import {
  getFirestore,
  doc,
  setDoc,
  serverTimestamp,
  updateDoc,
  deleteDoc,
} from 'firebase/firestore';
import { app } from '../resources/gcp-config';
import { successAlert, errorAlert } from '../components/alerts';
import { v4 as uuidv4 } from 'uuid';

const db = getFirestore(app);

const createpos = async (formData) => {
  const posId = uuidv4();
  formData.updatedAt = serverTimestamp();
  formData.posId = posId;
  try {
    await setDoc(doc(db, 'pos', posId), formData);

    successAlert('Success!', 'A new PO has been created.');
  } catch (error) {
    console.error('Error creating pos:', error);
    errorAlert('Error!', 'An error occurred while creating the PO.');
  }
};

const updatepos = async (posId, formData) => {
  try {
    await updateDoc(doc(db, 'pos', posId), {
      ...formData,
      updatedAt: serverTimestamp(),
    });
    // successAlert('Success!', 'The pos has been updated.');
  } catch (error) {
    console.error('Error updating pos:', error);
    errorAlert('Error!', 'An error occurred while updating the Pos');
  }
};

const deletePOS = async (posId) => {
  try {
    await deleteDoc(doc(db, 'pos', posId));
    successAlert('Success!', 'The pos has been deleted.');
  } catch (error) {
    console.error('Error deleting pos:', error);
    errorAlert('Error!', 'An error occurred while deleting the Pos');
  }
};

const updateposArray = async (arrayElement, jsonObject, posId) => {
  // console.log(arrayElement, jsonObject, posId);
  try {
    const posRef = doc(db, 'pos', posId);
    await updateDoc(posRef, {
      [arrayElement]: jsonObject,
    });

    // console.log('pos array updated successfully.');
  } catch (error) {
    console.error('Error updating pos array:', error);
  }
};

export { createpos, updatepos, updateposArray, deletePOS };
