import { fetchAllDeliveriesData } from '../../database/fetch-deliveries-data';

const getDeliveryData = (callback) => {
  return fetchAllDeliveriesData((deliveries) => {
    const deliveryData = deliveries
      .map((delivery, index) => {
        if (!delivery) {
          return null;
        }

        const originalDate = delivery.deliveryDate
          ? new Date(delivery.deliveryDate)
          : null;
        let formattedDate;

        // Check if the originalDate is valid
        if (!originalDate || isNaN(originalDate.getTime())) {
          // If originalDate is not a valid date or is null, set formattedDate to 'TBD'
          formattedDate = 'TBD';
        } else {
          // If originalDate is valid, proceed with formatting
          const day = originalDate.getDate();
          const month = originalDate.getMonth() + 1; // Months are zero-indexed, so we add 1
          const year = originalDate.getFullYear();

          formattedDate = `${month.toString().padStart(2, '0')}-${day
            .toString()
            .padStart(2, '0')}-${year}`;
        }

        const updatedAtDate = new Date(delivery.updatedAt);
        const updatedAtDay = updatedAtDate.getDate();
        const updatedAtMonth = updatedAtDate.getMonth() + 1; // Months are zero-indexed, so we add 1
        const updatedAtYear = updatedAtDate.getFullYear();
        const updatedAtHours = updatedAtDate.getHours();
        const updatedAtMinutes = updatedAtDate.getMinutes();
        const updatedAtSeconds = updatedAtDate.getSeconds();

        // Format the updatedAt as 'MM-DD-YYYY, HH:mm:ss'
        const formattedUpdatedAt = `${updatedAtMonth
          .toString()
          .padStart(2, '0')}/${updatedAtDay
          .toString()
          .padStart(2, '0')}/${updatedAtYear}, ${updatedAtHours
          .toString()
          .padStart(2, '0')}:${updatedAtMinutes
          .toString()
          .padStart(2, '0')}:${updatedAtSeconds.toString().padStart(2, '0')}`;

        // // console.log(formattedUpdatedAt);

        return {
          vendorName: delivery.vendorName || '',
          uniqueId: delivery.docId, // Use the index as the uniqueId
          deliveryDate: formattedDate || '',
          spoId: delivery.spoId || delivery.spo || '',
          vpoId: delivery.vpoId || '',
          delQty: delivery.deliveryQty || '',
          saiQty: delivery.saiQty || '',
          trackingID: delivery.trackingID || '',
          trackingPartner: delivery.trackingPartner || '',
          status: delivery.deliveryStatus || 'Not Shipped',
          updatedAt: delivery.updatedAt || '',
        };
      })
      .filter(Boolean);

    callback(deliveryData);
  });
};

export default getDeliveryData;
