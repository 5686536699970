import { app } from '../resources/gcp-config';
import {
  getFirestore,
  doc,
  getDoc,
  onSnapshot,
  collection,
  getDocs,
} from 'firebase/firestore';

const db = getFirestore(app);

/**
 * The function fetches all vendor data from a Firestore collection and calls a callback function with
 * the retrieved data.
 * @param callback - The callback parameter is a function that will be called with the fetched vendors
 * data as an argument. It allows you to handle the fetched data in your code.
 * @returns The `unsubscribe` function is being returned.
 */

export const fetchAllVendorsData = (callback) => {
  const q = collection(db, 'vendors');
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const vendors = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      docId: doc.id,
    }));
    callback(vendors); // call the callback function with the result
  });

  // Return the unsubscribe function
  return unsubscribe;
};

// export const fetchAllVendorsData = (callback) => {
//   const q = collection(db, 'vendors');
//   const vendors = querySnapshot.docs.map((doc) => {
//     return { ...doc.data(), docId: doc.id };
//   });
//   return vendors;
// };

/**
 * The function fetches a single vendor's data from a Firestore database using the provided document
 * ID.
 * @param docId - The `docId` parameter is the unique identifier of the document you want to fetch from
 * the "vendors" collection. It is used to construct a document reference and retrieve the document
 * snapshot from Firestore.
 * @returns The function `fetchSingleVendorData` returns an object that contains the data of the
 * document retrieved from the Firestore database, along with the `docId` property which represents the
 * ID of the document. If the document does not exist, it returns `null`.
 */
export const fetchSingleVendorData = async (docId) => {
  const docRef = doc(db, 'vendors', docId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return { ...docSnap.data(), docId: docSnap.id };
  } else {
    console.error(new Error('No such document!'));
    return null;
  }
};
