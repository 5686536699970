import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const labels = [
  'January',
  'Feburary',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const backgroundColors = [
  'rgba(255, 99, 132, 0.2)',
  'rgba(54, 162, 235, 0.2)',
  'rgba(255, 206, 86, 0.2)',
  'rgba(75, 192, 192, 0.2)',
  'rgba(153, 102, 255, 0.2)',
  'rgba(255, 159, 64, 0.2)',
  'rgba(123, 104, 238, 0.2)',
  'rgba(0, 128, 0, 0.2)',
  'rgba(255, 0, 255, 0.2)',
  'rgba(255, 165, 0, 0.2)',
  'rgba(70, 130, 180, 0.2)',
  'rgba(255, 215, 0, 0.2)',
];

const borderColors = [
  'rgba(255, 99, 132, 1)',
  'rgba(54, 162, 235, 1)',
  'rgba(255, 206, 86, 1)',
  'rgba(75, 192, 192, 1)',
  'rgba(153, 102, 255, 1)',
  'rgba(255, 159, 64, 1)',
  'rgba(123, 104, 238, 1)',
  'rgba(0, 128, 0, 1)',
  'rgba(255, 0, 255, 1)',
  'rgba(255, 165, 0, 1)',
  'rgba(70, 130, 180, 1)',
  'rgba(255, 215, 0, 1)',
];

const commonOptions = {
  plugins: {
    legend: {
      display: false,
      position: 'bottom', // Adjust the position as needed
    },
  },
};

const ReportsChart = ({ chartData }) => {
  const totalPoData = {
    labels: labels,
    datasets: [
      {
        label: '# of POs',
        data: chartData.totalPO,
        backgroundColor: backgroundColors,
        borderColor: borderColors,
        borderWidth: 1,
      },
    ],
  };

  const totalRfqResponsesData = {
    labels: labels,
    datasets: [
      {
        label: '# of RFQs',
        data: chartData.totalRfqResponses,
        backgroundColor: backgroundColors,
        borderColor: borderColors,
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <div className="grid grid-cols-2 gap-8 mb-5">
        <div className="h-72 w-fit m-5 text-center mx-auto">
          <div> Total Po </div>
          <Pie data={totalPoData} />
        </div>
        <div className="h-72 w-fit m-5 text-center mx-auto">
          <div> Total Rfq Responses </div>
          <Pie data={totalRfqResponsesData} />
        </div>
      </div>
    </>
  );
};

export default ReportsChart;
