import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage';

import { app } from '../resources/gcp-config';

const storeFilePos = async (file, fileId, fileMetadata) => {
  const storage = getStorage(app);
  const fileRef = ref(storage, `pos-attachments/${fileId}`);

  const uploadTask = uploadBytesResumable(fileRef, file, fileMetadata);

  return new Promise((resolve, reject) => {
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // You can use this to track the upload progress
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // console.log('Upload is ' + progress + '% done');
      },
      (error) => {
        // Handle unsuccessful uploads
        console.error('Upload failed:', error);
        reject(error);
      },
      () => {
        // Handle successful uploads on complete
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          // console.log('File available atttt', downloadURL);
          resolve(downloadURL);
        });
      }
    );
  });
};

const storeFilePackets = async (file, fileId, fileMetadata) => {
  const storage = getStorage(app);
  const fileRef = ref(storage, `pos-packets/${fileId}`);

  const uploadTask = uploadBytesResumable(fileRef, file, fileMetadata);

  return new Promise((resolve, reject) => {
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // You can use this to track the upload progress
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // console.log('Upload is ' + progress + '% done');
      },
      (error) => {
        // Handle unsuccessful uploads
        console.error('Upload failed:', error);
        reject(error);
      },
      () => {
        // Handle successful uploads on complete
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          // console.log('File available at', downloadURL);
          resolve(downloadURL);
        });
      }
    );
  });
};

const storeFileDeliveries = async (file, fileId, fileMetadata) => {
  const storage = getStorage(app);
  const fileRef = ref(storage, `deliveries/${fileId}`);

  const uploadTask = uploadBytesResumable(fileRef, file, fileMetadata);

  return new Promise((resolve, reject) => {
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // You can use this to track the upload progress
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // console.log('Upload is ' + progress + '% done');
      },
      (error) => {
        // Handle unsuccessful uploads
        console.error('Upload failed:', error);
        reject(error);
      },
      () => {
        // Handle successful uploads on complete
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          // console.log('File available at', downloadURL);
          resolve(downloadURL);
        });
      }
    );
  });
};

export { storeFilePos, storeFilePackets, storeFileDeliveries };
