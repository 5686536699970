import React from 'react';

const ModalHeader = ({ title, onClose }) => (
  <div className="flex items-center justify-between p-4 border-b rounded-t dark:border-gray-600">
    <h3 className="text-lg font-medium text-gray-900 dark:text-white">
      {title}
    </h3>
    <button
      type="button"
      className="text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 dark:hover:bg-gray-700 dark:hover:text-white"
      onClick={onClose}
      aria-label="Close modal"
    >
      <svg
        className="w-5 h-5"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </button>
  </div>
);

const ModalFooter = ({ onConfirm }) => (
  <div className="flex justify-end p-4 border-t dark:border-gray-600">
    <button
      type="button"
      onClick={onConfirm}
      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
    >
      Yes
    </button>
  </div>
);

const ProcessRFQRecord = ({ openModal, setOpenModal, handleProcessEvent }) => {
  if (!openModal) return null;

  return (
    <div
      id="addUserData-modal"
      tabIndex="-1"
      aria-hidden={!openModal}
      className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-black bg-opacity-50"
    >
      <div className="relative w-full max-w-md p-4 mx-auto">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <ModalHeader
            title="Confirm RFQ Processing"
            onClose={() => setOpenModal(false)}
          />
          <div className="p-5 text-center">
            <p className="text-sm text-gray-700 dark:text-gray-300">
              Do you want to process this entry as RFQ?
            </p>
          </div>
          <ModalFooter onConfirm={handleProcessEvent} />
        </div>
      </div>
    </div>
  );
};

export default ProcessRFQRecord;
