const deliveryFilterValue = [
  { name: 'deliveryDate', operator: 'contains', type: 'string', value: '' },
  { name: 'spoId', operator: 'contains', type: 'string', value: '' },
  { name: 'vpoId', operator: 'contains', type: 'string', value: '' },
  { name: 'vendorName', operator: 'contains', type: 'string', value: '' },
  { name: 'delQty', operator: 'gte', type: 'number', value: '' },
  { name: 'saiQty', operator: 'gte', type: 'number', value: '' },
  { name: 'trackingID', operator: 'contains', type: 'string', value: '' },
  { name: 'trackingPartner', operator: 'contains', type: 'string', value: '' },
  { name: 'status', operator: 'contains', type: 'string', value: '' },
  { name: 'updatedAt', operator: 'contains', type: 'string', value: '' },
];
export default deliveryFilterValue;
