import React, { useState, useEffect } from 'react';
import AddUserComponent from './addNewUser';
import { fetchUserData } from '../../database/fetch-user-data';

const UsersTopBar = () => {
  const [openModal, setOpenModal] = useState(false);
  const [userRole, setUserRole] = useState(null);
  useEffect(() => {
    const fetchRole = async () => {
      const userData = await fetchUserData();
      if (userData) {
        setUserRole(userData.role);
      }
    };

    fetchRole();
  }, []);
  return (
    <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 dark:border-gray-700">
      <div>
        <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
          Users
        </h2>
        <p className="text-sm text-gray-600 dark:text-gray-400">
          View users, edit and more.
        </p>
      </div>

      <div>
        <div className="inline-flex gap-x-2">
          {userRole && userRole.toLowerCase() === 'admin' && (
            <button
              className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
              onClick={() => setOpenModal(true)}
            >
              <svg
                className="flex-shrink-0 w-3 h-3"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M2.63452 7.50001L13.6345 7.5M8.13452 13V2"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
              Add user
            </button>
          )}
          <AddUserComponent openModal={openModal} setOpenModal={setOpenModal} />
        </div>
      </div>
    </div>
  );
};

export default UsersTopBar;
