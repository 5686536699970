import { fetchAllVendorsData } from '../../database/fetch-vendor-data';

const getVendorData = (callback) => {
  return fetchAllVendorsData((vendors) => {
    const vendorData = vendors.map((vendor) => ({
      uniqueId: vendor.vendorId || "",
      rating: vendor.rating || "",
      name: vendor.vendorName || "",
      category: vendor.primaryPSCCategory || "",
      contact: vendor.primaryContactName || "",
      city: vendor.city || "",
      state: vendor.state || "",
      phone: vendor.primaryPhone || "",
      email: vendor.primaryEmail || "",
      website: vendor.website || "",
      vendorImage:vendor.vendorImage || ""
    }));
    callback(vendorData);
  });
};

export default getVendorData;
