import React from 'react';
import { EyeIcon } from '@heroicons/react/20/solid';
import AssignedToRenderer from './user-renderer';
import { deleteRFQ, updateRFQ } from '../../database/set-rfq-data';

/**
 * The emailRenderer function returns an HTML anchor element with a mailto link.
 * @param value - The `value` parameter in the `emailRenderer` function represents the email address
 * that needs to be rendered as a clickable link.
 * @returns The emailRenderer function returns an HTML anchor element (a tag) with the email address as
 * the link text and the email address as the href attribute value.
 */
const emailRenderer = (value) => {
  return (
    <a
      href={`mailto:${value.value}`}
      className="text-blue-500 hover:text-blue-700 underline"
    >
      {value.value}
    </a>
  );
};

const onRenderer = ({ value, data, setRfqData }) => {
  let color;
  switch (value) {
    case 'bid':
      color = 'bg-green-500 text-white';
      break;
    case 'no bid':
    case undefined:
      color = 'bg-red-600 text-white';
      break;
    case 'consider':
      color = 'bg-yellow-600 text-white';
      break;
    default:
      color = 'bg-blue-500 text-white';
  }

  return (
    <div className="flex justify-center">
      <select
        value={value || 'new'}
        className={`px-2 py-1 rounded w-full ${color}`}
        onChange={(e) => {
          handleStatusChange(e.target.value, data);
          setRfqData((prevData) =>
            prevData.filter((item) => item.status !== 'no bid')
          );
        }}
      >
        <option value="bid">Bid</option>
        <option value="no bid">No Bid</option>
        <option value="consider">Consider</option>
        <option value="new">New</option>
      </select>
    </div>
  );
};

// This function handles the status change.
async function handleStatusChange(newValue, data) {
  const formData = {
    status: newValue,
  };

  try {
    await updateRFQ(data.uniqueId, formData);
  } catch (error) {
    console.error('Error updating RFQ:', error);
  }
}

const viewRenderer = ({ data, setOpenEditModal, setSelectedId }) => {
  const handleClick = (event) => {
    const docId = event.currentTarget.getAttribute('data-doc-id');
    // console.log('docId', docId);
    setSelectedId(docId); // Set the selected id
    setOpenEditModal(true); // Open the modal
  };

  return (
    <div
      className="flex justify-center"
      data-doc-id={data.data.uniqueId}
      onClick={handleClick}
    >
      <EyeIcon className="h-5 w-5 hover:text-blue-500 cursor-pointer" />
    </div>
  );
};
const onDeleteRenderer = ({ data, setOpenEditModal, setSelectedId }) => {
  // // console.log('idData: ', data.uniqueId);

  return (
    <button
      type="button"
      onClick={() => deleteRFQ(data.uniqueId)}
      className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
    >
      Delete
    </button>
  );
};

const orderColumns = (columns, order) => {
  if (!order) return columns;

  const orderedColumns = [];
  order.forEach((columnName) => {
    const column = columns.find((col) => col.name === columnName);
    if (column) {
      orderedColumns.push(column);
    }
  });

  // Add any remaining columns that are not specified in the order
  columns.forEach((column) => {
    if (!order.includes(column.name)) {
      orderedColumns.push(column);
    }
  });

  return orderedColumns;
};

const rfqColumns = (
  setOpenEditModal,
  setSelectedId,
  setRfqData,
  tablePreferences
) => {
  const columns = [
    {
      name: 'view',
      header: '',
      minWidth: 20,
      render: (data) => viewRenderer({ data, setOpenEditModal, setSelectedId }),
      ...(tablePreferences.view && { defaultWidth: tablePreferences.view }),
    },
    {
      name: 'no',
      header: 'No.',
      minWidth: 100,
      ...(tablePreferences.no && { defaultWidth: tablePreferences.no }),
    },
    {
      name: 'rfqNo',
      header: 'RFQ No.',
      minWidth: 200,
      ...(tablePreferences.rfqNo && { defaultWidth: tablePreferences.rfqNo }),
    },
    {
      name: 'agency',
      header: 'Agency',
      minWidth: 250,
      ...(tablePreferences.agency && { defaultWidth: tablePreferences.agency }),
    },
    {
      name: 'email',
      header: 'Email',
      minWidth: 250,
      render: emailRenderer,
      ...(tablePreferences.email && { defaultWidth: tablePreferences.email }),
    },
    {
      name: 'emailSubject',
      header: 'Subject',
      minWidth: 250,
      ...(tablePreferences.emailSubject && {
        defaultWidth: tablePreferences.emailSubject,
      }),
    },
    {
      name: 'releaseDate',
      header: 'Release Date',
      minWidth: 250,
      ...(tablePreferences.releaseDate && {
        defaultWidth: tablePreferences.releaseDate,
      }),
    },
    {
      name: 'dueBy',
      header: 'Due By',
      minWidth: 250,
      ...(tablePreferences.dueBy && { defaultWidth: tablePreferences.dueBy }),
    },
    {
      name: 'lineItems',
      header: 'Line Items',
      minWidth: 30,
      ...(tablePreferences.lineItems && {
        defaultWidth: tablePreferences.lineItems,
      }),
    },
    {
      name: 'attachments',
      header: 'Attachments',
      minWidth: 30,
      ...(tablePreferences.attachments && {
        defaultWidth: tablePreferences.attachments,
      }),
    },
    {
      name: 'quotes',
      header: 'Quotes',
      minWidth: 30,
      ...(tablePreferences.quotes && { defaultWidth: tablePreferences.quotes }),
    },
    {
      name: 'assignedTo',
      header: 'Assigned To',
      minWidth: 200,
      render: AssignedToRenderer,
      ...(tablePreferences.assignedTo && {
        defaultWidth: tablePreferences.assignedTo,
      }),
    },
    {
      name: 'status',
      header: 'Status',
      minWidth: 200,
      render: ({ value, data }) => onRenderer({ value, data, setRfqData }),
      ...(tablePreferences.status && { defaultWidth: tablePreferences.status }),
    },
    {
      name: 'delete',
      header: 'Delete',
      minWidth: 50,
      render: ({ value, data }) =>
        onDeleteRenderer({ value, data, setRfqData }),
      ...(tablePreferences.delete && { defaultWidth: tablePreferences.delete }),
    },
  ];

  const order = tablePreferences?.order;

  // Order the columns
  const orderedColumns = orderColumns(columns, order);

  return orderedColumns;
};

export default rfqColumns;
