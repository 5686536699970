import React, { useRef, useState } from 'react';
import Loader from '../loader/loader.js';
import { createCO } from '../../database/set-co-data.js';
import { errorAlert, warnAlert } from '../alerts.js';
import { UserCircleIcon } from '@heroicons/react/24/outline';

function AddNewCoComponent({ openModal, setOpenModal }) {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    company: '',
    jobTitle: '',
    businessPhone: '',
    emailAddress: '',
    imageFile: null,
  });
  const [coImagePreview, setCOImagePreview] = useState('')
  const [validationErrors, setValidationErrors] = useState({})

  const fileRef = useRef(null);

  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const validateForm = () => {
    const errors = {};
    if (!formData.name.trim()) errors.name = true;
    if (!formData.company.trim()) errors.company = true;
    if (!formData.jobTitle.trim()) errors.jobTitle = true;
    if (!formData.emailAddress || !isValidEmail) errors.emailAddress = true;
    if (!formData.businessPhone.trim()) errors.businessPhone = true;
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  function addNewCO() {
    if (!validateForm()) {
      warnAlert('Please fill in all required fields.');
      return;
    }
    if (isValidEmail(formData.emailAddress)) {
      setIsLoading(true);
      createCO(formData).then((r) => {
      closeModal();
      });
    } else {
      errorAlert('Error!', 'Please Enter a valid email Address');
    }
  }

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    const acceptedFileTypes = ['image/jpeg', 'image/png', 'image/gif','image/webp'];
    const maxFileSize = 5 * 1024 * 1024;

    if (file) {
      if (!acceptedFileTypes.includes(file.type)) {
        warnAlert('Please upload a valid image file (JPEG, PNG, GIF, WEBP).');
        fileRef.current.value = "";
        return;
      }
      if (file.size > maxFileSize) {
        fileRef.current.value = "";
        warnAlert('File size exceeds the maximum(5 MB) limit.');
        return;
      }
      setFormData((prevData) => ({
        ...prevData,
        imageFile: file,
      }));
      setCOImagePreview(URL.createObjectURL(file));
    }
  };

  const clearFormStates = () => {
    setIsLoading(false);
    setFormData({
      name: '',
      company: '',
      jobTitle: '',
      businessPhone: '',
      emailAddress: '',
      imageFile: null,      
    });
    setCOImagePreview('')
    setValidationErrors({})
  }

  const closeModal = () => {
    setOpenModal(false)
    clearFormStates();
  }

  return (
    <>
      {isLoading && <Loader />}

      <div
        id="addCoData-modal"
        tabIndex="-1"
        aria-hidden="true"
        className={
          openModal
            ? 'flex items-center justify-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full h-[calc(100%)] max-h-full bg-black bg-opacity-50'
            : 'hidden'
        }
      >
        <div className="relative p-4 w-full max-w-5xl max-h-full">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                Add New CO
              </h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={closeModal}
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <div className="p-4 md:p-5 space-y-4 overflow-y-auto max-h-[calc(100vh-15rem)]">
              <section className="bg-white dark:bg-gray-900">
                <div className="mx-auto max-w-5xl">
                  <section className="bg-white dark:bg-gray-900">
                    <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
                      <div className="sm:col-span-2">

                        <label htmlFor="image" className="block mt-2 mx-auto cursor-pointer w-32 h-32  items-center justify-center border rounded-full border-gray-300 relative">
                          <input
                            id="image"
                            type="file"
                            accept="image/*"
                            onChange={handleImageChange}
                            className="absolute top-0 left-0 opacity-0 cursor-pointer w-32 h-32"
                            ref={fileRef}
                          />
                          {coImagePreview ? (
                            <img src={coImagePreview} alt="Image Preview" className="w-full h-full object-cover rounded-full" />
                          ) : (
                            <div className="w-full h-full flex items-center justify-center bg-gray-200 rounded-full">
                              <UserCircleIcon className="w-32 h-32" />
                            </div>
                          )}
                        </label>
                      </div>
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="primaryContact"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Name <span className="text-red-600">*</span>
                        </label>
                        <input
                          type="text"
                          name="name"
                          id="name"
                          className={`bg-white border text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500
                             ${validationErrors.name
                              ? 'border-red-600 dark:border-red-600'
                              : 'border-gray-300 dark:border-gray-600'
                            }`}
                          placeholder="Name"
                          value={formData.name}
                          onChange={(e) =>
                            setFormData((prev) => ({
                              ...prev,
                              name: e.target.value,
                            }))
                          }
                        />
                        {validationErrors?.name && (
                          <p className="text-red-600 text-sm">Name is required.</p>
                        )}
                      </div>
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="primaryContact"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Company <span className="text-red-600">*</span>
                        </label>
                        <input
                          type="text"
                          name="company"
                          id="company"
                          className={`bg-white border text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500
                             ${validationErrors.company
                              ? 'border-red-600 dark:border-red-600'
                              : 'border-gray-300 dark:border-gray-600'
                            }`}
                          placeholder="Company"
                          value={formData.company}
                          onChange={(e) =>
                            setFormData((prev) => ({
                              ...prev,
                              company: e.target.value,
                            }))
                          }
                        />
                        {validationErrors?.company && (
                          <p className="text-red-600 text-sm">Company is required.</p>
                        )}
                      </div>
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="primaryContact"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Job Title <span className="text-red-600">*</span>
                        </label>
                        <input
                          type="text"
                          name="jobTitle"
                          id="jobTitle"
                          className={`bg-white border text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500
                             ${validationErrors.jobTitle
                              ? 'border-red-600 dark:border-red-600'
                              : 'border-gray-300 dark:border-gray-600'
                            }`}
                          placeholder="Job Title"
                          value={formData.jobTitle}
                          onChange={(e) =>
                            setFormData((prev) => ({
                              ...prev,
                              jobTitle: e.target.value,
                            }))
                          }
                        />
                        {validationErrors?.jobTitle && (
                          <p className="text-red-600 text-sm">Job Title is required.</p>
                        )}
                      </div>
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="spo"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Business Phone <span className="text-red-600">*</span>
                        </label>
                        <input
                          type="tel"
                          name="businessPhone"
                          id="businessPhone"
                          className={`bg-white border text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500
                             ${validationErrors.businessPhone
                              ? 'border-red-600 dark:border-red-600'
                              : 'border-gray-300 dark:border-gray-600'
                            }`}
                          placeholder="Business Phone Number"
                          required
                          value={formData.businessPhone}
                          onChange={(e) =>
                            setFormData((prev) => ({
                              ...prev,
                              businessPhone: e.target.value.toString(),
                            }))
                          }
                        />
                        {validationErrors?.businessPhone && (
                          <p className="text-red-600 text-sm">Business Phone is required.</p>
                        )}
                      </div>
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="primaryContact"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Email <span className="text-red-600">*</span>
                        </label>
                        <input
                          type="email"
                          name="emailAddress"
                          id="emailAddressA"
                          className="bg-gwhite border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          placeholder="Email"
                          value={formData.emailAddress}
                          onChange={(e) =>
                            setFormData((prev) => ({
                              ...prev,
                              emailAddress: e.target.value,
                            }))
                          }
                        />
                        {validationErrors?.emailAddress && (
                          <p className="text-red-600 text-sm">Email Address is required.</p>
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              </section>
            </div>
            <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
              <button
                type="button"
                onClick={() => {
                  addNewCO();
                }}
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Add CO
              </button>
              <button
                onClick={closeModal}
                type="button"
                className="ms-3 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddNewCoComponent;
