const dataRender = ({ value }) => {
  const sum = value.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );
  return sum;
};

const monthsRender = ({ value }) => {
  return <div>{value.join(',')}</div>;
};

const ReportsColumns = [
  { name: 'agency', header: 'Agency', minWidth: 250 },
  { name: 'totalPO', header: 'Total PO', minWidth: 100 },
  {
    name: 'totalRfqResponses',
    header: 'Total RFQ Responses',
    minWidth: 100,
  },
  {
    name: 'totalPoCost',
    header: 'Total PO Cost',
    minWidth: 200,
  },
  {
    name: 'totalRfqCost',
    header: 'Total RFQ Cost',
    minWidth: 200,
  },
  {
    name: 'projMargin',
    header: 'Projected Margin',
    minWidth: 200,
  },
  {
    name: 'totalFinalPoCost',
    header: 'Total Final PO Cost',
    minWidth: 200,
  },
  {
    name: 'actualMargin',
    header: 'Actual Margin',
    minWidth: 100,
  },
  { name: 'taxes', header: 'Taxes', minWidth: 200 },
  { name: 'profit', header: 'Profit', minWidth: 200 },
  { name: 'months', header: 'Months', minWidth: 100 },
  {
    name: 'vpoInvoices',
    header: 'VPO invoices',
    minWidth: 100,
  },
  {
    name: 'totalSpoInvoices',
    header: 'Total SPO invoices',
    minWidth: 100,
  },
];

export const TotalsColumns = [
  { name: 'agency', header: 'Total', minWidth: 250 },
  { name: 'totalPO', header: 'Total PO', minWidth: 100 },
  { name: 'totalRfqResponses', header: 'Total RFQ Responses', minWidth: 100 },
  { name: 'totalPoCost', header: 'Total PO Cost', minWidth: 200 },
  { name: 'totalRfqCost', header: 'Total RFQ Cost', minWidth: 200 },
  { name: 'projMargin', header: 'Projected Margin', minWidth: 200 },
  { name: 'totalFinalPoCost', header: 'Total Final PO Cost', minWidth: 200 },
  { name: 'actualMargin', header: 'Actual Margin', minWidth: 100 },
  { name: 'taxes', header: 'Taxes', minWidth: 200 },
  { name: 'profit', header: 'Profit', minWidth: 200 },
  {
    name: 'months',
    header: 'Months',
    minWidth: 100,
    render: () => <span></span>,
  },
  { name: 'vpoInvoices', header: 'VPO invoices', minWidth: 100 },
  { name: 'totalSpoInvoices', header: 'Total SPO invoices', minWidth: 100 },
];

export const ItemsColumns = [
  { name: 'itemName', header: 'Item Name', minWidth: 250 },
  { name: 'qty', header: 'Qty', minWidth: 20 },

  {
    name: 'totalPoCost',
    header: 'Total LI Purchase Cost',
    minWidth: 200,
    render: ({ value }) => value.toFixed(2),
  },
  {
    name: 'totalFinalPoCost',
    header: 'Total PO Cost',
    minWidth: 200,
    render: ({ value }) => value.toFixed(2),
  },
  {
    name: 'margin',
    header: 'Margin',
    minWidth: 20,
    render: ({ value }) => value.toFixed(2),
  },
  // {
  //   name: 'totalRfqCost',
  //   header: 'Total RFQ Cost',
  //   minWidth: 200,
  //   render: ({ value }) => value.toFixed(2),
  // },
  {
    name: 'profit',
    header: 'Profit',
    minWidth: 200,
    render: ({ value }) => value.toFixed(2),
  },
];

export const ItemsTotalColumns = [
  { name: 'itemName', header: 'Total', minWidth: 250 },
  { name: 'qty', header: 'Qty', minWidth: 20 },

  {
    name: 'totalPoCost',
    header: 'Total LI Purchase Cost',
    minWidth: 200,
    render: ({ value }) => value.toFixed(2),
  },
  {
    name: 'totalFinalPoCost',
    header: 'Total PO Cost',
    minWidth: 200,
    render: ({ value }) => value.toFixed(2),
  },
  {
    name: 'margin',
    header: 'Margin',
    minWidth: 20,
    render: ({ value }) => value.toFixed(2),
  },
  // {
  //   name: 'totalRfqCost',
  //   header: 'Total RFQ Cost',
  //   minWidth: 200,
  //   render: ({ value }) => value.toFixed(2),
  // },
  {
    name: 'profit',
    header: 'Profit',
    minWidth: 200,
    render: ({ value }) => value.toFixed(2),
  },
];

export default ReportsColumns;
