import EmailsTable from '../components/emails/emails-table';
import EmailModal from '../components/emails/emails-modal';
import NavbarElement from '../components/sidebar/navbar';
import Sidebar from '../components/sidebar/sidebar';
import classNames from 'classnames';
import React, { useState } from 'react';

function EmailsPages() {
  const [collapsed, setSidebarCollapsed] = useState(false);
  const [showSidebar, setShowSidebar] = useState(true);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  return (
    <div
      className={classNames({
        'grid bg-zinc-100 min-h-screen': true,
        'grid-cols-sidebar': !collapsed,
        'grid-cols-sidebar-collapsed': collapsed,
        'transition-[grid-template-columns] duration-300 ease-in-out': true,
      })}
    >
      <div
        style={{
          display: 'flex',
        }}
      >
        <div>
          <Sidebar
            collapsed={collapsed}
            setCollapsed={setSidebarCollapsed}
            shown={showSidebar}
          />
        </div>
        <div className="container" style={{ overflow: 'auto' }}>
          <div className="">
            <NavbarElement
              onMenuButtonClick={() => setShowSidebar((prev) => !prev)}
            />
            <div className="flex-grow overflow-hidden">
              <EmailsTable
                setOpenEditModal={setOpenEditModal}
                openEditModal={openEditModal}
                setSelectedId={setSelectedId}
              />
            </div>
          </div>
          <EmailModal
            openEditModal={openEditModal}
            setOpenEditModal={setOpenEditModal}
            docId={selectedId}
          />
        </div>
      </div>
    </div>
  );
}

export default EmailsPages;
