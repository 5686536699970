import Swal from 'sweetalert2';

/**
 * The function `successAlert` displays a success alert using the Swal library with a specified header
 * and text.
 * @param header - The header parameter is a string that represents the title of the success alert. It
 * is displayed at the top of the alert box.
 * @param text - The `text` parameter is a string that represents the content of the success alert. It
 * can be any message or information that you want to display to the user when the success alert is
 * shown.
 */
export const successAlert = (header, text) => {
  Swal.fire({
    icon: 'success',
    title: header,
    text: text,
  });
};

/**
 * The function `warnAlert` displays a warning alert with a specified header and text using the Swal
 * library.
 * @param header - The header parameter is a string that represents the title or header of the warning
 * alert.
 * @param text - The `text` parameter is a string that represents the content of the warning alert. It
 * is the message that will be displayed to the user in the alert dialog.
 */
export const warnAlert = (header, text) => {
  Swal.fire({
    icon: 'warning',
    title: header,
    text: text,
  });
};

/**
 * The `errorAlert` function displays an error alert using the Swal library with a specified header and
 * text.
 * @param header - The `header` parameter is a string that represents the title or header of the error
 * alert. It is displayed at the top of the alert box.
 * @param text - The `text` parameter is a string that represents the content of the error message. It
 * is the main text that will be displayed in the error alert.
 */
export const infoAlert = (header, text) => {
  Swal.fire({
    icon: 'info',
    title: header,
    text: text,
  });
};

/**
 * The function `errorAlert` displays an error alert using the Swal library with a specified header and
 * text.
 * @param header - The header parameter is a string that represents the title or header of the error
 * alert. It is displayed at the top of the alert box.
 * @param text - The `text` parameter is a string that represents the content of the error message. It
 * is the main text that will be displayed in the error alert.
 */
export const errorAlert = (header, text) => {
  Swal.fire({
    icon: 'error',
    title: header,
    text: text,
  });
};

/**
 * import { successAlert, warnAlert, infoAlert, errorAlert } from '../components/alerts';

// To display a success alert
successAlert('Success!', 'You have successfully completed the task.');

// To display a warning alert
warnAlert('Warning!', 'This is a warning message.');

// To display an info alert
infoAlert('Info', 'This is an informational message.');

// To display an error alert
errorAlert('Error!', 'An error occurred.');
 */
