import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { fetchAllRfqData } from '../../database/fetch-rfq-data';
import Loader from '../loader/loader';

export default function Calendar() {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchAllRfqData((rfqData) => {
      const mappedEvents = rfqData.flatMap((rfq) => {
        const initialEvent = {
          title: `${rfq.agency} (${rfq.rfqNo})`,
          date: String(rfq.dueDate),
          rfqData: rfq,
          color: '#000000',
        };

        if (rfq.review) {
          const reviewEvent = {
            title: `Sent RFQ for review`,
            date: String(rfq.dueDate),
            rfqData: rfq,
            color: '#1E429F',
          };

          return [initialEvent, reviewEvent];
        } else {
          return [initialEvent];
        }
      });

      setEvents(mappedEvents);
      setIsLoading(false); // this is newly added line
    });
  }, []);

  const handleEventClick = ({ event }) => {
    setSelectedEvent(event.extendedProps.rfqData);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedEvent(null);
  };

  return (
    <>
      {isLoading && <Loader />}
      <FullCalendar
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        weekends={true}
        events={events}
        eventClick={handleEventClick}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,dayGridWeek,dayGridDay',
        }}
      />
      <div className="z-100 fixed">
        {selectedEvent && (
          <div
            className={`${
              isModalOpen ? 'block' : 'hidden'
            } fixed inset-0 overflow-y-auto`}
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 modal">
              <div
                className="fixed inset-0 transition-opacity"
                aria-hidden="true"
              >
                <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
              </div>

              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>

              <div
                className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-headline"
              >
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <h3
                        className="text-lg leading-6 font-medium text-gray-900"
                        id="modal-headline"
                      >
                        RFQ Details
                      </h3>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          RFQ Number: {selectedEvent.rfqNo}
                          <br />
                          RFQ Name: {selectedEvent.agency}
                          <br />
                          RFQ Due date:{' '}
                          {new Date(selectedEvent.dueDate).toLocaleDateString(
                            'en-US',
                            {
                              month: '2-digit',
                              day: '2-digit',
                              year: 'numeric',
                            }
                          )}{' '}
                          <br />
                          {selectedEvent.review}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    onClick={closeModal}
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
