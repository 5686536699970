import React, { useState, useEffect } from 'react';
import Loader from '../loader/loader.js';
import { fetchAllRfqData } from '../../database/fetch-rfq-data.js'; // Replace with the actual path
import { createpos } from '../../database/set-po-data.js';

function AddNewPosComponent({ openModal, setOpenModal }) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRFQ, setSelectedRFQ] = useState(null);
  const [selectedRfqId, setSelectedRfqId] = useState('');
  const [rfqsList, setRfqsList] = useState([]);
  const [spoValue, setSpoValue] = useState('');
  const [awardedDateValue, setAwardedDateValue] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const unsubscribe = fetchAllRfqData((rfqs) => {
        setRfqsList(rfqs);
        setIsLoading(false);
      });

      return () => {
        unsubscribe();
      };
    };

    fetchData();
  }, []);

  const handleRfqChange = (e) => {
    const newSelectedRfqId = e.target.value;
    setSelectedRfqId(newSelectedRfqId);
  };

  const clearPOform =()=>{
    setAwardedDateValue('');
    setSelectedRFQ('')
    setSelectedRfqId('')
    setSpoValue('')
  }
  const closeModal = () => {
    setOpenModal(false)
    clearPOform();
  }


  useEffect(() => {
    if (selectedRfqId) {
      const filterRfq = rfqsList.find((rfq) => rfq.rfqNo === selectedRfqId);
      setSelectedRFQ(filterRfq || {}); 
    }
  }, [selectedRfqId, rfqsList]);

  function addNewPO() {
    if (selectedRFQ) {
      // Set default values if properties are undefined
      const items = selectedRFQ.items || [];
      const quotes = selectedRFQ.quotes || [];
      const totalLI = items.length;

      const clinArray = items.map((item) => item.clin);

      const vpoAddedPo = quotes.map((item) => {
        const position = clinArray.indexOf(item.clin);
        let dateObject = new Date(awardedDateValue);
        let formattedDate = `${dateObject.getDate()}/${dateObject.getMonth() + 1
          }/${dateObject.getFullYear()}`;
        return {
          ...item,
          vpo: `${spoValue}-${position + 1}/${totalLI}-${item.vQty}/${item.qty
            }-${formattedDate}`,
        };
      });

      const newJson = {
        ...selectedRFQ,
        spo: spoValue,
        awardedDate: awardedDateValue,
        originalQuotes: vpoAddedPo,
        modifiedQuotes: vpoAddedPo,
      };
      delete newJson.quotes;

      setIsLoading(true);
      createpos(newJson).then((r) => {
        setIsLoading(false);
      });
      clearPOform()
    }
  }

  return (
    <>
      {isLoading && <Loader />}

      <div
        id="addUserData-modal"
        tabIndex="-1"
        aria-hidden="true"
        className={
          openModal
            ? 'flex items-center justify-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full h-[calc(100%)] max-h-full bg-black bg-opacity-50'
            : 'hidden'
        }
      >
        <div className="relative p-4 w-full max-w-5xl max-h-full">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                Add New PO
              </h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={closeModal}
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <div className="p-4 md:p-5 space-y-4 overflow-y-auto max-h-[calc(100vh-15rem)]">
              <section className="bg-white dark:bg-gray-900">
                <div className="mx-auto max-w-5xl">
                  <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
                    <div className="sm:col-span-2">
                      <label
                        htmlFor="selectRFQOptions"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Select an Existing RFQ
                      </label>
                      <select
                        id="selectRFQOptions"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        required
                        value={selectedRfqId}
                        onChange={handleRfqChange}
                      >
                        <option>Choose a Existing RFQ</option>
                        {rfqsList?.map((item) => (
                          <option key={item.itemId} value={item.rfqNo}>
                            {item.rfqNo}: {item.agency}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <section className="bg-white dark:bg-gray-900">
                    <h5 className="flex justify-between items-center text-lg font-semibold text-gray-900 dark:text-white mb-3 mt-6">
                      Line Items{' '}
                    </h5>
                    <div className="my-6 relative overflow-x-auto shadow-md sm:rounded-lg">
                      <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                          <tr>
                            <th scope="col" className="px-6 py-3">
                              CLIN
                            </th>
                            <th scope="col" className="px-6 py-3">
                              Item Name
                            </th>
                            <th scope="col" className="px-6 py-3">
                              SAI Qty
                            </th>
                            <th scope="col" className="px-6 py-3">
                              Total
                            </th>
                            <th scope="col" className="px-6 py-3">
                              Vendor
                            </th>
                          </tr>
                        </thead>
                       
                        <tbody>
                          {selectedRFQ && selectedRFQ.quotes ? (
                            selectedRFQ.quotes.length > 0 ? (
                              selectedRFQ.quotes.map((quote, index) => (
                                <tr
                                  key={index}
                                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                                >
                                  <th
                                    scope="row"
                                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                  >
                                    {quote.clin}
                                  </th>
                                  <td className="px-6 py-4">
                                    {quote.itemName}
                                  </td>
                                  <td className="px-6 py-4">{quote.saiQty}</td>
                                  <td className="px-6 py-4">
                                    {quote.saiTotal}
                                  </td>
                                  <td className="px-6 py-4">
                                    {quote.vendorName}
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="5" className="text-center py-4">
                                  No quotes available
                                </td>
                              </tr>
                            )
                          ) : (
                            <tr>
                              <td colSpan="5" className="text-center py-4">
                                No RFQ selected
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </section>
                  <section className="bg-white dark:bg-gray-900">
                    <h5 className="flex justify-between items-center text-lg font-semibold text-gray-900 dark:text-white mb-3 mt-6">
                      PO Details{' '}
                    </h5>
                    <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="spo"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          SPO# <span className="text-red-600">*</span>
                        </label>
                        <input
                          type="text"
                          name="spo"
                          id="spo"
                          className="bg-gwhite border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          placeholder="SPO#"
                          required
                          value={spoValue}
                          onChange={(e) => setSpoValue(e.target.value)}
                        />
                      </div>
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="awardedDate"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Awarded Date <span className="text-red-600">*</span>
                        </label>
                        <input
                          type="date"
                          name="awardedDate"
                          id="awardedDate"
                          className="bg-gwhite border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          placeholder="SPO#"
                          required
                          value={awardedDateValue}
                          onChange={(e) => setAwardedDateValue(e.target.value)}
                        />
                      </div>
                    </div>
                  </section>
                  <section className="bg-white dark:bg-gray-900">
                    <h5 className="flex justify-between items-center text-lg font-semibold text-gray-900 dark:text-white mb-3 mt-6">
                      Agency Details{' '}
                    </h5>
                    <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="primaryContact"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Agency <span className="text-red-600">*</span>
                        </label>
                        <input
                          type="text"
                          name="poAgency"
                          id="poAgency"
                          className="bg-gwhite border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          placeholder="Government Agency Name"
                          value={selectedRFQ ? selectedRFQ.agency : ''}
                        />
                      </div>
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="primaryContractingOfficer"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Primary Contracting Officer{' '}
                          <span className="text-red-600">*</span>
                        </label>
                        <input
                          type="text"
                          name="primaryContractingOfficer"
                          id="primaryContractingOfficer"
                          className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          placeholder="John Doe"
                          value={
                            selectedRFQ ? selectedRFQ.primaryContactName : ''
                          }
                        />
                      </div>
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="primaryContractingOfficerEmail"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Primary Contracting Officer Email{' '}
                          <span className="text-red-600">*</span>
                        </label>
                        <input
                          type="text"
                          name="primaryContractingOfficerEmail"
                          id="primaryContractingOfficerEmail"
                          className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          placeholder="johndoe@gmail.com"
                          value={selectedRFQ ? selectedRFQ.primaryEmail : ''}
                        />
                      </div>
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="secondaryContractingOfficer"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Secondary Contracting Officer{' '}
                        </label>
                        <input
                          type="text"
                          name="secondaryContractingOfficer"
                          id="secondaryContractingOfficer"
                          className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          placeholder="John Doe"
                          value={
                            selectedRFQ ? selectedRFQ.secondaryContactName : ''
                          }
                        />
                      </div>
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="secondaryContractingOfficerEmail"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Secondary Contracting Officer Email{' '}
                        </label>
                        <input
                          type="text"
                          name="secondaryContractingOfficerEmail"
                          id="secondaryContractingOfficerEmail"
                          className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          placeholder="janedoe@gmail.com"
                          value={selectedRFQ ? selectedRFQ.secondaryEmail : ''}
                        />
                      </div>
                    </div>
                  </section>
                </div>
              </section>
            </div>
            <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
              <button
                type="button"
                onClick={() => {
                  addNewPO();
                  setOpenModal(false);
                }}
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Add PO
              </button>
              <button
                onClick={closeModal}
                type="button"
                className="ms-3 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddNewPosComponent;
