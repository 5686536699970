import { PencilSquareIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { deleteDelivery } from '../../database/set-delivery-data';

const getStatusProps = (value) => {
  switch (value) {
    case 'Delivered':
      return {
        badgeClass:
          'bg-green-500 text-white px-2 py-1 rounded-full text-center mx-5',
        displayValue: value,
      };
    case 'Not Shipped':
    case 'Pending':
      return {
        badgeClass:
          'bg-red-500 text-white px-2 py-1 rounded-full text-center mx-5',
        displayValue: 'Not Shipped',
      };
    case 'Shipped':
    case 'In Transit':
    case 'Out for Delivery':
    case 'In Route':
      return {
        badgeClass:
          'bg-yellow-500 text-white px-2 py-1 rounded-full text-center mx-5',
        displayValue: 'Shipped',
      };
    default:
      return {
        badgeClass:
          'bg-red-500 text-white px-2 py-1 rounded-full text-center mx-5',
        displayValue: 'Not Shipped',
      };
  }
};

const StatusCell = ({ value }) => {
  const { badgeClass, displayValue } = getStatusProps(value);

  return <div className={badgeClass}>{displayValue}</div>;
};

const TrackingIDCell = ({ value, data }) => {
  const trackingUrlGenerators = {
    FEDEX: (trackingNumber) =>
      `https://www.fedex.com/apps/fedextrack/?tracknumbers=${trackingNumber}`,
    USPS: (trackingNumber) =>
      `https://tools.usps.com/go/TrackConfirmAction?tLabels=${trackingNumber}`,
    UPS: (trackingNumber) =>
      `https://www.ups.com/track?loc=null&tracknum=${trackingNumber}`,
    DHL: (trackingNumber) =>
      `http://www.dhl.com/en/express/tracking.html?AWB=${trackingNumber}`,
    AMAZON: (trackingNumber) =>
      `https://www.amazon.com/progress-tracker/package/ref=ppx_yo_dt_b_track_package?_encoding=UTF8&itemId=&orderId=${trackingNumber}&packageIndex=0&shipmentId=`,
  };

  const trackingUrlGenerator =
    trackingUrlGenerators[data.trackingPartner.toUpperCase()];

  return trackingUrlGenerator ? (
    <a
      href={trackingUrlGenerator(value)}
      target="_blank"
      className="text-blue-700 underline hover:text-blue-900"
      rel="noopener noreferrer"
    >
      Track: {value}
    </a>
  ) : (
    value
  );
};

const viewRenderer = ({ data, setOpenEditModal, setSelectedId }) => {
  const handleClick = (event) => {
    const docId = event.currentTarget.getAttribute('data-doc-id');
    // console.log('docId', docId);
    setSelectedId(docId); // Set the selected id
    setOpenEditModal(true); // Open the modal
  };

  return (
    <div
      className="flex justify-center"
      data-doc-id={data.data.uniqueId}
      onClick={handleClick}
    >
      <PencilSquareIcon className="h-5 w-5 hover:text-blue-500 cursor-pointer" />
    </div>
  );
};

const onDeleteRenderer = ({ data }) => {
  // // console.log('idData: ', data);

  return (
    <button
      type="button"
      onClick={() => deleteDelivery(data.uniqueId)}
      className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
    >
      Delete
    </button>
  );
};

const onInvoiceGenerateRenderer = ({
  data,
  setOpenGenerateInvoicModal,
  setInvoiceSelectedId,
}) => {
  // // console.log('idData: ', data);

  return (
    <button
      type="button"
      onClick={() => {
        setInvoiceSelectedId(data.uniqueId);
        setOpenGenerateInvoicModal(true);
      }}
      className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
    >
      Generate Invoice
    </button>
  );
};

const orderColumns = (columns, order) => {
  if (!order) return columns;

  const orderedColumns = [];
  order.forEach((columnName) => {
    const column = columns.find((col) => col.name === columnName);
    if (column) {
      orderedColumns.push(column);
    }
  });

  // Add any remaining columns that are not specified in the order
  columns.forEach((column) => {
    if (!order.includes(column.name)) {
      orderedColumns.push(column);
    }
  });

  return orderedColumns;
};

const deliveryColumns = (
  setOpenEditModal,
  setSelectedId,
  tablePreferences = {},
  setInvoiceSelectedId,
  setOpenGenerateInvoicModal
) => {
  const columns = [
    {
      name: 'deliveryDate',
      header: 'Delivery Date',
      minWidth: 100,
      ...(tablePreferences.deliveryDate && {
        defaultWidth: tablePreferences.deliveryDate,
      }),
    },
    {
      name: 'spoId',
      header: 'SPO#',
      minWidth: 250,
      ...(tablePreferences.spoId && { defaultWidth: tablePreferences.spoId }),
    },
    {
      name: 'vpoId',
      header: 'VPO#',
      minWidth: 250,
      ...(tablePreferences.vpoId && { defaultWidth: tablePreferences.vpoId }),
    },
    {
      name: 'vendorName',
      header: 'Vendor Name',
      minWidth: 200,
      ...(tablePreferences.vendorName && {
        defaultWidth: tablePreferences.vendorName,
      }),
    },
    {
      name: 'delQty',
      header: 'Shipped Qty',
      minWidth: 150,
      ...(tablePreferences.delQty && { defaultWidth: tablePreferences.delQty }),
    },
    {
      name: 'saiQty',
      header: 'Sai Qty',
      minWidth: 150,
      ...(tablePreferences.saiQty && { defaultWidth: tablePreferences.saiQty }),
    },
    {
      name: 'trackingPartner',
      header: 'Tracking Partner',
      minWidth: 200,
      ...(tablePreferences.trackingPartner && {
        defaultWidth: tablePreferences.trackingPartner,
      }),
    },
    {
      name: 'trackingID',
      header: 'Tracking ID',
      minWidth: 250,
      render: TrackingIDCell,
      ...(tablePreferences.trackingID && {
        defaultWidth: tablePreferences.trackingID,
      }),
    },
    {
      name: 'status',
      header: 'Status',
      minWidth: 200,
      render: StatusCell,
      ...(tablePreferences.status && { defaultWidth: tablePreferences.status }),
    },
    {
      name: 'updatedAt',
      header: 'Updated At',
      minWidth: 200,
      ...(tablePreferences.updatedAt && {
        defaultWidth: tablePreferences.updatedAt,
      }),
    },
    {
      name: 'update',
      header: '',
      minWidth: 100,
      render: (data) => viewRenderer({ data, setOpenEditModal, setSelectedId }),
      ...(tablePreferences.update && { defaultWidth: tablePreferences.update }),
    },
    {
      name: 'invoiceGenerate',
      header: 'Generate Invoice',
      minWidth: 50,
      render: ({ value, data }) =>
        onInvoiceGenerateRenderer({
          data,
          setOpenGenerateInvoicModal,
          setInvoiceSelectedId,
        }),
      ...(tablePreferences.invoiceGenerate && {
        defaultWidth: tablePreferences.invoiceGenerate,
      }),
    },
    {
      name: 'delete',
      header: 'Delete',
      minWidth: 50,
      render: ({ value, data }) => onDeleteRenderer({ data }),
      ...(tablePreferences.delete && { defaultWidth: tablePreferences.delete }),
    },
  ];

  const order = tablePreferences?.order;

  // Order the columns
  const orderedColumns = orderColumns(columns, order);

  return orderedColumns;
};
export default deliveryColumns;
