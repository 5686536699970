import { deleteDoc, doc, getFirestore } from 'firebase/firestore';
import { errorAlert, successAlert } from '../components/alerts';
import { app } from '../resources/gcp-config';

const db = getFirestore(app);

export const deleteEmail = async (emailId) => {
  try {
    await deleteDoc(doc(db, 'email-records', emailId));
    successAlert('Success!', 'Email has been deleted.');
  } catch (error) {
    console.error('Error deleting Email:', error);
    errorAlert('Error!', 'An error occurred while deleting the Email.');
  }
};
