import { app } from '../resources/gcp-config';
import {
  getFirestore,
  doc,
  getDoc,
  onSnapshot,
  collection,
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const db = getFirestore(app);
const auth = getAuth(app);

/**
 * The function fetchUserData retrieves the data of the currently signed-in user from a Firestore
 * database.
 * @returns The function `fetchUserData` returns the data of the user document if it exists, otherwise
 * it returns `null`.
 */
export const fetchUserData = async () => {
  const user = auth.currentUser;

  if (user) {
    const docRef = doc(db, 'users', user.uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return { ...docSnap.data(), docId: user.uid };
    } else {
      console.error(new Error('No such document!'));
      return null;
    }
  } else {
    console.error(new Error('No user is signed in.'));
  }
};

export const fetchAllUsersData = (callback) => {
  const q = collection(db, 'users');
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const users = querySnapshot.docs.map((doc) => {
      return { ...doc.data(), docId: doc.id };
    });
    callback(users);
  });

  return unsubscribe;
};

export const fetchSingleUserData = async (docId) => {
  const docRef = doc(db, 'users', docId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return { ...docSnap.data(), docId: docSnap.id };
  } else {
    console.error(new Error('No such document!'));
    return null;
  }
};
