import React, { useCallback, useEffect, useState } from 'react';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import deliveryColumns from './deliveries-columns'; // Adjust the import path
import getDeliveryData from './deliveries-data';
import { fetchAllDeliveriesData } from '../../database/fetch-deliveries-data';
import deliveryFilterValue from '../deliveries/deliveries-sort';
import { Editor } from '@tinymce/tinymce-react';
import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';
import {
  EnvelopeIcon,
  NewspaperIcon,
  PaperAirplaneIcon,
} from '@heroicons/react/24/outline';
import { storeFileDeliveries } from '../../storage/store-files-pos';
import Loader from '../loader/loader';
import { errorAlert, successAlert } from '../alerts';
import { updateDelivery } from '../../database/set-delivery-data';
import { fetchAllEmailTemplates } from '../../database/fetch-email-template.js';
import fetchTablePref from '../../database/fetch-table-pref.js';
import updateTablePref from '../../database/set-table-pref.js';
import GenerateInvoiceModal from './deliveries-invoice-generate-modal.js';

const gridStyle = { height: '80vh' };

const DeliveryTable = ({ setOpenEditModal, setSelectedId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [deliveryData, setDeliveryData] = useState([]);
  const [allDeliveryDetails, setAllDeliveryDetails] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [openMailModal, setopenMailModal] = useState(false);
  const [openShipmentModal, setopenShipmentModal] = useState(false);
  const [filteredDeliveryDetails, setFilteredDeliveryDetails] = useState([]);
  const [filteredDeliveryInvoiceDetails, setFilteredDeliveryInvoiceDetails] =
    useState([]);
  const [invoiceAttachmentsList, setInvoiceAttachmentsList] = useState([]);
  const [shipmentAttachmentsList, setShipmentAttachmentsList] = useState([]);
  const [shipmentTempAttachments, setShipmentTempAttachments] = useState([]);
  const [invoiceTempAttachments, setInvoiceTempAttachments] = useState([]);
  const [shipmentEmails, setShipmentEmails] = useState([]);
  const [shipmentFocused, setShipmentFocused] = useState(false);
  const [isEditingShipment, setIsEditingShipment] = useState(false);
  const [shipmentMailContent, setShipmentMailContent] = useState('');
  const [shipmentMailSubject, setShipmentMailSubject] = useState('');
  const [editingIndex, setEditingIndex] = useState(null);
  const [invoiceEmails, setInvoiceEmails] = useState([]);
  const [invoiceFocused, setInvoiceFocused] = useState(false);
  const [isEditingInvoice, setIsEditingInvoice] = useState(false);
  const [invoiceMailContent, setInvoiceMailContent] = useState('');
  const [invoiceMailSubject, setInvoiceMailSubject] = useState('');
  const [editingInvoiceIndex, setEditingInvoiceIndex] = useState(null);

  const [emailTemplates, setEmailTemplates] = useState([]);
  const [editingInvoiceVariables, setEditingInvoiceVariables] = useState({});
  const [processedInvoiceContent, setProcessedInvoiceContent] = useState('');
  const [processedShipmentContent, setProcessedShipmentContent] = useState('');
  const [editingShipmentVariables, setEditingShipmentVariables] = useState({});
  const [tablePrefrences, setTablePrefrences] = useState({});
  const [openGenerateInvoicModal, setOpenGenerateInvoicModal] = useState(false);
  const [invoiceSelectedId, setInvoiceSelectedId] = useState(null);
 const handleShipmentUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const metadata = { contentType: file.type };

    const fileId = 'file-' + Date.now();

    const url = await storeFileDeliveries(file, fileId, metadata);

    setShipmentAttachmentsList([
      ...shipmentAttachmentsList,
      {
        id: shipmentAttachmentsList.length + 1,
        name: `File-${Math.floor(100 + Math.random() * 900)}`,
        url: url,
      },
    ]);
  };

  const handleShipmentAttachmentChange = (e) => {
    const selectedFile = shipmentTempAttachments.find(
      (file) => file.fileName === e.target.value || file.name === e.target.value
    );
    if (selectedFile) {
      setShipmentAttachmentsList([
        ...shipmentAttachmentsList,
        {
          name: selectedFile.fileName || selectedFile.name,
          url: selectedFile.attachmentUrl,
        },
      ]);
    }
  };

  const handleShipmentDeleteAttachmentRow = (index) => {
    const newShipmentAttachmentsList = [...shipmentAttachmentsList];
    newShipmentAttachmentsList.splice(index, 1);
    setShipmentAttachmentsList(newShipmentAttachmentsList);
  };

  const handleInvoiceUpload = async (event) => {
    setIsLoading(true);
    const file = event.target.files[0];
    if (!file) return;

    const metadata = { contentType: file.type };

    const fileId = 'file-' + Date.now();

    const url = await storeFileDeliveries(file, fileId, metadata); // Please replace 'storeFileDeliveries' with the correct function to upload files in your scenario.

    setInvoiceAttachmentsList([
      ...invoiceAttachmentsList,
      {
        id: invoiceAttachmentsList.length + 1,
        name: `File-${Math.floor(100 + Math.random() * 900)}`,
        url: url,
      },
    ]);
    setIsLoading(false);
  };

  const handleInvoiceAttachmentChange = (e) => {
    const selectedFile = invoiceTempAttachments.find(
      (file) => file.fileName === e.target.value || file.name === e.target.value
    );
    if (selectedFile) {
      setInvoiceAttachmentsList([
        ...invoiceAttachmentsList,
        {
          name: selectedFile.fileName || selectedFile.name,
          url: selectedFile.attachmentUrl, // Ensure 'attachmentUrl' is the correct property based on your files' structure.
        },
      ]);
    }
  };

  const handleInvoiceDeleteAttachmentRow = (index) => {
    const newInvoiceAttachmentsList = [...invoiceAttachmentsList];
    newInvoiceAttachmentsList.splice(index, 1);
    setInvoiceAttachmentsList(newInvoiceAttachmentsList);
  };

  const onSelectionItemsChange = useCallback(({ selected }) => {
    setSelectedItems(selected);
  }, []);

  useEffect(() => {
    const unsubscribe = getDeliveryData((data) => {
      setDeliveryData(data);
    });

    fetchTablePref('deliveriesTable')
      .then((tableData) => {
        if (tableData) setTablePrefrences(tableData);
      })
      .catch((err) => console.log(err));

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const unsubscribe = fetchAllDeliveriesData((data) => {
      setAllDeliveryDetails(data);
      console.log('All Deliveries: ', data);

      fetchAllEmailTemplates((templates) => {
        const invoiceTemplate = templates.filter((template) => {
          return template.mailType === 'del_in';
        });
        const shipmentTemplate = templates.filter((template) => {
          return template.mailType === 'del_sh';
        });

        // console.log(invoiceTemplate);
        // console.log(shipmentTemplate);

        if (invoiceTemplate.length > 0) {
          setInvoiceMailContent(invoiceTemplate[0].template);
        }

        if (shipmentTemplate.length > 0) {
          setShipmentMailContent(shipmentTemplate[0].template);
        }
        setEmailTemplates(templates);
      });
    });

    return () => unsubscribe();
  }, []);

  const TrackingIDCell = ({ partner, trackingNumber }) => {
    console.log(partner, trackingNumber);
    const trackingUrlGenerators = {
      FEDEX: (trackingNumber) =>
        `https://www.fedex.com/apps/fedextrack/?tracknumbers=${trackingNumber}`,
      USPS: (trackingNumber) =>
        `https://tools.usps.com/go/TrackConfirmAction?tLabels=${trackingNumber}`,
      UPS: (trackingNumber) =>
        `https://www.ups.com/track?loc=null&tracknum=${trackingNumber}`,
      DHL: (trackingNumber) =>
        `http://www.dhl.com/en/express/tracking.html?AWB=${trackingNumber}`,
      AMAZON: (trackingNumber) =>
        `https://www.amazon.com/progress-tracker/package/ref=ppx_yo_dt_b_track_package?_encoding=UTF8&itemId=&orderId=${trackingNumber}&packageIndex=0&shipmentId=`,
    };

    // Call the function with trackingNumber to get the URL string
    const getTrackingUrl = trackingUrlGenerators[partner.toUpperCase()];

    return getTrackingUrl(trackingNumber);
  };

  useEffect(() => {
    const sendInvoices = async () => {
      // console.log(selectedItems);
      // console.log(allDeliveryDetails);
      const selectedItemKeys = Object.keys(selectedItems);

      const filteredDeliveryDetails = allDeliveryDetails.filter((item) =>
        selectedItemKeys.includes(item.docId)
      );

      // Defining helper function
      const objectHasNoNullValues = (obj) =>
        Object.values(obj).every((val) => val !== null && val !== undefined);

      // Using filter and map
      const newDataArray = filteredDeliveryDetails
        .filter((item) => objectHasNoNullValues(item))
        .map((item) => {
          console.log('item: ', item);
          // Convert 'deliveryDate' string to a Date object
          const originalDate = new Date(item.deliveryDate);

          // Extract day, month, and year components
          const deliveryDay = originalDate.getDate();
          const deliveryMonth = originalDate.getMonth() + 1; // Months are zero-indexed, so we add 1
          const deliveryYear = originalDate.getFullYear();

          // Format the deliveryDate as 'MM-DD-YYYY'
          const formattedDeliveryDate = `${deliveryMonth
            .toString()
            .padStart(2, '0')}-${deliveryDay
            .toString()
            .padStart(2, '0')}-${deliveryYear}`;

          const locals = {
            spo: item.spoId || item.spo,
            primaryContactName: item.primaryContactName,
            primaryEmail: item.primaryEmail,
            primaryPhone: item.primaryPhone,
            itemName: item.itemName,
            date: formattedDeliveryDate,
            trackingID: item.trackingID,
            trackingPartner: item.trackingPartner,
            deliveryQty: item.deliveryQty,
            agency: item.agency,
            deliveryAddress: item.deliveryAddress,
            deliveryStatus: item.deliveryStatus,
            deliveryPartner: item.deliveryPartner || item.trackingPartner,
          };

          const processedContent = replaceVariables2(
            invoiceMailContent,
            locals
          );
          // console.log('maill: ', processedContent);

          return {
            spoId: item.spoId || item.spo,
            vpoId: item.vpoId,
            primaryContactName: item.primaryContactName,
            primaryEmail: item.primaryEmail,
            primaryPhone: item.primaryPhone,
            itemName: item.itemName,
            deliveryDate: formattedDeliveryDate,
            trackingId: item.trackingID,
            trackingPartner: item.trackingPartner,
            deliveryQty: item.deliveryQty,
            deliveryAddress: item.deliveryAddress,
            vQty: item.saiQty,
            emails: [item.primaryEmail, item.secondaryEmail],
            vendorName: item.vendorName,
            agency: item.agency,
            deliveryStatus: item.deliveryStatus,
            mailContent: processedContent,
            mailSubject: `Delivery Confirmation for Partial Shipment - Purchase Order # ${
              item.spoId || item.spo
            }`,
            attachments: [
              ...(item.attachments ? item.attachments : []),
              ...(item.packetsAttachments ? item.packetsAttachments : []),
              ...(item.posAttachments ? item.posAttachments : []),
              ...(item.sendAttachments ? item.sendAttachments : []),
            ],
            // sendAttachments: [...item.sendAttachments],
            sendAttachments: [],
          };
        });

      // console.log(newDataArray);

      setFilteredDeliveryInvoiceDetails(newDataArray);
    };
    sendInvoices();
  }, [allDeliveryDetails, invoiceMailContent, selectedItems]);

  useEffect(() => {
    // console.log(selectedItems);
    // console.log(allDeliveryDetails);
    const selectedItemKeys = Object.keys(selectedItems);

    const filteredDeliveryDetails = allDeliveryDetails.filter((item) =>
      selectedItemKeys.includes(item.docId)
    );

    // Defining helper function
    const objectHasNoNullValues = (obj) =>
      Object.values(obj).every((val) => val !== null && val !== undefined);

    // Using filter and map
    const newDataArray = filteredDeliveryDetails
      .filter((item) => objectHasNoNullValues(item))
      .map((item) => {
        // Convert 'deliveryDate' string to a Date object
        const originalDate = new Date(item.deliveryDate);

        // Extract day, month, and year components
        const deliveryDay = originalDate.getDate();
        const deliveryMonth = originalDate.getMonth() + 1; // Months are zero-indexed, so we add 1
        const deliveryYear = originalDate.getFullYear();

        // Format the deliveryDate as 'MM-DD-YYYY'
        const formattedDeliveryDate = `${deliveryMonth
          .toString()
          .padStart(2, '0')}-${deliveryDay
          .toString()
          .padStart(2, '0')}-${deliveryYear}`;

        const locals = {
          spo: item.spoId || item.spo,
          primaryContactName: item.primaryContactName,
          primaryEmail: item.primaryEmail,
          primaryPhone: item.primaryPhone,
          itemName: item.itemName,
          date: formattedDeliveryDate,
          trackingID: item.trackingID,
          trackingPartner: item.trackingPartner,
          deliveryQty: item.deliveryQty,
          agency: item.agency,
          deliveryAddress: item.deliveryAddress,
          deliveryStatus: item.deliveryStatus,
          deliveryPartner: item.deliveryPartner || item.trackingPartner,
        };

        const processedContent = replaceVariables2(shipmentMailContent, locals);

        return {
          spoId: item.spoId || item.spo,
          vpoId: item.vpoId,
          primaryContactName: item.primaryContactName,
          primaryEmail: item.primaryEmail,
          primaryPhone: item.primaryPhone,
          itemName: item.itemName,
          deliveryDate: formattedDeliveryDate,
          trackingId: item.trackingID,
          trackingPartner: item.trackingPartner,
          deliveryQty: item.deliveryQty,
          vQty: item.saiQty,
          emails: item.secondaryEmail
            ? [item.primaryEmail, item.secondaryEmail]
            : [item.primaryEmail],
          vendorName: item.vendorName,
          agency: item.agency,
          deliveryStatus: item.deliveryStatus,
          mailContent: processedContent,
          mailSubject: `Partial Shipment Notification for Purchase Order # ${
            item.spoId || item.spo
          }`,
          attachments: [
            ...(item.attachments ? item.attachments : []),
            ...(item.packetsAttachments ? item.packetsAttachments : []),
          ],
          sendAttachments: [],
        };
      });

    // console.log(newDataArray);

    setFilteredDeliveryDetails(newDataArray);
  }, [allDeliveryDetails, selectedItems, shipmentMailContent]);

  async function sendShipmentEmailFunction() {
    console.log(filteredDeliveryDetails);
    console.log(selectedItems);
    const emails = filteredDeliveryDetails.map((content) => {
      const validMailAddresses = content.emails.filter(isValidEmail);
      return {
        mailSubject: content.mailSubject,
        mailAddresses: validMailAddresses,
        mailBody: content.mailContent,
        mailAttachments: content.sendAttachments,
      };
    });
    console.log(emails);
    setIsLoading(true);

    try {
      emails.forEach(async (email) => {
        const response = await fetch(
          //`${process.env.REACT_APP_URL}send-email/rfq-packet`,
          `${process.env.REACT_APP_URL}/send-email/send-shipment-notification`,
          // 'http://localhost:3001/send-email/send-shipment-notification',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(email),
          }
        );
        if (!response.ok) {
          setIsLoading(false);
          throw new Error('Network response was not ok');
        }

        await response.json();
        successAlert('Emails sent successfully');
        setopenShipmentModal(false);
        setIsLoading(false);
        // autoAddBid(docId, selectedVendorsArray, selectedItemsArray);
        // updateRFQ(docId, { packetStatus: 'Sent' });
      });
    } catch (error) {
      setIsLoading(false);
      errorAlert('Failed to send request quotes via email!');
    }
  }

  // Function to check if a single email address is valid
  function isValidEmail(email) {
    // Use a regular expression to check for a valid email pattern
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  async function sendInvoiceEmailFunction() {
    const emails = filteredDeliveryInvoiceDetails.map((content) => {
      console.log('content: ', content);
      const validMailAddresses = content.emails.filter(isValidEmail);
      return {
        mailSubject: content.mailSubject,
        mailAddresses: validMailAddresses,
        mailBody: content.mailContent,
        mailAttachments: content.sendAttachments,
      };
    });
    let selectedItemsArray = Object.keys(selectedItems);
    // console.log(selectedItemsArray);
    console.log('Emails: ', emails);
    setIsLoading(true);

    try {
      for (const email of emails) {
        const response = await fetch(
          //`${process.env.REACT_APP_URL}send-email/rfq-packet`,
          `${process.env.REACT_APP_URL}/send-email/send-invoice`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(email),
          }
        );
        if (!response.ok) {
          setIsLoading(false);
          throw new Error('Network response was not ok');
        }

        await response.json();
        successAlert('Emails sent successfully');
        setopenMailModal(false);
        setIsLoading(false);

        selectedItemsArray.forEach((itemId) => {
          console.log(itemId);
          updateDelivery(
            { invoiceSent: 'Sent', invoiceSentDate: new Date() },
            itemId
          );
        });
      }
    } catch (error) {
      setIsLoading(false);
      errorAlert('Failed to send request quotes via email!');
    }
  }

  // shippo shipment tracking test
  useEffect(() => {
    const shippoHandle = async () => {
      fetch(`${process.env.REACT_APP_URL}/shippo-add`, {
        method: 'POST',
      })
        .then((response) => response.text())
        .then((result) => console.log('tracking data received'))
        .catch((error) => console.error(error));
    };

    shippoHandle();
  }, []);

  const replaceVariables2 = (template, values) => {
    const variableRegex = /\{\{([^{}]+)\}\}/g;
    const tableRegex = /<table[^>]*>[\s\S]*?<\/table>/i;
    const hasTable = tableRegex.test(template);

    if (hasTable) {
      const tableContent = template.match(tableRegex)[0];
      const tableRows = tableContent.match(/<tr[^>]*>[\s\S]*?<\/tr>/gi);

      if (tableRows && tableRows.length >= 2) {
        const secondRow = tableRows[1];
        // console.log(values);
        let replacedSecondRow;
        if (Array.isArray(values)) {
          replacedSecondRow = values.map((item) => {
            // Replace variables in the second row with item values
            return secondRow.replace(variableRegex, (match, variable) => {
              return item[variable] || '';
            });
          });
        }
        const modifiedTable = tableContent.replace(
          secondRow,
          replacedSecondRow
        );
        template = template.replace(tableRegex, modifiedTable);
      }
    }

    let replacedTemplate = template.replace(
      variableRegex,
      (match, variable) => {
        return values[variable] || '';
      }
    );
    return replacedTemplate;
  };

  function htmlToText(html) {
    if (!html) return '';
    // Replace <br> tags and block elements endings with line breaks
    const lineBreaksHtml = html
      .replace(/<br\s*\/?>/gi, '\n')
      .replace(/<\/(p|div|h[1-6])>/gi, '\n');
    // Create a new div element and set its innerHTML to the modified HTML
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = lineBreaksHtml;
    // Extract text from the div and replace multiple line breaks with a single one
    return tempDiv.textContent || tempDiv.innerText || '';
  }

  const replaceSubjectVariables = (subject, values) => {
    const variableRegex = /\{\{([^{}]+)\}\}/g;

    return subject.replace(variableRegex, (match, variable) => {
      return values[variable] || '';
    });
  };

  const handleInvoiceTemplateSelection = async (emailTempId) => {
    const selectedTemplate = emailTemplates.filter(
      (template) => template.emailTempId === emailTempId
    );
    const rawContent = selectedTemplate[0]?.template;
    const rawSubject = selectedTemplate[0]?.mailSubject;
    console.log(emailTemplates);
    if (rawContent) {
      const processedTemp = replaceVariables2(
        rawContent,
        editingInvoiceVariables
      );
      setProcessedInvoiceContent(processedTemp);
    }
    if (rawSubject) {
      const processedMailSubject = replaceSubjectVariables(
        rawSubject,
        editingInvoiceVariables
      );
      setInvoiceMailSubject(processedMailSubject);
    }
  };

  const handleShipmentTemplateSelection = async (emailTempId) => {
    const selectedTemplate = emailTemplates.filter(
      (template) => template.emailTempId === emailTempId
    );
    const rawContent = selectedTemplate[0]?.template;
    const rawSubject = selectedTemplate[0]?.mailSubject;
    console.log(selectedTemplate);
    if (rawContent) {
      const processedTemp = replaceVariables2(
        rawContent,
        editingShipmentVariables
      );
      setProcessedShipmentContent(processedTemp);
    }
    if (rawSubject) {
      const processedMailSubject = replaceSubjectVariables(
        rawSubject,
        editingShipmentVariables
      );
      setShipmentMailSubject(processedMailSubject);
    }
  };

  const handleColumnResize = async (e) => {
    const updatedColumnName = e.column.name;
    const updatedWidth = e.width;

    const preferences = {
      [updatedColumnName]: updatedWidth,
    };

    await updateTablePref('deliveriesTable', preferences);
  };

  const handleColumnOrderChange = async (e) => {
    await updateTablePref('deliveriesTable', { order: e });
  };
  return (
    <>
      {isLoading && <Loader />}
      <ReactDataGrid
        style={gridStyle}
        idProperty="uniqueId"
        defaultFilterValue={deliveryFilterValue}
        columns={deliveryColumns(
          setOpenEditModal,
          setSelectedId,
          tablePrefrences,
          setInvoiceSelectedId,
          setOpenGenerateInvoicModal
        )}
        onColumnResize={handleColumnResize}
        onColumnOrderChange={handleColumnOrderChange}
        dataSource={deliveryData}
        pagination
        defaultLimit={20}
        rowHeight={60}
        enableSelection
        enableKeyboardNavigation
        showCellBorders={'horizontal'}
        selected={selectedItems}
        checkboxColumn
        onSelectionChange={onSelectionItemsChange}
        columnMenu={{ lockable: false }}
      />
      <button
        onClick={() => {
          setopenMailModal(true);
        }}
        className="inline-flex mt-4 mx-3 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
      >
        <NewspaperIcon className="flex-shrink-0 w-5 h-5 mr-3 -ml-1" />
        Send Invoice
      </button>
      <button
        onClick={() => {
          setopenShipmentModal(true);
        }}
        className="inline-flex mt-4 mx-3 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
      >
        <EnvelopeIcon className="flex-shrink-0 w-5 h-5 mr-3 -ml-1" />
        Send Shipment Notification
      </button>

      <div
        id="addUserData-modal"
        tabIndex="-1"
        aria-hidden="true"
        className={
          openMailModal
            ? 'flex items-center justify-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full h-[calc(100%)] max-h-full bg-black bg-opacity-50'
            : 'hidden'
        }
      >
        <div className="relative p-4 w-full max-w-7xl max-h-full">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                Send Invoice Email
              </h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={() => setopenMailModal(false)}
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <div className="p-4 md:p-5 space-y-4 overflow-y-auto max-h-[calc(100vh-15rem)]">
              <section className="bg-white dark:bg-gray-900">
                <div className="mx-auto max-w-7xl">
                  <div className="relative overflow-x-auto shadow-md sm:rounded-lg mb-6">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                      <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                          <th scope="col" className="px-6 py-3">
                            To
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Subject
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Mail Body
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Attachments
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredDeliveryInvoiceDetails.map((item, index) => (
                          <tr
                            key={index}
                            className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"
                          >
                            <th
                              scope="row"
                              className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              {item.emails.map((email, emailIndex) => (
                                <p key={emailIndex}> {email} </p>
                              ))}
                            </th>
                            <td className="px-6 py-4">{item.mailSubject}</td>
                            <td className="px-6 py-4">
                              {htmlToText(item.mailContent)}
                            </td>
                            <td className="px-6 py-4">
                              {item.sendAttachments &&
                              item.sendAttachments.length > 0
                                ? item.sendAttachments.length
                                : 0}{' '}
                            </td>
                            <td className="px-6 py-4">
                              <p
                                className="cursor-pointer font-medium text-blue-600 dark:text-blue-500 hover:underline"
                                onClick={() => {
                                  console.log(
                                    // item.emails.filter((email) => email),
                                    'Item: ',
                                    item
                                  );
                                  setInvoiceEmails(
                                    item.emails.filter((email) => email)
                                  );
                                  setInvoiceMailSubject(item.mailSubject);
                                  setProcessedInvoiceContent(item.mailContent);
                                  setInvoiceTempAttachments(item.attachments);
                                  setEditingInvoiceIndex(index);
                                  setIsEditingInvoice(true);
                                  setEditingInvoiceVariables({
                                    spo: item.spoId || item.spo,
                                    primaryContactName: item.primaryContactName,
                                    primaryEmail: item.primaryEmail,
                                    primaryPhone: item.primaryPhone,
                                    itemName: item.itemName,
                                    date: item.deliveryDate,
                                    trackingID: item.trackingId,
                                    trackingPartner: item.trackingPartner,
                                    deliveryQty: item.deliveryQty,
                                    agency: item.agency,
                                    deliveryAddress: item.deliveryAddress,
                                    deliveryStatus: item.deliveryStatus,
                                    deliveryPartner:
                                      item.deliveryPartner ||
                                      item.trackingPartner,
                                  });
                                }}
                              >
                                Edit
                              </p>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  {isEditingInvoice && (
                    <>
                      <div className="grid grid-cols-12 gap-4">
                        <div className="col-span-12">
                          <label
                            htmlFor="shipment_subject"
                            className="col-span-1 mb-2 font-medium text-gray-900 dark:text-white items-center inline-flex"
                          >
                            Choose Template
                          </label>
                          <select
                            id="shipment_subject"
                            className="col-span-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Write the Email Subject Over Here"
                            onChange={(e) => {
                              handleInvoiceTemplateSelection(e.target.value);
                            }}
                          >
                            <option value="">Select Template</option>
                            {emailTemplates?.map((template) => {
                              if (template.mailType === 'del_in') {
                                return (
                                  <option
                                    key={template.emailTempId}
                                    value={template.emailTempId}
                                  >
                                    {template.title}
                                  </option>
                                );
                              }
                            })}
                          </select>
                        </div>
                        <label
                          htmlFor="invoice_to"
                          className="col-span-1 mb-2 font-medium text-gray-900 dark:text-white items-center inline-flex"
                        >
                          To
                        </label>
                        <div id="invoice_to" className="col-span-11 mb-4">
                          <ReactMultiEmail
                            placeholder="Input your email"
                            emails={invoiceEmails}
                            onChange={(_emails) => {
                              setInvoiceEmails(_emails);
                            }}
                            onFocus={() => setInvoiceFocused(true)}
                            onBlur={() => setInvoiceFocused(false)}
                            getLabel={(email, index, removeEmail) => {
                              return (
                                <div
                                  data-tag
                                  key={index}
                                  data-tag-item
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    padding: '5px',
                                    border: '1px solid black',
                                    borderRadius: '3px',
                                  }}
                                >
                                  <span>{email}</span>
                                  <span
                                    data-tag-handle
                                    onClick={() => removeEmail(index)}
                                  >
                                    ×
                                  </span>
                                </div>
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div className="grid grid-cols-12 gap-6 mb-6">
                        <label
                          htmlFor="shipment_subject"
                          className="col-span-1 mb-2 font-medium text-gray-900 dark:text-white items-center inline-flex"
                        >
                          Subject
                        </label>
                        <input
                          type="text"
                          id="shipment_subject"
                          value={invoiceMailSubject}
                          className="col-span-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder="Write the Email Subject Over Here"
                          required
                          onChange={(e) => {
                            setInvoiceMailSubject(e.target.value);
                          }}
                        />
                      </div>
                      <div className="w-full">
                        <Editor
                          apiKey={process.env.REACT_APP_TINYMCE_EDITOR}
                          init={{
                            plugins:
                              ' anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                            toolbar:
                              'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                            ai_request: (request, respondWith) =>
                              respondWith.string(() =>
                                Promise.reject(
                                  'See docs to implement AI Assistant'
                                )
                              ),
                          }}
                          value={processedInvoiceContent}
                          onEditorChange={(content, editor) => {
                            setProcessedInvoiceContent(content);
                          }}
                        />
                      </div>
                      <div className="w-full mt-4">
                        <section className="bg-white dark:bg-gray-900">
                          <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                  <th scope="col" className="px-6 py-3">
                                    Attachment Name
                                  </th>
                                  <th scope="col" className="px-6 py-3">
                                    Attachment
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-center"
                                  >
                                    <div className="flex">
                                      <input
                                        type="file"
                                        id="file-upload-invoice"
                                        style={{ display: 'none' }}
                                        onChange={handleInvoiceUpload}
                                      />
                                      <button
                                        id="states-button"
                                        data-dropdown-toggle="dropdown-states"
                                        className="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-500 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600"
                                        type="button"
                                        onClick={() =>
                                          document
                                            .getElementById(
                                              'file-upload-invoice'
                                            )
                                            .click()
                                        }
                                      >
                                        Upload
                                      </button>
                                      <label
                                        htmlFor="states"
                                        className="sr-only"
                                      >
                                        Choose a Existing File
                                      </label>
                                      <select
                                        onChange={handleInvoiceAttachmentChange}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-e-lg border-s-gray-100 dark:border-s-gray-700 border-s-2 focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                      >
                                        {invoiceTempAttachments.map(
                                          (attachment, index) => {
                                            const label =
                                              attachment.fileName ||
                                              attachment.name ||
                                              `File-${Math.floor(
                                                100 + Math.random() * 900
                                              )}`;

                                            return (
                                              <option key={index} value={label}>
                                                {label}
                                              </option>
                                            );
                                          }
                                        )}
                                      </select>
                                    </div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {invoiceAttachmentsList.map((row, index) => (
                                  <tr key={row.id}>
                                    <td>
                                      <input
                                        type="text"
                                        placeholder="Attachment Name"
                                        value={row.name}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 mx-4 my-2"
                                      />
                                    </td>
                                    <td>
                                      <a
                                        href={row.url}
                                        target="_blank"
                                        className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 mx-4 my-2"
                                      >
                                        {row.url}
                                      </a>
                                    </td>
                                    <td className="text-center">
                                      <button
                                        onClick={() => {
                                          if (
                                            window.confirm(
                                              'Are you sure you want to delete this row?'
                                            )
                                          ) {
                                            handleInvoiceDeleteAttachmentRow(
                                              index
                                            );
                                          }
                                        }}
                                        className="text-red-500 hover:text-red-700 text-center"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          strokeWidth={1.5}
                                          stroke="currentColor"
                                          className="w-6 h-6 mx-auto"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                          />
                                        </svg>
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </section>
                      </div>
                      <div className="w-full text-right">
                        <button
                          type="button"
                          onClick={() => {
                            let updatedFilteredInvoiceDetails = [
                              ...filteredDeliveryInvoiceDetails,
                            ];
                            updatedFilteredInvoiceDetails[editingInvoiceIndex] =
                              {
                                ...updatedFilteredInvoiceDetails[
                                  editingInvoiceIndex
                                ],
                                emails: invoiceEmails,
                                mailSubject: invoiceMailSubject,
                                mailContent: processedInvoiceContent,
                                sendAttachments: invoiceAttachmentsList,
                              };
                            setFilteredDeliveryInvoiceDetails(
                              updatedFilteredInvoiceDetails
                            );

                            setEditingInvoiceIndex(null);
                            setIsEditingInvoice(false);
                            setInvoiceAttachmentsList([]);
                            setInvoiceTempAttachments([]);
                          }}
                          className="mt-3 inline-flex text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        >
                          Save
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </section>
            </div>
            <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
              <button
                type="button"
                onClick={sendInvoiceEmailFunction}
                className="inline-flex text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                <PaperAirplaneIcon className="flex-shrink-0 w-5 h-5 mr-3 -ml-1" />
                Send Emails
              </button>
              <button
                onClick={() => setopenMailModal(false)}
                type="button"
                className="ms-3 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        id="addUserData-modal"
        tabIndex="-1"
        aria-hidden="true"
        className={
          openShipmentModal
            ? 'flex items-center justify-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full h-[calc(100%)] max-h-full bg-black bg-opacity-50'
            : 'hidden'
        }
      >
        <div className="relative p-4 w-full max-w-7xl max-h-full">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                Send Shipment Email
              </h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={() => setopenShipmentModal(false)}
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <div className="p-4 md:p-5 space-y-4 overflow-y-auto max-h-[calc(100vh-15rem)]">
              <section className="bg-white dark:bg-gray-900">
                <div className="mx-auto max-w-7xl">
                  <div className="relative overflow-x-auto shadow-md sm:rounded-lg mb-6">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                      <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                          <th scope="col" className="px-6 py-3">
                            To
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Subject
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Mail Body
                          </th>{' '}
                          <th scope="col" className="px-6 py-3">
                            Attachments
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredDeliveryDetails.map((item, index) => (
                          <tr
                            key={index}
                            className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"
                          >
                            <th
                              scope="row"
                              className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              {item.emails.map((email, emailIndex) => (
                                <p key={emailIndex}> {email} </p>
                              ))}
                            </th>
                            <td className="px-6 py-4">{item.mailSubject}</td>
                            <td className="px-6 py-4">
                              {htmlToText(item.mailContent)}
                            </td>
                            <td className="px-6 py-4">
                              {item.sendAttachments &&
                              item.sendAttachments.length > 0
                                ? item.sendAttachments.length
                                : 0}
                            </td>
                            <td className="px-6 py-4">
                              <p
                                className="cursor-pointer font-medium text-blue-600 dark:text-blue-500 hover:underline"
                                onClick={() => {
                                  console.log(
                                    item.emails.filter((email) => email)
                                  );
                                  setShipmentEmails(
                                    item.emails.filter((email) => email)
                                  );
                                  setShipmentMailSubject(item.mailSubject);
                                  setProcessedShipmentContent(item.mailContent);
                                  setShipmentTempAttachments(item.attachments);
                                  setEditingIndex(index);
                                  setIsEditingShipment(true);
                                  setEditingShipmentVariables({
                                    spo: item.spoId || item.spo,
                                    primaryContactName: item.primaryContactName,
                                    primaryEmail: item.primaryEmail,
                                    primaryPhone: item.primaryPhone,
                                    itemName: item.itemName,
                                    date: item.deliveryDate,
                                    trackingID: item.trackingId,
                                    trackingPartner: item.trackingPartner,
                                    deliveryQty: item.deliveryQty,
                                    agency: item.agency,
                                    deliveryAddress: item.deliveryAddress,
                                    deliveryStatus: item.deliveryStatus,
                                    deliveryPartner:
                                      item.deliveryPartner ||
                                      item.trackingPartner,
                                  });
                                }}
                              >
                                Edit
                              </p>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  {isEditingShipment && (
                    <>
                      <div className="grid grid-cols-12 gap-4">
                        <div className="col-span-12">
                          <label
                            htmlFor="shipment_subject"
                            className="col-span-1 mb-2 font-medium text-gray-900 dark:text-white items-center inline-flex"
                          >
                            Choose Template
                          </label>
                          <select
                            id="shipment_subject"
                            className="col-span-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Write the Email Subject Over Here"
                            onChange={(e) => {
                              handleShipmentTemplateSelection(e.target.value);
                            }}
                          >
                            <option value="">Select Template</option>
                            {emailTemplates?.map((template) => {
                              if (template.mailType === 'del_sh') {
                                return (
                                  <option
                                    key={template.emailTempId}
                                    value={template.emailTempId}
                                  >
                                    {template.title}
                                  </option>
                                );
                              }
                            })}
                          </select>
                        </div>
                        <label
                          htmlFor="shipment_to"
                          className="col-span-1 mb-2 font-medium text-gray-900 dark:text-white items-center inline-flex"
                        >
                          To
                        </label>
                        <div id="shipment_to" className="col-span-11 mb-4">
                          <ReactMultiEmail
                            placeholder="Input your email"
                            emails={shipmentEmails}
                            onChange={(_emails) => {
                              setShipmentEmails(_emails);
                            }}
                            onFocus={() => setShipmentFocused(true)}
                            onBlur={() => setShipmentFocused(false)}
                            getLabel={(email, index, removeEmail) => {
                              return (
                                <div
                                  data-tag
                                  key={index}
                                  data-tag-item
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    padding: '5px',
                                    border: '1px solid black',
                                    borderRadius: '3px',
                                  }}
                                >
                                  <span>{email}</span>
                                  <span
                                    data-tag-handle
                                    onClick={() => removeEmail(index)}
                                  >
                                    ×
                                  </span>
                                </div>
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div className="grid grid-cols-12 gap-6 mb-6">
                        <label
                          htmlFor="shipment_subject"
                          className="col-span-1 mb-2 font-medium text-gray-900 dark:text-white items-center inline-flex"
                        >
                          Subject
                        </label>
                        <input
                          type="text"
                          id="shipment_subject"
                          value={shipmentMailSubject}
                          className="col-span-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder="Write the Email Subject Over Here"
                          required
                          onChange={(e) => {
                            setShipmentMailSubject(e.target.value);
                          }}
                        />
                      </div>
                      <div className="w-full">
                        <Editor
                          apiKey={process.env.REACT_APP_TINYMCE_EDITOR}
                          init={{
                            plugins:
                              ' anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                            toolbar:
                              'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                            ai_request: (request, respondWith) =>
                              respondWith.string(() =>
                                Promise.reject(
                                  'See docs to implement AI Assistant'
                                )
                              ),
                          }}
                          value={processedShipmentContent}
                          onEditorChange={(content, editor) => {
                            setProcessedShipmentContent(content);
                          }}
                        />
                      </div>
                      <div className="w-full mt-4">
                        <section className="bg-white dark:bg-gray-900">
                          <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                  <th scope="col" className="px-6 py-3">
                                    Attachment Name
                                  </th>
                                  <th scope="col" className="px-6 py-3">
                                    Attachment
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-center"
                                  >
                                    <div className="flex">
                                      <input
                                        type="file"
                                        id="file-upload-shipment"
                                        style={{ display: 'none' }}
                                        onChange={handleShipmentUpload}
                                      />
                                      <button
                                        id="states-button"
                                        data-dropdown-toggle="dropdown-states"
                                        className="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-500 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600"
                                        type="button"
                                        onClick={() =>
                                          document
                                            .getElementById(
                                              'file-upload-shipment'
                                            )
                                            .click()
                                        }
                                      >
                                        Upload
                                      </button>
                                      <label
                                        htmlFor="states"
                                        className="sr-only"
                                      >
                                        Choose a Existing File
                                      </label>
                                      <select
                                        onChange={
                                          handleShipmentAttachmentChange
                                        }
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-e-lg border-s-gray-100 dark:border-s-gray-700 border-s-2 focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                      >
                                        {shipmentTempAttachments.map(
                                          (attachment, index) => {
                                            const label =
                                              attachment.fileName ||
                                              attachment.name ||
                                              `File-${Math.floor(
                                                100 + Math.random() * 900
                                              )}`;

                                            return (
                                              <option key={index} value={label}>
                                                {label}
                                              </option>
                                            );
                                          }
                                        )}
                                      </select>
                                    </div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {shipmentAttachmentsList.map((row, index) => (
                                  <tr key={row.id}>
                                    <td>
                                      <input
                                        type="text"
                                        placeholder="Attachment Name"
                                        value={row.name}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 mx-4 my-2"
                                      />
                                    </td>
                                    <td>
                                      <a
                                        href={row.url}
                                        target="_blank"
                                        className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 mx-4 my-2"
                                      >
                                        {row.url}
                                      </a>
                                    </td>
                                    <td className="text-center">
                                      <button
                                        onClick={() => {
                                          if (
                                            window.confirm(
                                              'Are you sure you want to delete this row?'
                                            )
                                          ) {
                                            handleShipmentDeleteAttachmentRow(
                                              index
                                            );
                                          }
                                        }}
                                        className="text-red-500 hover:text-red-700 text-center"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          strokeWidth={1.5}
                                          stroke="currentColor"
                                          className="w-6 h-6 mx-auto"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                          />
                                        </svg>
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </section>
                      </div>
                      <div className="w-full text-right">
                        <button
                          type="button"
                          onClick={() => {
                            let updatedFilteredDeliveryDetails = [
                              ...filteredDeliveryDetails,
                            ];
                            updatedFilteredDeliveryDetails[editingIndex] = {
                              ...updatedFilteredDeliveryDetails[editingIndex],
                              emails: shipmentEmails,
                              mailSubject: shipmentMailSubject,
                              mailContent: processedShipmentContent,
                              sendAttachments: shipmentAttachmentsList,
                            };
                            setFilteredDeliveryDetails(
                              updatedFilteredDeliveryDetails
                            );

                            setEditingIndex(null);
                            setIsEditingShipment(false);
                            setShipmentAttachmentsList([]);
                            setShipmentTempAttachments([]);
                          }}
                          className="mt-3 inline-flex text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        >
                          Save
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </section>
            </div>
            <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
              <button
                type="button"
                onClick={sendShipmentEmailFunction}
                className="inline-flex text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                <PaperAirplaneIcon className="flex-shrink-0 w-5 h-5 mr-3 -ml-1" />
                Send Emails
              </button>
              <button
                onClick={() => setopenShipmentModal(false)}
                type="button"
                className="ms-3 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Invoice Generate Modal */}
      <GenerateInvoiceModal
        openGenerateInvoicModal={openGenerateInvoicModal}
        setOpenGenerateInvoicModal={setOpenGenerateInvoicModal}
        docId={invoiceSelectedId}
      />
    </>
  );
};

export default DeliveryTable;
