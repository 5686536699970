function numericSort(a, b) {
  return a - b;
}

const posFilterValue = [
  {
    name: 'no',
    operator: 'gte',
    type: 'number',
    value: '',
    sort: numericSort,
  },
  { name: 'from', operator: 'contains', type: 'string', value: '' },
  { name: 'dateTime', operator: 'contains', type: 'string', value: '' },
  { name: 'attachments', operator: 'gte', type: 'number', value: '' },
  { name: 'subject', operator: 'contains', type: 'string', value: '' },
  {
    name: 'classify',
    operator: 'contains',
    type: 'string',
    value: '',
  },
];
export default posFilterValue;
