import { app } from '../resources/gcp-config';
import {
  collection,
  doc,
  getDoc,
  getFirestore,
  onSnapshot,
} from 'firebase/firestore';

const db = getFirestore(app);

export const fetchAllEmailTemplates = (callback) => {
  const q = collection(db, 'email-templates');
  return onSnapshot(q, (querySnapshot) => {
    const templates = querySnapshot.docs.map((doc) => {
      return { ...doc.data(), docId: doc.id };
    });
    callback(templates);
  });
};
