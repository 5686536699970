import React from 'react';

const FloatingButton = ({ onClick, text }) => (
  <button
    className="fixed right-4 bottom-4 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg px-5 py-2.5 me-2 mb-2 focus:outline-none text-sm inline-flex items-center"
    onClick={onClick}
  >
    <svg
      className="flex-shrink-0 w-5 h-5 mr-3 -ml-1"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M2.63452 7.50001L13.6345 7.5M8.13452 13V2"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
    {text}
  </button>
);

export default FloatingButton;