import { initializeApp } from 'firebase/app';

const firebaseConfigDev = {
  apiKey: 'AIzaSyDSkXlEbA0HeIYold_4EcyQ1Zuiw0oE_VU',
  authDomain: 'yoda-technologies-kb.firebaseapp.com',
  projectId: 'yoda-technologies-kb',
  storageBucket: 'yoda-technologies-kb.appspot.com',
  messagingSenderId: '389973887645',
  appId: '1:389973887645:web:bd30661e41fa4d839ea274',
};

const firebaseConfigProd = {
  apiKey: 'AIzaSyCpR1whTczt3i6YFAH0NTB-7ZUldF1QqVQ',
  authDomain: 'serving-again.firebaseapp.com',
  projectId: 'serving-again',
  storageBucket: 'serving-again.appspot.com',
  messagingSenderId: '228864301254',
  appId: '1:228864301254:web:be07294d312620d2171fc7',
};

export const app = initializeApp(firebaseConfigProd);
