import { useEffect, useRef, useState } from 'react';
import { createRFQ } from '../../database/set-rfq-data.js';
import Loader from '../loader/loader.js';
import { warnAlert } from '../alerts.js';
import { v4 as uuidv4 } from 'uuid';
import { storeFileRFQ } from '../../storage/store-files-rfq.js';
import { categories } from '../../resources/categories.js';
import { fetchAllCOData } from '../../database/fetch-co-data.js';
import * as XLSX from 'xlsx';

function AddNewRfqComponent({ openModal, setOpenModal }) {
  const [isLoading, setIsLoading] = useState(false);
  const [rfqNo, setRfqNo] = useState('');
  const [agency, setAgency] = useState('');
  const [releaseDate, setReleaseDate] = useState(new Date());
  const [dueDate, setDueDate] = useState(new Date());
  const [address, setAddress] = useState('');
  const [primaryContactName, setPrimaryContactName] = useState('');
  const [secondaryContactName, setSecondaryContactName] = useState('');
  const [primaryPhone, setPrimaryPhone] = useState('');
  const [secondaryPhone, setSecondaryPhone] = useState('');
  const [primaryEmail, setPrimaryEmail] = useState('');
  const [secondaryEmail, setSecondaryEmail] = useState('');
  const [isPrimaryEmailValid, setIsPrimaryEmailValid] = useState(true);
  const [isSecondaryEmailValid, setIsSecondaryEmailValid] = useState(true);
  const [comments, setComments] = useState('');
  const [coData, setCoData] = useState([]);

  const [currentStep, setCurrentStep] = useState(1);

  const [rows, setRows] = useState([{ id: 1 }]);
  const [attachmentRows, setAttachmentRows] = useState([]);
  const fileInputRef = useRef(null);

  const handleAttachmentNameChange = (event, index) => {
    const newAttachments = [...attachmentRows];
    newAttachments[index].name = event.target.value;
    setAttachmentRows(newAttachments);
  };

  useEffect(() => {
    const unsubscribe = fetchAllCOData((COs) => {
      // // console.log(COs);
      setCoData(COs);
    });
    return () => unsubscribe();
  }, []);

  const [selectedContact, setSelectedContact] = useState(null);
  // Function to handle primary contact selection
  const handlePrimaryContactSelection = (event) => {
    const selectedName = event.target.value;
    const contact = coData.find((contact) => contact.name === selectedName);

    if (contact) {
      setSelectedContact(contact);
    } else {
      // Set the selected contact with only the name property if not found
      setSelectedContact({ name: selectedName });
      setPrimaryContactName(selectedName);
    }
  };

  const [selectedSecondaryContact, setSelectedSecondaryContact] =
    useState(null);
  const handleSecondaryContactSelection = (event) => {
    const selectedName = event.target.value;
    const contact = coData.find((contact) => contact.name === selectedName);
    setSelectedSecondaryContact(contact);
  };

  useEffect(() => {
    if (selectedContact) {
      setPrimaryContactName(selectedContact.name || '');
      setPrimaryEmail(selectedContact.emailAddress || '');
      setPrimaryPhone(selectedContact.businessPhone || '');
    }
  }, [selectedContact]);

  useEffect(() => {
    if (selectedSecondaryContact) {
      setSecondaryContactName(selectedSecondaryContact.name || '');
      setSecondaryEmail(selectedSecondaryContact.emailAddress || '');
      setSecondaryPhone(selectedSecondaryContact.businessPhone || '');
    }
  }, [selectedSecondaryContact]);

  const handleAttachmentFileChange = (event, index) => {
    const newAttachments = [...attachmentRows];
    const file = event.target.files[0];
    newAttachments[index].file = file;
    newAttachments[index].name = file.name;
    setAttachmentRows(newAttachments);
  };

  const handleRowInputChange = (event, index, field) => {
    const newRows = [...rows];
    newRows[index][field] = event.target.value;
    setRows(newRows);
  };

  const addRow = () => {
    // const id = rows.length ? rows[rows.length - 1].id + 1 : 1;
    setRows((prevRows) => [
      ...prevRows,
      {
        id: uuidv4(),
        clin: '',
        itemName: '',
        category: '',
        qty: '',
        unit: 'EA',
        description: '',
        deliveryAddress: '',
        dueBy: '',
      },
    ]);
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      try {
        const fileData = await readFile(file);
        const parsedRows = parseExcelData(fileData);

        setRows((prevRows) => [...prevRows, ...parsedRows]);

        // Clear the file input
        if (fileInputRef.current) {
          fileInputRef.current.value = null;
        }
      } catch (error) {
        console.error('Error reading or parsing the file', error);
      }
    }
  };

  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        resolve(e.target.result);
      };

      reader.onerror = (e) => {
        reject(new Error('Error reading the file.'));
      };

      reader.readAsBinaryString(file);
    });
  };

  const parseExcelData = (fileData) => {
    const workbook = XLSX.read(fileData, { type: 'binary' });
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];

    // Assuming the Excel sheet has columns: clin, itemName, category, qty, description, deliveryAddress, dueBy
    const parsedRows = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

    // Remove the header row
    parsedRows.shift();

    return parsedRows.map((row) => ({
      id: uuidv4(),
      clin: row[0] || '',
      itemName: row[1] || '',
      category: row[2] || '',
      qty: row[3] || '',
      description: row[4] || '',
      deliveryAddress: row[5] || '',
      dueBy: row[6] || '',
    }));
  };

  const addAttachmentRow = () => {
    const id = attachmentRows.length
      ? attachmentRows[attachmentRows.length - 1].id + 1
      : 1;
    setAttachmentRows([...attachmentRows, { id }]);
  };

  const handleDeleteRow = (index) => {
    const newRows = [...rows];
    newRows.splice(index, 1);
    setRows(newRows);
  };

  const handleDeleteAttachmentRow = (index) => {
    const newAttachmentRows = [...attachmentRows];
    newAttachmentRows.splice(index, 1);
    setAttachmentRows(newAttachmentRows);
  };

  /**
   * The `handlePhoneNumberChange` function formats a phone number input by adding parentheses, spaces,
   * and dashes in the appropriate places.
   * @param event - The event parameter is an object that represents the event that triggered the
   * function. In this case, it is likely an event object related to a change event on an input field.
   */
  const handlePhoneNumberChange = (event, setPhone) => {
    let input = event.target.value.replace(/\D/g, '');

    if (input.length > 10) {
      input = input.substring(0, 10);
    }

    let size = input.length;

    if (size < 4) {
      input = '(' + input;
    } else if (size < 7) {
      input = '(' + input.substring(0, 3) + ') ' + input.substring(3, 6);
    } else {
      input =
        '(' +
        input.substring(0, 3) +
        ') ' +
        input.substring(3, 6) +
        '-' +
        input.substring(6, 10);
    }

    event.target.value = input;
    setPhone(input);
  };

  /**
   * The below code defines two functions, handlePrimaryEmailChange and handleSecondaryEmailChange, which
   * handle changes to the primary and secondary email inputs respectively and validate the email format
   * using a regular expression.
   * @param event - The `event` parameter is an object that represents the event that occurred. In this
   * case, it is an event object related to the email input field. It contains information about the
   * event, such as the target element (the input field), the value of the input field, and other
   * properties and methods
   */
  const handlePrimaryEmailChange = (event) => {
    setPrimaryEmail(event.target.value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsPrimaryEmailValid(emailRegex.test(event.target.value));
  };

  const handleSecondaryEmailChange = (event) => {
    setSecondaryEmail(event.target.value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsSecondaryEmailValid(emailRegex.test(event.target.value));
  };

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  /**
   * The function `validatePageOne` checks if all required fields on a page are filled and returns the
   * name of the first unfilled field, or null if all fields are filled.
   * @returns The function `validatePageOne` returns the name of the first unfilled field from the
   * `requiredFields` array. If all fields are filled, it returns `null`.
   */
  const validatePageOne = () => {
    // List all required fields with their names
    const requiredFields = [
      { name: 'RFQ No', value: rfqNo },
      { name: 'Agency', value: agency },
      { name: 'Release Date', value: releaseDate },
      { name: 'Due Date', value: dueDate },
      { name: 'Address', value: address },
      { name: 'Primary Contact Name', value: primaryContactName },
      { name: 'Primary Phone', value: primaryPhone },
      { name: 'Primary Email', value: primaryEmail },
    ];

    // Find the first field that is not filled
    const unfilledField = requiredFields.find(
      (field) => field.value === null || field.value === ''
    );

    if (unfilledField) {
      return unfilledField.name;
    }

    // If all validations pass, return null
    return null;
  };

  return (
    <>
      {isLoading && <Loader />}

      <div
        id="addUserData-modal"
        tabIndex="-1"
        aria-hidden="true"
        className={
          openModal
            ? 'flex items-center justify-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full h-[calc(100%)] max-h-full bg-black bg-opacity-50'
            : 'hidden'
        }
      >
        <div className="relative p-4 w-full max-w-7xl max-h-full">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                Add New RFQ
              </h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={() => setOpenModal(false)}
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <div className="p-4 md:p-5 space-y-4 overflow-y-auto max-h-[calc(100vh-15rem)]">
              <ol className="flex items-center w-full p-3 space-x-2 text-sm font-medium text-center text-gray-500 bg-white border border-gray-200 rounded-lg shadow-sm dark:text-gray-400 sm:text-base dark:bg-gray-800 dark:border-gray-700 sm:p-4 sm:space-x-4 rtl:space-x-reverse">
                <li
                  className={`flex items-center ${
                    currentStep > 1
                      ? 'text-green-600 dark:text-green-500'
                      : currentStep === 1
                        ? 'text-blue-600 dark:text-blue-500'
                        : ''
                  }`}
                >
                  <span
                    className={`flex items-center justify-center w-5 h-5 me-2 text-lg rounded-full shrink-0 ${
                      currentStep > 1
                        ? 'border-green-600 dark:border-green-500'
                        : currentStep === 1
                          ? 'border-blue-600 dark:border-blue-500'
                          : 'border-gray-500 dark:border-gray-400'
                    }`}
                  >
                    1
                  </span>
                  <span className="hidden sm:inline-flex sm:ms-2 text-lg">
                    Add RFQ Details
                  </span>
                  {currentStep < 2 && (
                    <svg
                      className="w-3 h-3 ms-2 sm:ms-4 rtl:rotate-180"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 12 10"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m7 9 4-4-4-4M1 9l4-4-4-4"
                      />
                    </svg>
                  )}
                </li>
                <li
                  className={`flex items-center ${
                    currentStep > 2
                      ? 'text-green-600 dark:text-green-500'
                      : currentStep === 2
                        ? 'text-blue-600 dark:text-blue-500'
                        : ''
                  }`}
                >
                  <span
                    className={`flex items-center justify-center w-5 h-5 me-2 text-lg rounded-full shrink-0 ${
                      currentStep > 2
                        ? 'border-green-600 dark:border-green-500'
                        : currentStep === 2
                          ? 'border-blue-600 dark:border-blue-500'
                          : 'border-gray-500 dark:border-gray-400'
                    }`}
                  >
                    2
                  </span>
                  <span className="hidden sm:inline-flex sm:ms-2">
                    <span className="text-lg">Add Line Items</span>
                  </span>
                  {currentStep < 3 && (
                    <svg
                      className="w-3 h-3 ms-2 sm:ms-4 rtl:rotate-180"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 12 10"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m7 9 4-4-4-4M1 9l4-4-4-4"
                      />
                    </svg>
                  )}
                </li>
                <li
                  className={`flex items-center ${
                    currentStep > 3
                      ? 'text-green-600 dark:text-green-500'
                      : currentStep === 3
                        ? 'text-blue-600 dark:text-blue-500'
                        : ''
                  }`}
                >
                  <span
                    className={`flex items-center justify-center w-5 h-5 me-2 text-lg rounded-full shrink-0 ${
                      currentStep > 3
                        ? 'border-green-600 dark:border-green-500'
                        : currentStep === 3
                          ? 'border-blue-600 dark:border-blue-500'
                          : 'border-gray-500 dark:border-gray-400'
                    }`}
                  >
                    3
                  </span>
                  <span className="text-lg">Add Attachments</span>
                  {currentStep < 4 && (
                    <svg
                      className="w-3 h-3 ms-2 sm:ms-4 rtl:rotate-180"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 12 10"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m7 9 4-4-4-4M1 9l4-4-4-4"
                      />
                    </svg>
                  )}
                </li>
                <li
                  className={`flex items-center ${
                    currentStep > 4
                      ? 'text-green-600 dark:text-green-500'
                      : currentStep === 4
                        ? 'text-blue-600 dark:text-blue-500'
                        : ''
                  }`}
                >
                  <span
                    className={`flex items-center justify-center w-5 h-5 me-2 text-lg rounded-full shrink-0 ${
                      currentStep > 4
                        ? 'border-green-600 dark:border-green-500'
                        : currentStep === 4
                          ? 'border-blue-600 dark:border-blue-500'
                          : 'border-gray-500 dark:border-gray-400'
                    }`}
                  >
                    4
                  </span>
                  <span className="text-lg">Check and Confirm</span>
                </li>
              </ol>

              {currentStep === 1 && (
                <section className="bg-white dark:bg-gray-900">
                  <div className="mx-auto max-w-7xl">
                    <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
                      <div>
                        <label
                          htmlFor="rfqNo"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          RFQ No <span className="text-red-600">*</span>
                        </label>
                        <input
                          type="text"
                          name="rfqNo"
                          id="rfqNo"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          placeholder="1223211"
                          required
                          onChange={(event) => setRfqNo(event.target.value)}
                          defaultValue={rfqNo}
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="agency"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Agency <span className="text-red-600">*</span>
                        </label>
                        <input
                          type="text"
                          name="agency"
                          id="agency"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          placeholder="Ministry of Defence"
                          required
                          onChange={(event) => setAgency(event.target.value)}
                          defaultValue={agency}
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="releaseDate"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Release Date <span className="text-red-600">*</span>
                        </label>
                        <input
                          type="date"
                          name="releaseDate"
                          id="releaseDate"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          required
                          onChange={(event) =>
                            setReleaseDate(event.target.value)
                          }
                          defaultValue={releaseDate}
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="dueDate"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Due Date <span className="text-red-600">*</span>
                        </label>
                        <input
                          type="date"
                          name="dueDate"
                          id="dueDate"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          required
                          onChange={(event) => setDueDate(event.target.value)}
                          defaultValue={dueDate}
                        />
                      </div>
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="address"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Address <span className="text-red-600">*</span>
                        </label>
                        <textarea
                          name="address"
                          id="address"
                          rows="2"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          required
                          onChange={(event) => setAddress(event.target.value)}
                          defaultValue={address}
                          placeholder="Add the Delivery Address of Contracting Officer"
                        />
                      </div>
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="primaryContact"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Primary Contact{' '}
                          <span className="text-red-600">*</span>
                        </label>
                        <input
                          list="primaryContacts"
                          type="text"
                          name="primaryContact"
                          id="primaryContact"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          placeholder="John Doe"
                          required
                          onChange={handlePrimaryContactSelection}
                        />
                        <datalist id="primaryContacts">
                          {coData.map((contact, index) => (
                            <option key={index} value={contact.name} />
                          ))}
                        </datalist>
                      </div>
                      <div>
                        <label
                          htmlFor="primary_phone"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Primary Phone <span className="text-red-600">*</span>
                        </label>
                        <div className="relative">
                          <div className="absolute inset-y-0 start-0 top-0 flex items-center ps-3.5 pointer-events-none">
                            <svg
                              className="w-4 h-4 text-gray-500 dark:text-gray-400"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 19 18"
                            >
                              <path d="M18 13.446a3.02 3.02 0 0 0-.946-1.985l-1.4-1.4a3.054 3.054 0 0 0-4.218 0l-.7.7a.983.983 0 0 1-1.39 0l-2.1-2.1a.983.983 0 0 1 0-1.389l.7-.7a2.98 2.98 0 0 0 0-4.217l-1.4-1.4a2.824 2.824 0 0 0-4.218 0c-3.619 3.619-3 8.229 1.752 12.979C6.785 16.639 9.45 18 11.912 18a7.175 7.175 0 0 0 5.139-2.325A2.9 2.9 0 0 0 18 13.446Z" />
                            </svg>
                          </div>
                          <input
                            type="text"
                            id="primary_phone"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            pattern="((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}"
                            placeholder="(123) 456-7890"
                            required
                            value={
                              selectedContact
                                ? selectedContact.businessPhone
                                : ''
                            }
                            onChange={(e) =>
                              setSelectedContact((prev) => ({
                                ...prev,
                                businessPhone: e.target.value,
                              }))
                            }
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="primary_email"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Primary Email <span className="text-red-600">*</span>
                        </label>
                        <input
                          type="text"
                          name="primary_email"
                          id="primary_email"
                          defaultValue={primaryEmail}
                          className={`bg-gray-50 border ${
                            isPrimaryEmailValid
                              ? 'border-gray-300'
                              : 'border-red-600'
                          } text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500`}
                          placeholder="user@domain.com"
                          required
                          value={
                            selectedContact ? selectedContact.emailAddress : ''
                          }
                          onChange={(e) =>
                            setSelectedContact((prev) => ({
                              ...prev,
                              emailAddress: e.target.value,
                            }))
                          }
                        />
                      </div>
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="secondaryContact"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Secondary Contact
                        </label>
                        <input
                          list="secondaryContacts"
                          type="text"
                          name="secondaryContact"
                          id="secondaryContact"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          placeholder="Jane Doe"
                          onChange={handleSecondaryContactSelection}
                        />
                        <datalist id="secondaryContacts">
                          {coData.map((contact, index) => (
                            <option key={index} value={contact.name} />
                          ))}
                        </datalist>
                      </div>
                      <div>
                        <label
                          htmlFor="secondary_phone"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Secondary Phone
                        </label>
                        <div className="relative">
                          <div className="absolute inset-y-0 start-0 top-0 flex items-center ps-3.5 pointer-events-none">
                            <svg
                              className="w-4 h-4 text-gray-500 dark:text-gray-400"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 19 18"
                            >
                              <path d="M18 13.446a3.02 3.02 0 0 0-.946-1.985l-1.4-1.4a3.054 3.054 0 0 0-4.218 0l-.7.7a.983.983 0 0 1-1.39 0l-2.1-2.1a.983.983 0 0 1 0-1.389l.7-.7a2.98 2.98 0 0 0 0-4.217l-1.4-1.4a2.824 2.824 0 0 0-4.218 0c-3.619 3.619-3 8.229 1.752 12.979C6.785 16.639 9.45 18 11.912 18a7.175 7.175 0 0 0 5.139-2.325A2.9 2.9 0 0 0 18 13.446Z" />
                            </svg>
                          </div>
                          <input
                            type="text"
                            id="secondary_phone"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            pattern="((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}"
                            placeholder="(123) 456-7890"
                            value={
                              selectedSecondaryContact
                                ? selectedSecondaryContact.businessPhone
                                : ''
                            }
                            onChange={(e) =>
                              setSelectedSecondaryContact((prev) => ({
                                ...prev,
                                businessPhone: e.target.value,
                              }))
                            }
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="secondary_email"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Secondary Email
                        </label>
                        <input
                          type="text"
                          name="secondary_email"
                          id="secondary_email"
                          className={`bg-gray-50 border ${
                            isSecondaryEmailValid
                              ? 'border-gray-300'
                              : 'border-red-600'
                          } text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500`}
                          placeholder="user@domain.com"
                          value={
                            selectedSecondaryContact
                              ? selectedSecondaryContact.emailAddress
                              : ''
                          }
                          onChange={(e) =>
                            setSelectedSecondaryContact((prev) => ({
                              ...prev,
                              emailAddress: e.target.value,
                            }))
                          }
                        />
                      </div>
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="comments"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Comments
                        </label>
                        <textarea
                          name="comments"
                          id="comments"
                          rows="2"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          onChange={(event) => setComments(event.target.value)}
                          defaultValue={comments}
                          placeholder="Please enter any additional comments here"
                        />
                      </div>
                    </div>
                  </div>
                </section>
              )}

              {currentStep === 2 && (
                <section className="bg-white dark:bg-gray-900">
                  <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                      <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                          <th scope="col" className="px-6 py-3">
                            CLIN
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Item Name
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Category
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Qty
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Unit
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Description
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Delivery Address
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Due By
                          </th>
                          <th scope="col" className="px-6 py-3">
                            <button
                              onClick={addRow}
                              type="button"
                              className="p-1 rounded-lg bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                className="h-6 w-6"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                                />
                              </svg>
                            </button>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {rows.map((row, index) => (
                          <tr key={row.id}>
                            <td>
                              <input
                                type="text"
                                placeholder="CLIN"
                                value={row.clin}
                                onChange={(event) =>
                                  handleRowInputChange(event, index, 'clin')
                                }
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 mx-4 my-2"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                placeholder="Item Name"
                                value={row.itemName}
                                onChange={(event) =>
                                  handleRowInputChange(event, index, 'itemName')
                                }
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 mx-4 my-2"
                              />
                            </td>
                            <td>
                              <select
                                className="w-32 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 mx-4 my-2"
                                value={row.category}
                                onChange={(event) =>
                                  handleRowInputChange(event, index, 'category')
                                }
                              >
                                {categories.map((category) => (
                                  <option
                                    key={category.categoryname}
                                    value={category.categoryname}
                                  >
                                    {category.categoryname}
                                  </option>
                                ))}
                              </select>
                            </td>
                            <td>
                              <input
                                type="number"
                                placeholder="Qty"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 mx-4 my-2"
                                value={row.qty}
                                onChange={(event) =>
                                  handleRowInputChange(event, index, 'qty')
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                placeholder="Unit"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 mx-4 my-2"
                                value={row.unit}
                                onChange={(event) =>
                                  handleRowInputChange(event, index, 'unit')
                                }
                              />
                            </td>
                            <td>
                              <textarea
                                placeholder="Description"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 mx-4 my-2"
                                value={row.description}
                                onChange={(event) =>
                                  handleRowInputChange(
                                    event,
                                    index,
                                    'description'
                                  )
                                }
                              />
                            </td>
                            <td>
                              <textarea
                                placeholder="Delivery Address"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 mx-4 my-2"
                                value={row.deliveryAddress}
                                onChange={(event) =>
                                  handleRowInputChange(
                                    event,
                                    index,
                                    'deliveryAddress'
                                  )
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="date"
                                placeholder="Due By"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 mx-4 my-2"
                                value={row.dueBy}
                                onChange={(event) =>
                                  handleRowInputChange(event, index, 'dueBy')
                                }
                              />
                            </td>
                            <td className="text-center">
                              <button
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      'Are you sure you want to delete this row?'
                                    )
                                  ) {
                                    handleDeleteRow(index);
                                  }
                                }}
                                className="text-red-500 hover:text-red-700 text-center"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-6 h-6 mx-auto"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                  />
                                </svg>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </section>
              )}

              {currentStep === 3 && (
                <section className="bg-white dark:bg-gray-900">
                  <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                      <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                          <th scope="col" className="px-6 py-3">
                            #
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Attachment Name
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Attachment
                          </th>
                          <th scope="col" className="px-6 py-3 text-center">
                            <button
                              onClick={addAttachmentRow}
                              type="button"
                              className="p-1 rounded-lg bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mx-auto"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                className="h-6 w-6 mx-auto"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                                />
                              </svg>
                            </button>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {attachmentRows.map((row, index) => (
                          <tr key={row.id}>
                            <td className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 mx-4 my-2">
                              {index + 1}
                            </td>
                            <td>
                              <input
                                type="text"
                                placeholder="Attachment Name"
                                value={row.name}
                                onChange={(event) =>
                                  handleAttachmentNameChange(event, index)
                                }
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 mx-4 my-2"
                              />
                            </td>
                            <td>
                              <input
                                type="file"
                                placeholder="Attachment"
                                onChange={(event) =>
                                  handleAttachmentFileChange(event, index)
                                }
                                className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 mx-4 my-2"
                              />
                            </td>
                            <td className="text-center">
                              <button
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      'Are you sure you want to delete this row?'
                                    )
                                  ) {
                                    handleDeleteAttachmentRow(index);
                                  }
                                }}
                                className="text-red-500 hover:text-red-700 text-center"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-6 h-6 mx-auto"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                  />
                                </svg>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </section>
              )}

              {currentStep === 4 && (
                <>
                  <section className="bg-white dark:bg-gray-900">
                    <h5 className="text-lg font-semibold text-gray-900 dark:text-white mb-3 mt-6">
                      RFQ Details
                    </h5>

                    <div className="block w-full p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                      <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
                        <div>
                          <label
                            htmlFor="rfqNo"
                            className="block mb-2 font-semibold text-gray-900 dark:text-white"
                          >
                            RFQ No <span className="text-red-600">*</span>
                          </label>
                          <p className="">{rfqNo ? rfqNo : '--'}</p>{' '}
                        </div>
                        <div>
                          <label
                            htmlFor="agency"
                            className="block mb-2 font-semibold text-gray-900 dark:text-white"
                          >
                            Agency <span className="text-red-600">*</span>
                          </label>
                          <p className="">{agency ? agency : '--'}</p>
                        </div>
                        <div>
                          <label
                            htmlFor="releaseDate"
                            className="block mb-2 font-semibold text-gray-900 dark:text-white"
                          >
                            Release Date <span className="text-red-600">*</span>
                          </label>
                          <p className="">{releaseDate ? releaseDate : '--'}</p>
                        </div>
                        <div>
                          <label
                            htmlFor="dueDate"
                            className="block mb-2 font-semibold text-gray-900 dark:text-white"
                          >
                            Due Date <span className="text-red-600">*</span>
                          </label>
                          <p className="">{dueDate ? dueDate : '--'}</p>
                        </div>
                        <div className="sm:col-span-2">
                          <label
                            htmlFor="address"
                            className="block mb-2 font-semibold text-gray-900 dark:text-white"
                          >
                            Address <span className="text-red-600">*</span>
                          </label>
                          <p className="">{address ? address : '--'}</p>
                        </div>
                        <div className="sm:col-span-2">
                          <label
                            htmlFor="primaryContact"
                            className="block mb-2 font-semibold text-gray-900 dark:text-white"
                          >
                            Primary Contact{' '}
                            <span className="text-red-600">*</span>
                          </label>
                          <p className="">
                            {primaryContactName ? primaryContactName : '--'}
                          </p>
                        </div>
                        <div>
                          <label
                            htmlFor="primary_phone"
                            className="block mb-2 font-semibold text-gray-900 dark:text-white"
                          >
                            Primary Phone{' '}
                            <span className="text-red-600">*</span>
                          </label>
                          <p className="">
                            {primaryPhone ? primaryPhone : '--'}
                          </p>
                        </div>
                        <div>
                          <label
                            htmlFor="primary_email"
                            className="block mb-2 font-semibold text-gray-900 dark:text-white"
                          >
                            Primary Email{' '}
                            <span className="text-red-600">*</span>
                          </label>
                          <p className="">
                            {primaryEmail ? primaryEmail : '--'}
                          </p>
                        </div>
                        <div className="sm:col-span-2">
                          <label
                            htmlFor="secondaryContact"
                            className="block mb-2 font-semibold text-gray-900 dark:text-white"
                          >
                            Secondary Contact
                          </label>
                          <p className="">
                            {secondaryContactName ? secondaryContactName : '--'}
                          </p>
                        </div>
                        <div>
                          <label
                            htmlFor="secondary_phone"
                            className="block mb-2 font-semibold text-gray-900 dark:text-white"
                          >
                            Secondary Phone
                          </label>
                          <p className="">
                            {secondaryPhone ? secondaryPhone : '--'}
                          </p>
                        </div>
                        <div>
                          <label
                            htmlFor="secondary_email"
                            className="block mb-2 font-semibold text-gray-900 dark:text-white"
                          >
                            Secondary Email
                          </label>
                          <p className="">
                            {secondaryEmail ? secondaryEmail : '--'}
                          </p>
                        </div>
                        <div className="sm:col-span-2">
                          <label
                            htmlFor="comments"
                            className="block mb-2 font-semibold text-gray-900 dark:text-white"
                          >
                            Comments
                          </label>
                          <p className="">{comments ? comments : '--'}</p>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="bg-white dark:bg-gray-900">
                    <h5 className="text-lg font-semibold text-gray-900 dark:text-white mb-3 mt-6">
                      Line Items
                    </h5>

                    <div className="block w-full p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                      <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                          <tr>
                            <th scope="col" className="px-6 py-3">
                              CLIN
                            </th>
                            <th scope="col" className="px-6 py-3">
                              Item Name
                            </th>
                            <th scope="col" className="px-6 py-3">
                              Category
                            </th>
                            <th scope="col" className="px-6 py-3">
                              Qty
                            </th>
                            <th scope="col" className="px-6 py-3">
                              Description
                            </th>
                            <th scope="col" className="px-6 py-3">
                              Delivery Address
                            </th>
                            <th scope="col" className="px-6 py-3">
                              Due By
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {rows.map((row, index) => (
                            <tr key={index}>
                              <td className="px-6 py-3">{row.clin}</td>
                              <td className="px-6 py-3">{row.itemName}</td>
                              <td className="px-6 py-3">{row.category}</td>
                              <td className="px-6 py-3">{row.qty}</td>
                              <td className="px-6 py-3">{row.description}</td>
                              <td className="px-6 py-3">
                                {row.deliveryAddress}
                              </td>
                              <td className="px-6 py-3">{row.dueBy}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </section>
                  <section className="bg-white dark:bg-gray-900">
                    <h5 className="text-lg font-semibold text-gray-900 dark:text-white mb-3 mt-6">
                      Attachments
                    </h5>

                    <div className="block w-full p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                      {attachmentRows.length > 0 ? (
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                              <th scope="col" className="px-6 py-3">
                                #
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Attachment Name
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {attachmentRows.map((attachment, index) => (
                              <tr key={index}>
                                <td className="px-6 py-3">{index + 1}</td>
                                <td className="px-6 py-3">{attachment.name}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <p className="text-center text-red-500 dark:text-red-400">
                          No attachments Found
                        </p>
                      )}
                    </div>
                  </section>
                </>
              )}
            </div>
            <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
              {currentStep === 1 && (
                <button
                  onClick={() => setOpenModal(false)}
                  type="button"
                  className="ms-3 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                >
                  Cancel
                </button>
              )}

              {currentStep > 1 && (
                <button
                  onClick={prevStep}
                  type="button"
                  className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                >
                  Back
                </button>
              )}

              {currentStep === 2 && (
                <>
                  <input
                    ref={fileInputRef}
                    type="file"
                    accept=".xlsx, .xls, .csv"
                    className="hidden"
                    onChange={handleFileChange}
                  />

                  <button
                    type="button"
                    onClick={() => {
                      if (fileInputRef.current) {
                        fileInputRef.current.click();
                      }
                    }}
                    className="ml-3 text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                  >
                    Upload Excel
                  </button>
                </>
              )}

              {currentStep < 4 && (
                <button
                  onClick={() => {
                    const unfilledField = validatePageOne();
                    if (unfilledField) {
                      warnAlert(`Please fill in the ${unfilledField} field.`);
                    } else {
                      nextStep();
                    }
                  }}
                  type="button"
                  className="ms-3 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Next
                </button>
              )}

              {currentStep === 4 && (
                <button
                  type="button"
                  className="ms-3 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  onClick={async () => {
                    setOpenModal(false);
                    setIsLoading(true);
                    const itemsWithId = rows.map((item) => ({
                      ...item,
                      itemId: uuidv4(), // generate a unique id
                    }));

                    const attachmentsWithIdAndUrl = [];
                    attachmentsWithIdAndUrl.push({
                      attachmentUrl:
                        'https://firebasestorage.googleapis.com/v0/b/serving-again.appspot.com/o/Resell%20Certificate.pdf?alt=media&token=be68bcfe-b9aa-470b-9eb8-db8d921cdd0d',
                      name: 'Resell Certificate.pdf',
                    });

                    for (const attachment of attachmentRows) {
                      const { file, ...rest } = attachment; // Exclude file from the rest of the attachment properties
                      const attachmentId = uuidv4(); // generate a unique id
                      const attachmentUrl = await storeFileRFQ(
                        file,
                        attachmentId,
                        {
                          contentType: file.type,
                        }
                      );
                      attachmentsWithIdAndUrl.push({
                        ...rest,
                        attachmentId,
                        attachmentUrl,
                      });
                    }

                    const rfqData = {
                      rfqNo,
                      agency,
                      releaseDate,
                      dueDate,
                      address,
                      primaryContactName,
                      primaryPhone,
                      secondaryContactName,
                      secondaryPhone,
                      primaryEmail,
                      secondaryEmail,
                      comments,
                      assignedTo: '',
                      status: '',
                      items: itemsWithId,
                      attachments: attachmentsWithIdAndUrl,
                    };

                    await createRFQ(rfqData);
                    setRfqNo('');
                    setAgency('');
                    setReleaseDate(new Date());
                    setDueDate(new Date());
                    setAddress('');
                    setPrimaryContactName('');
                    setSecondaryContactName('');
                    setPrimaryPhone('');
                    setSecondaryPhone('');
                    setPrimaryEmail('');
                    setSecondaryEmail('');
                    setIsPrimaryEmailValid(true);
                    setIsSecondaryEmailValid(true);
                    setComments('');
                    setCurrentStep(1);
                    setRows([{ id: 1 }]);
                    setAttachmentRows([]);

                    setIsLoading(false);
                  }}
                >
                  Save RFQ Details
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddNewRfqComponent;
