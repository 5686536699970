import React, { useEffect, useState } from 'react';
import UsersTopBar from './userTopBar';
import {
  fetchAllUsersData,
  fetchUserData,
} from '../../database/fetch-user-data';
import EditUserComponent from './edituser';
import { deleteUser } from '../../database/set-user-data';
import { UserCircleIcon } from '@heroicons/react/24/outline';

const UsersTable = ({ setOpenEditModal, setSelectedId }) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [currentDocId, setCurrentDocId] = useState(null);

  /* The `useEffect` hook is used to perform side effects in a functional component. In this case, it is
used to fetch all users' data and update the state with the fetched data. */
  useEffect(() => {
    const unsubscribe = fetchAllUsersData((users) => {
      setUsers(users);
    });

    return () => unsubscribe();
  }, []);

  /* The code block you provided is using the `useState` and `useEffect` hooks in a functional component
to fetch the user's role data and update the state with the fetched role. */
  const [userRole, setUserRole] = useState(null);
  useEffect(() => {
    const fetchRole = async () => {
      const userData = await fetchUserData();
      if (userData) {
        setUserRole(userData.role);
      }
    };

    fetchRole();
  }, []);

  const handleEditClick = (docId) => {
    setSelectedId(docId);
    setOpenEditModal(true);
  };

  const handleDeleteClick = async (docId) => {
    try {
      await deleteUser(docId);
      setUsers((prevUsers) => prevUsers.filter((user) => user.docId !== docId));
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };
  return (
    <>
      <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
        <div className="flex flex-col">
          <div className="-m-1.5 overflow-x-auto">
            <div className="p-1.5 min-w-full inline-block align-middle">
              <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden dark:bg-slate-900 dark:border-gray-700">
                <UsersTopBar />
                <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                  <thead className="bg-gray-50 dark:bg-slate-800">
                    <tr className="py-3">
                      <th
                        scope="col"
                        className="ps-6 lg:ps-3 pe-6 py-3 text-start"
                      >
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                            Profile
                          </span>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="ps-6 lg:ps-3 pe-6 py-3 text-start"
                      >
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                            Name
                          </span>
                        </div>
                      </th>
                      <th scope="col" className="px-6 py-3 text-start">
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                            Role
                          </span>
                        </div>
                      </th>
                      <th scope="col" className="px-6 py-3 text-start">
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                            Username
                          </span>
                        </div>
                      </th>
                      <th scope="col" className="px-6 py-3 text-start">
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                            Phone
                          </span>
                        </div>
                      </th>
                      <th scope="col" className="px-6 py-3 text-start">
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                            Email
                          </span>
                        </div>
                      </th>
                      <th scope="col" className="px-6 py-3 text-start">
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                            Last Updated At
                          </span>
                        </div>
                      </th>
                      {userRole && userRole.toLowerCase() === 'admin' && (
                        <th scope="col" className="px-6 py-3 text-end"></th>
                      )}
                    </tr>
                  </thead>

                  <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                    {users.map((user, index) => (
                      <tr
                        key={index}
                        className="hover:bg-gray-100 dark:hover:bg-gray-800"
                      >
                        <td className="h-px w-px whitespace-nowrap">
                          <div className="ps-3 pe-6 py-5">
                            <div className="flex items-center gap-x-3">
                              <div className="grow">
                                {/* <span className="block text-sm font-semibold text-gray-800 dark:text-gray-200"> */}
                                <span className="flex h-12 w-12">
                                  {user.photoURL ? (
                                    <img className="w-full h-full object-fill rounded-full" src={user.photoURL} alt="User Photo" />
                                  ) : (
                                    <UserCircleIcon className="w-12 h-12" />
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="h-px w-px whitespace-nowrap">
                          <div className="ps-3 pe-6 py-5">
                            <div className="flex items-center gap-x-3">
                              <div className="grow">
                                <span className="block text-sm font-semibold text-gray-800 dark:text-gray-200">
                                  {user.name ? user.name : ' '}
                                </span>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="h-px w-72 whitespace-nowrap">
                          <div className="px-6 py-3">
                            <span className="block text-sm text-gray-800 dark:text-gray-200">
                              {user.role
                                ? user.role.charAt(0).toUpperCase() +
                                user.role.slice(1)
                                : ' '}
                            </span>
                          </div>
                        </td>
                        <td className="h-px w-72 whitespace-nowrap">
                          <div className="px-6 py-3">
                            <span className="block text-sm text-gray-800 dark:text-gray-200">
                              {user.username ? user.username : ' '}
                            </span>
                          </div>
                        </td>
                        <td className="h-px w-72 whitespace-nowrap">
                          <div className="px-6 py-3">
                            <span className="block text-sm text-gray-800 dark:text-gray-200">
                              {user.primaryPhone ? user.primaryPhone : ' '}
                            </span>
                          </div>
                        </td>
                        <td className="h-px w-72 whitespace-nowrap">
                          <div className="px-6 py-3">
                            <span className="block text-sm text-gray-800 dark:text-gray-200">
                              {user.primaryEmail ? user.primaryEmail : ' '}
                            </span>
                          </div>
                        </td>
                        <td className="h-px w-px whitespace-nowrap">
                          <div className="px-6 py-3">
                            <span className="text-sm text-gray-500">
                              {user.timestamp ? (
                                <>
                                  {user.timestamp
                                    .toDate()
                                    .toLocaleDateString('en-US', {
                                      day: '2-digit',
                                      month: 'short',
                                    })}
                                  ,{' '}
                                  {user.timestamp
                                    .toDate()
                                    .toLocaleTimeString('en-US', {
                                      hour: '2-digit',
                                      minute: '2-digit',
                                    })}
                                </>
                              ) : (
                                ' '
                              )}
                            </span>
                          </div>
                        </td>
                        {userRole && userRole.toLowerCase() === 'admin' && (
                          <td className="h-px w-px whitespace-nowrap">
                            <div className="px-6 py-1.5">
                              <button
                                className="inline-flex items-center gap-x-1 text-sm text-blue-600 decoration-2 hover:underline font-medium dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 cursor-pointer"
                                onClick={() => handleEditClick(user.docId)}
                              >
                                Edit
                              </button>
                              <button
                                className="inline-flex items-center gap-x-1 text-sm ml-2 text-red-600 decoration-2 hover:underline font-medium dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 cursor-pointer"
                                onClick={() => handleDeleteClick(user.docId)}
                              >
                                Delete
                              </button>
                            </div>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 dark:border-gray-700">
                  <div>
                    <p className="text-sm text-gray-600 dark:text-gray-400">
                      <span className="font-semibold text-gray-800 dark:text-gray-200">
                        {users.length}
                      </span>{' '}
                      results
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isEditModalOpen && (
        <EditUserComponent
          openEditModal={isEditModalOpen}
          setOpenEditModal={setIsEditModalOpen}
          selectedId={currentDocId}
        />
      )}
    </>
  );
};

export default UsersTable;
