const rfqFilterValue = [
  { name: 'no', operator: 'gte', type: 'number', value: '' },
  { name: 'rfqNo', operator: 'contains', type: 'string', value: '' },
  { name: 'agency', operator: 'contains', type: 'string', value: '' },
  { name: 'email', operator: 'contains', type: 'string', value: '' },
  { name: 'emailSubject', operator: 'contains', type: 'string', value: '' },
  { name: 'releaseDate', operator: 'after', type: 'date', value: '' },
  { name: 'dueBy', operator: 'after', type: 'date', value: '' },
  { name: 'lineItems', operator: 'gte', type: 'number', value: '' },
  { name: 'attachments', operator: 'gte', type: 'number', value: '' },
  { name: 'quotes', operator: 'gte', type: 'number', value: '' },
  { name: 'assignedTo', operator: 'contains', type: 'string', value: '' },
];
export default rfqFilterValue;
