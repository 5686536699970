import { app } from '../resources/gcp-config';
import {
  collection,
  doc,
  getDoc,
  getFirestore,
  onSnapshot,
} from 'firebase/firestore';

const db = getFirestore(app);

export const fetchAllInvoiceTemplates = (callback) => {
  const q = collection(db, 'invoice-templates');
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const templates = querySnapshot.docs.map((doc) => {
      return { ...doc.data(), docId: doc.id };
    });
    callback(templates);
  });
  // Return the unsubscribe function
  return unsubscribe;
};

export const fetchSingleInvoiceTemplate = async (docId) => {
  const docRef = doc(db, 'invoice-templates', docId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return { ...docSnap.data(), docId: docSnap.id };
  } else {
    console.error(new Error('No such document!'));
    return null;
  }
};
