import { useState, useEffect, useRef } from 'react';
import { deleteVendor, updateVendor } from '../../database/set-vendor-data';
import Loader from '../loader/loader';
import { warnAlert } from '../alerts';
import { SolidStar, EmptyStar } from './vendor-columns.js';
import { fetchSingleVendorData } from '../../database/fetch-vendor-data';
import { categories } from '../../resources/categories.js';
import { UserCircleIcon } from '@heroicons/react/24/outline';
import 'react-toastify/dist/ReactToastify.css';

function EditVendorComponent({ openEditModal, setOpenEditModal, selectedId }) {
  const [isLoading, setIsLoading] = useState(false);
  const [primaryContactName, setPrimaryContactName] = useState('');
  const [secondaryContactName, setSecondaryContactName] = useState('');
  const [primaryPhone, setPrimaryPhone] = useState('');
  const [secondaryPhone, setSecondaryPhone] = useState('');
  const [primaryEmail, setPrimaryEmail] = useState('');
  const [secondaryEmail, setSecondaryEmail] = useState('');
  const [isPrimaryEmailValid, setIsPrimaryEmailValid] = useState(true);
  const [isSecondaryEmailValid, setIsSecondaryEmailValid] = useState(true);
  const [rating, setRating] = useState(0);
  const [sendResellerCertificate, setSendResellerCertificate] = useState(false);
  const [vendorName, setVendorName] = useState('');
  const [comments, setComments] = useState('');
  const [primaryPSCCategory, setPrimaryPSCCategory] = useState('');
  const [secondaryPSCCategory, setSecondaryPSCCategory] = useState('');
  const [description, setDescription] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [streetAddress2, setStreetAddress2] = useState('');
  const [poBox, setPoBox] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [website, setWebsite] = useState('');
  const [vendorImagePreview, setVendorImagePreview] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [validationErrors, setValidationErrors] = useState({})
  const fileRef = useRef(null)

  const states = [
    "--",
    'AL',
    'AK',
    'AS',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'DC',
    'FL',
    'FM',
    'GA',
    'GU',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MH',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'MP',
    'OH',
    'OK',
    'OR',
    'PW',
    'PA',
    'PR',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UM',
    'UT',
    'VT',
    'VA',
    'VI',
    'WA',
    'WV',
    'WI',
    'WY',
  ];

  /**
   * The `handlePhoneNumberChange` function formats a phone number input by adding parentheses, spaces,
   * and dashes in the appropriate places.
   * @param event - The event parameter is an object that represents the event that triggered the
   * function. In this case, it is likely an event object related to a change event on an input field.
   */
  const handlePhoneNumberChange = (event, setPhone) => {
    let input = event.target.value.replace(/\D/g, '');

    if (input.length > 10) {
      input = input.substring(0, 10);
    }

    let size = input.length;

    if (size < 4) {
      input = size !== 0 ? '(' + input : input;
    } else if (size < 7) {
      input = '(' + input.substring(0, 3) + ') ' + input.substring(3, 6);
    } else {
      input =
        '(' +
        input.substring(0, 3) +
        ') ' +
        input.substring(3, 6) +
        '-' +
        input.substring(6, 10);
    }

    event.target.value = input;
    setPhone(input);
  };

  /**
   * The below code defines two functions, handlePrimaryEmailChange and handleSecondaryEmailChange, which
   * handle changes to the primary and secondary email inputs respectively and validate the email format
   * using a regular expression.
   * @param event - The `event` parameter is an object that represents the event that occurred. In this
   * case, it is an event object related to the email input field. It contains information about the
   * event, such as the target element (the input field), the value of the input field, and other
   * properties and methods
   */
  const handlePrimaryEmailChange = (event) => {
    setPrimaryEmail(event.target.value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsPrimaryEmailValid(emailRegex.test(event.target.value));
  };

  const handleSecondaryEmailChange = (event) => {
    setSecondaryEmail(event.target.value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsSecondaryEmailValid(emailRegex.test(event.target.value));
  };

  const handleZipCode = (event, setZipCode) => {
    let input = event.target.value.replace(/\D/g, '');
    event.target.value = input;
    setZipCode(input);
  };

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];

    const acceptedFileTypes = ['image/jpeg', 'image/png', 'image/gif','image/webp'];
    const maxFileSize = 5 * 1024 * 1024; 

    if (file) {
      if (!acceptedFileTypes.includes(file.type)) {
          warnAlert('Please upload a valid image file (JPEG, PNG, GIF, WEBP).');
          fileRef.current.value = "";
          return;
      }
      if (file.size > maxFileSize) {
          warnAlert('File size exceeds the 5 MB limit.');
          fileRef.current.value = "";
          return;
      }
      setImageFile(file);
      setVendorImagePreview(URL.createObjectURL(file));
  }
  };

  useEffect(() => {
    if (selectedId) {
      fetchSingleVendorData(selectedId)
        .then((data) => {
          if (data) {
            setPrimaryContactName(data.primaryContactName);
            setSecondaryContactName(data.secondaryContactName);
            setPrimaryPhone(data.primaryPhone);
            setSecondaryPhone(data.secondaryPhone);
            setPrimaryEmail(data.primaryEmail);
            setSecondaryEmail(data.secondaryEmail);
            setIsPrimaryEmailValid(true); // Assuming the fetched emails are valid
            setIsSecondaryEmailValid(true); // Assuming the fetched emails are valid
            setRating(data.rating);
            setSendResellerCertificate(data.sendResellerCertificate);
            setVendorName(data.vendorName);
            setComments(data.comments);
            setPrimaryPSCCategory(data.primaryPSCCategory);
            setSecondaryPSCCategory(data.secondaryPSCCategory);
            setDescription(data.description);
            setStreetAddress(data.streetAddress);
            setStreetAddress2(data.streetAddress2);
            setPoBox(data.poBox);
            setCity(data.city);
            setState(data.state);
            setZipCode(data.zipCode);
            setWebsite(data.website);
            setImageFile(data.imageFile);
            setVendorImagePreview(data.vendorImage)
          }
          setIsLoading(false); // Set loading to false after data is fetched
        })
        .catch((error) => {
          console.error('Error fetching vendor data: ', error);
        });
    } else {
      setIsLoading(false);
    }
  }, [selectedId]);

  return (
    <>
      {isLoading && <Loader />}

      <div
        id="addUserData-modal"
        tabIndex="-1"
        aria-hidden="true"
        className={
          openEditModal
            ? 'flex items-center justify-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full h-[calc(100%)] max-h-full bg-black bg-opacity-50'
            : 'hidden'
        }
      >
        <div className="relative p-4 w-full max-w-2xl max-h-full">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                Update Vendor
              </h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={() => setOpenEditModal(false)}
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <div className="p-4 md:p-5 space-y-4 overflow-y-auto max-h-[calc(100vh-15rem)]">
              <section className="bg-white dark:bg-gray-900">
                <div className="mx-auto max-w-2xl">
                  <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
                    <div className="relative sm:col-span-2">

                      <label htmlFor="image" className="block mt-2 mx-auto cursor-pointer w-32 h-32  items-center justify-center border rounded-full border-gray-500 relative">
                        <input
                          id="image"
                          type="file"
                          accept="image/*"
                          onChange={handleImageChange}
                          className="absolute inset-0 opacity-0 cursor-pointer"
                          ref={fileRef}
                        />
                        {vendorImagePreview ? (
                          <img
                            src={vendorImagePreview}
                            alt="Image Preview"
                            className="w-[126px] h-[126px] object-fill rounded-full"                         
                          />
                        ) : (
                          <div className="w-32 h-32 flex items-center justify-center bg-gray-200 rounded-full">
                            <UserCircleIcon className="w-32 h-32" />
                          </div>
                        )}
                      </label>
                    </div>
                    <div>
                      <label
                        htmlFor="rating"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Rating
                      </label>
                      <div id="rating" className="flex">
                        {[1, 2, 3, 4, 5].map((value) => (
                          <button
                            key={value}
                            onClick={() => setRating(value)}
                            className="flex items-center"
                          >
                            {value <= rating ? (
                              <SolidStar className="h-6 w-6 text-yellow-300" />
                            ) : (
                              <EmptyStar className="h-6 w-6" />
                            )}
                          </button>
                        ))}
                      </div>
                    </div>
                    <div className="sm:col-span-2">
                      <label
                        htmlFor="sendResellerCertificate"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      ></label>
                      <div className="flex items-center ps-4 border border-gray-200 rounded dark:border-gray-700">
                        <input
                          type="checkbox"
                          id="sendResellerCertificate"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          onChange={() =>
                            setSendResellerCertificate(!sendResellerCertificate)
                          }
                          checked={sendResellerCertificate}
                        />
                        <label
                          htmlFor="sendResellerCertificate"
                          className="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                          Send Reseller Certificate
                        </label>
                      </div>
                    </div>
                    <div className="sm:col-span-2">
                      <label
                        htmlFor="vendorname"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Vendor Name <span className="text-red-600">*</span>
                      </label>
                      <input
                        type="text"
                        name="vendorname"
                        id="vendorname"
                        className="bg-gray-200 cursor-not-allowed border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                        placeholder="Amazon"
                        required
                        value={vendorName}
                        readOnly
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="primaryPSCCategory"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Primary PSC Category
                      </label>
                      <select
                        id="primaryPSCCategory"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        required
                        value={primaryPSCCategory}
                        onChange={(event) =>
                          setPrimaryPSCCategory(event.target.value)
                        }
                      >
                        {categories.map((category) => (
                          <option
                            key={category.categoryname}
                            value={category.categoryname}
                          >
                           {truncateText(category.categoryname, 30)}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <label
                        htmlFor="secondaryPSCCategory"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Secondary PSC Category
                      </label>
                      <select
                        id="secondaryPSCCategory"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        required
                        value={secondaryPSCCategory}
                        onChange={(event) =>
                          setSecondaryPSCCategory(event.target.value)
                        }
                      >
                        {categories.map((category) => (
                          <option
                            key={category.categoryname}
                            value={category.categoryname}
                          >
                            {truncateText(category.categoryname, 30)}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="sm:col-span-2">
                      <label
                        htmlFor="description"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Description
                      </label>
                      <textarea
                        type="text"
                        name="description"
                        id="description"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="optional"
                        value={description}
                        onChange={(event) => setDescription(event.target.value)}
                      />
                    </div>
                    <div className="sm:col-span-2">
                      <label
                        htmlFor="primaryContact"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Primary Contact <span className="text-red-600">*</span>
                      </label>
                      <input
                        type="text"
                        name="primaryContact"
                        id="primaryContact"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="John Doe"
                        required
                        value={primaryContactName}
                        onChange={(event) =>
                          setPrimaryContactName(event.target.value)
                        }
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="primary_phone"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Primary Phone <span className="text-red-600">*</span>
                      </label>
                      <div className="relative">
                        <div className="absolute inset-y-0 start-0 top-0 flex items-center ps-3.5 pointer-events-none">
                          <svg
                            className="w-4 h-4 text-gray-500 dark:text-gray-400"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 19 18"
                          >
                            <path d="M18 13.446a3.02 3.02 0 0 0-.946-1.985l-1.4-1.4a3.054 3.054 0 0 0-4.218 0l-.7.7a.983.983 0 0 1-1.39 0l-2.1-2.1a.983.983 0 0 1 0-1.389l.7-.7a2.98 2.98 0 0 0 0-4.217l-1.4-1.4a2.824 2.824 0 0 0-4.218 0c-3.619 3.619-3 8.229 1.752 12.979C6.785 16.639 9.45 18 11.912 18a7.175 7.175 0 0 0 5.139-2.325A2.9 2.9 0 0 0 18 13.446Z" />
                          </svg>
                        </div>
                        <input
                          type="text"
                          id="primary_phone"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          pattern="((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}"
                          placeholder="(123) 456-7890"
                          required
                          onChange={(event) =>
                            handlePhoneNumberChange(event, setPrimaryPhone)
                          }
                          value={primaryPhone}
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="primary_email"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Primary Email <span className="text-red-600">*</span>
                      </label>
                      <input
                        type="text"
                        name="primary_email"
                        id="primary_email"
                        onChange={handlePrimaryEmailChange}
                        value={primaryEmail}
                        className={`bg-gray-50 border ${isPrimaryEmailValid
                            ? 'border-gray-300'
                            : 'border-red-600'
                          } text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500`}
                        placeholder="user@domain.com"
                        required
                      />
                    </div>
                    <div className="sm:col-span-2">
                      <label
                        htmlFor="street1"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Street 1 <span className="text-red-600">*</span>
                      </label>
                      <input
                        type="text"
                        name="street1"
                        id="street1"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="123 Street"
                        required
                        onChange={(event) =>
                          setStreetAddress(event.target.value)
                        }
                        value={streetAddress}
                      />
                    </div>
                    <div className="sm:col-span-2">
                      <label
                        htmlFor="street2"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Street 2
                      </label>
                      <input
                        type="text"
                        name="street2"
                        id="street2"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="123 Street"
                        onChange={(event) =>
                          setStreetAddress2(event.target.value)
                        }
                        value={streetAddress2}
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="poBox"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        PO Box
                      </label>
                      <input
                        type="text"
                        name="poBox"
                        id="poBox"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="123456"
                        onChange={(event) => setPoBox(event.target.value)}
                        value={poBox}
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="city"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        City <span className="text-red-600">*</span>
                      </label>
                      <input
                        type="text"
                        name="city"
                        id="city"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="Garfield"
                        required
                        onChange={(event) => setCity(event.target.value)}
                        value={city}
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="state"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        State <span className="text-red-600">*</span>
                      </label>
                      <select
                        id="state"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        required
                        onChange={(event) => setState(event.target.value)}
                        value={state}
                      >
                        {states.map((state) => (
                          <option key={state} value={state}>
                            {state}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div>
                      <label
                        htmlFor="zipCode"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        ZIP Code <span className="text-red-600">*</span>
                      </label>
                      <input
                        type="text"
                        name="zipCode"
                        id="zipCode"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="123456"
                         onChange={(event) =>  handleZipCode(event, setZipCode)}
                         value={zipCode}
                      />
                    </div>
                    <div className="sm:col-span-2">
                      <label
                        htmlFor="website"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Website
                      </label>
                      <input
                        type="text"
                        name="website"
                        id="website"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="www.example.com"
                        required
                        onChange={(event) =>
                          setWebsite(event.target.value)
                        }
                        value={website}
                      />
                    </div>
                    <div className="sm:col-span-2">
                      <label
                        htmlFor="secondaryContact"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Secondary Contact
                      </label>
                      <input
                        type="text"
                        name="secondaryContact"
                        id="secondaryContact"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="Jane Doe"
                        required
                        onChange={(event) =>
                          setSecondaryContactName(event.target.value)
                        }
                        value={secondaryContactName}
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="secondary_phone"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Secondary Phone
                      </label>
                      <div className="relative">
                        <div className="absolute inset-y-0 start-0 top-0 flex items-center ps-3.5 pointer-events-none">
                          <svg
                            className="w-4 h-4 text-gray-500 dark:text-gray-400"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 19 18"
                          >
                            <path d="M18 13.446a3.02 3.02 0 0 0-.946-1.985l-1.4-1.4a3.054 3.054 0 0 0-4.218 0l-.7.7a.983.983 0 0 1-1.39 0l-2.1-2.1a.983.983 0 0 1 0-1.389l.7-.7a2.98 2.98 0 0 0 0-4.217l-1.4-1.4a2.824 2.824 0 0 0-4.218 0c-3.619 3.619-3 8.229 1.752 12.979C6.785 16.639 9.45 18 11.912 18a7.175 7.175 0 0 0 5.139-2.325A2.9 2.9 0 0 0 18 13.446Z" />
                          </svg>
                        </div>
                        <input
                          type="text"
                          id="secondary_phone"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          pattern="((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}"
                          placeholder="(123) 456-7890"
                          required
                          onChange={(event) =>
                            handlePhoneNumberChange(event, setSecondaryPhone)
                          }
                          value={secondaryPhone}
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="secondary_email"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Secondary Email
                      </label>
                      <input
                        type="text"
                        name="secondary_email"
                        id="secondary_email"
                        onChange={handleSecondaryEmailChange}
                        value={secondaryEmail}
                        className={`bg-gray-50 border ${isSecondaryEmailValid
                            ? 'border-gray-300'
                            : 'border-red-600'
                          } text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500`}
                        placeholder="user@domain.com"
                        required
                      />
                    </div>
                    <div className="sm:col-span-2">
                      <label
                        htmlFor="comments"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Comments
                      </label>
                      <textarea
                        type="text"
                        name="comments"
                        id="comments"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="optional"
                        onChange={(event) => setComments(event.target.value)}
                        value={comments}
                      />
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
              <button
                onClick={async () => {
                  if (
                    !primaryPhone ||
                    !primaryEmail ||
                    !rating ||
                    !primaryContactName ||
                    !streetAddress ||
                    !city ||
                    !state ||
                    !zipCode
                  ) {
                    warnAlert('Please fill in all required fields.');
                    return;
                  }
                  setOpenEditModal(false);
                  setIsLoading(true);
                  const formData = {
                    primaryContactName,
                    primaryPhone,
                    primaryEmail,
                    secondaryContactName,
                    secondaryPhone,
                    secondaryEmail,
                    streetAddress,
                    streetAddress2,
                    city,
                    state,
                    zipCode,
                    poBox,
                    primaryPSCCategory,
                    secondaryPSCCategory,
                    description,
                    rating,
                    comments,
                    sendResellerCertificate,
                    website,
                    imageFile
                  };
                  await updateVendor(selectedId, formData);
                  setIsLoading(false);
                  // setVendorName('');
                  // setPrimaryContactName('');
                  // setPrimaryPhone('');
                  // setPrimaryEmail('');
                  // setSecondaryContactName('');
                  // setSecondaryPhone('');
                  // setSecondaryEmail('');
                  // setStreetAddress('');
                  // setStreetAddress2('');
                  // setCity('');
                  // setState('');
                  // setZipCode('');
                  // setPoBox('');
                  // setPrimaryPSCCategory('');
                  // setSecondaryPSCCategory('');
                  // setDescription('');
                  // setRating(0);
                  // setComments('');
                  // setWebsite('');
                  // setVendorImagePreview('')
                  // setImageFile(null);
                  // setSendResellerCertificate(false);
                }}
                type="button"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Update Vendor
              </button>
              <button
                onClick={() => setOpenEditModal(false)}
                type="button"
                className="ms-3 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  setOpenEditModal(false);
                  deleteVendor(selectedId);
                }}
                type="button"
                className="ms-3 text-white bg-red-500 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg text-sm font-medium px-5 py-2.5 focus:z-10 "
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditVendorComponent;
