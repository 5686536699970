import UsersTable from '../components/users/usersTable'; // adjust the path according to your project structure
import NavbarElement from '../components/sidebar/navbar';
import Sidebar from '../components/sidebar/sidebar';
import classNames from 'classnames';
import React, { useState } from 'react';
import FloatingButton from '../components/users/floating-users-button';
import AddUserComponent from '../components/users/addNewUser';
import EditUserComponent from '../components/users/edituser';

const UsersPage = () => {
  const [collapsed, setSidebarCollapsed] = useState(false);
  const [showSidebar, setShowSidebar] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  return (
    <div
      className={classNames({
        'grid bg-zinc-100 min-h-screen': true,
        'grid-cols-sidebar': !collapsed,
        'grid-cols-sidebar-collapsed': collapsed,
        'transition-[grid-template-columns] duration-300 ease-in-out': true,
      })}
    >
      <div
        style={{
          display: 'flex',
        }}
      >
        <div>
          <Sidebar
            collapsed={collapsed}
            setCollapsed={setSidebarCollapsed}
            shown={showSidebar}
          />
        </div>
        <div
          className="container"
          style={{ overflow: 'auto', height: '100vh' }}
        >
          <NavbarElement
            onMenuButtonClick={() => setShowSidebar((prev) => !prev)}
          />
          <UsersTable 
           setOpenEditModal={setOpenEditModal}
           setSelectedId={setSelectedId}
           />
          {/* <FloatingButton onClick={() => setOpenModal(true)} /> */}
          <AddUserComponent
            openModal={openModal}
            setOpenModal={setOpenModal} />
            {openEditModal && selectedId && (
            <EditUserComponent
              openEditModal={openEditModal}
              setOpenEditModal={setOpenEditModal}
              selectedId={selectedId}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default UsersPage;
