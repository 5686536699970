export const imageFormats = [
  'jpg',
  'jpeg',
  'webp',
  'png',
  'gif',
  'bmp',
  'svg',
  'tiff',
  'tif',
  'ico',
  'heic',
  'heif',
];
