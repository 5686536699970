import { fetchAllPosData } from '../../database/fetch-pos-data';

const getPosData = (callback) => {
  return fetchAllPosData((poss) => {
    const posData = poss
      .map((pos, index) => {
        if (!pos) {
          return null;
        }

        // // console.log(pos);

        const originalDate = new Date(pos.awardedDate);

        const day = originalDate.getDate();
        const month = originalDate.getMonth() + 1;
        const year = originalDate.getFullYear();
        const formattedDate = `${month.toString().padStart(2, '0')}-${day
          .toString()
          .padStart(2, '0')}-${year}`;

        const packetsAttachmentsLength = pos.packetsAttachments
          ? pos.packetsAttachments.length
          : 0;
        const posAttachmentsLength = pos.posAttachments
          ? pos.posAttachments.length
          : 0;
        const attachmentsLength = pos.attachments ? pos.attachments.length : 0;

        const totalAttachments =
          packetsAttachmentsLength + posAttachmentsLength + attachmentsLength;

        return {
          uniqueId: pos.posId || pos.docId,
          no: index + 1,
          spo: pos.spo || '',
          rfqNo: pos.rfqNo || '',
          agency: pos.agency || '',
          contractingOfficer: pos.primaryContactName || '',
          email: pos.primaryEmail || pos.primarySenderEmail || '',
          awardedDate: formattedDate || '',
          lineItems: pos.items?.length || 0,
          attachments: totalAttachments,
          quotes: pos.modifiedQuotes?.length || 0,
        };
      })
      .filter(Boolean);

    callback(posData);
  });
};

export default getPosData;
