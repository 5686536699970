import './loader.css'; // Import the CSS for the loader

function Loader() {
  return (
    <div
      style={{
        position: 'fixed', // Fixed positioning
        top: 0, // From the top of the viewport
        left: 0, // From the left of the viewport
        width: '100%', // Cover the full width
        height: '100%', // Cover the full height
        display: 'flex', // Use flexbox for centering
        justifyContent: 'center', // Center horizontally
        alignItems: 'center', // Center vertically
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
        zIndex: 9999, // Ensure loader is on top of all other elements
      }}
    >
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}

export default Loader;
