import React, { useEffect, useRef, useState } from 'react';
import Loader from '../loader/loader.js';
import { Editor } from '@tinymce/tinymce-react';
import {
  addEmailTemplate,
  updateEmailTemplate,
} from '../../database/set-email-template.js';
import { ReactMultiEmail } from 'react-multi-email';

const companyLogo =
  '<p><img src="https://firebasestorage.googleapis.com/v0/b/yoda-technologies-kb.appspot.com/o/serving-again-logo.png?alt=media&token=3dd3a271-c210-407f-a952-7d8e26f9ffc0" alt="Serving Again Logo" width="200" data-bit="iit"></p> ';

function CreateEmailTemplateModal({
  openModal,
  setOpenModal,
  selectedTemplate,
  setSelectedTemplate,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedMailType, setSelectedMailType] = useState('choose');
  const [mailSubject, setMailSubject] = useState([]);
  const [selectedVariable, setSelectedVariable] = useState('choose');
  const [templateTitle, setTemplateTitle] = useState('');
  const [template, setTemplate] = useState('');
  const editorRef = useRef(null);
  const subjectRef = useRef(null);

  useEffect(() => {
    if (selectedTemplate) {
      setTemplate(selectedTemplate.template);
      setSelectedMailType(selectedTemplate.mailType || 'choose');
      setTemplateTitle(selectedTemplate.title);
      setMailSubject(selectedTemplate.mailSubject || '');
    }
  }, [selectedTemplate]);

  const sendRFQMailTableLayout = `<table  style="border-collapse: collapse; width: 100%; height: 76.7968px;" border="1"><colgroup><col><col><col><col><col><col ><col><col><col><col><col><col></colgroup>
  <tbody>
  <tr style="height: 22.3984px;">
  <td style="height: 22.3984px;">CLIN</td>
  <td style="height: 22.3984px;">Item Name</td>
  <td style="height: 22.3984px;">Delivery Address</td>
  <td style="height: 22.3984px;">Required Qty</td>
  <td>Unit</td>
  <td>Vendor Cost</td>
  <td style="height: 22.3984px;">SAI Qty</td>
  <td style="height: 22.3984px;">Margin</td>
  <td style="height: 22.3984px;">G&A</td>
  <td>SAI S&H</td>
  <td>SAI Tax</td>
  <td>SAI Total</td>
  </tr>
  <tr style="height: 54.3984px;">
  <td style="height: 54.3984px;">{{clin}}</td>
  <td style="height: 54.3984px;">{{name}}</td>
  <td style="height: 54.3984px;">{{deliveryAddress}}</td>
  <td style="height: 54.3984px;">{{qty}}</td>
  <td>{{unit}}</td>
  <td>{{vendorCost}}</td>
  <td>{{saiQty}}</td>
  <td>{{margin}}</td>
  <td>{{gAndA}}</td>
  <td>{{saiSAndH}}</td>
  <td style="height: 54.3984px;">{{saiTax}}</td>
  <td style="height: 54.3984px;">{{saiTotal}}</td>
  </tr>
  </tbody>
  </table>
  `;

  const mailTypes = [
    { value: 'choose', label: 'Choose a Mail Type' },
    { value: 'rfq', label: 'Send RFQ Packet' },
    { value: 'rfq_rq', label: 'Send RFQ Request Quotes' },
    { value: 'pos_co', label: 'POS Send Confirmation Email' },
    { value: 'pos_oq', label: 'POS Original Quotes Send Vendor Email' },
    {
      value: 'pos_mq',
      label: 'POS Modified Quotes Send Update Emails to Vendors',
    },
    { value: 'del_in', label: 'Delivery Invoice' },
    { value: 'del_sh', label: 'Delivery Shipment Notification' },
  ];

  const variables = [
    { value: '', label: 'Choose Variable' },
    { value: sendRFQMailTableLayout, label: 'Send RFQ Packets Table' },
    { value: '{{totalAmount}}', label: 'Total Amount' },
    { value: '{{docId}}', label: 'Quote Id' },
    { value: '{{dueDate}}', label: 'Due Date' },
    { value: '{{rfqNo}}', label: 'RFQ No' },
    { value: '{{agency}}', label: 'Agency' },
    { value: '{{primaryContactName}}', label: 'Primary Contact Name' },
    { value: '{{clin}}', label: 'CLIN' },
    { value: '{{name}}', label: 'Item Name' },
    { value: '{{sAndH}}', label: 'S&H' },
    { value: '{{deliveryAddress}}', label: 'Delivery Address' },
    { value: '{{qty}}', label: 'Qty' },
    { value: '{{margin}}', label: 'Margin' },
    { value: '{{saiSAndH}}', label: 'SAI S&H' },
    { value: '{{saiQty}}', label: 'SAI Qty' },
    { value: '{{saiTax}}', label: 'Tax' },
    { value: '{{gAndA}}', label: 'G&A' },
    { value: companyLogo, label: 'Company Logo' },
    { value: '{{saiTotal}}', label: 'SAI Total' },
    { value: '{{unit}}', label: 'Unit' },
    { value: '{{comment}}', label: 'Comments' },
    { value: '{{vendorCost}}', label: 'Vendor Cost' },
    { value: '{{description}}', label: 'Description' },
    { value: '{{vendorName}}', label: 'Vendor Name' },
    { value: '{{vQty}}', label: 'Avl Qty' },
    { value: '{{price}}', label: 'Cost' },
    { value: '{{tax}}', label: 'Tax' },
    { value: '{{total}}', label: 'Total' },
    { value: '{{vpo}}', label: 'VPO' },
    { value: '{{uniqueId}}', label: 'Id' },
    { value: '{{linkedRfqNo}}', label: 'RFQ No' },
    { value: '{{agencyName}}', label: 'Agency Name' },
    { value: '{{primaryEmail}}', label: 'Primary Contact Email' },
    { value: '{{primaryPhone}}', label: 'Primary Contact Phone' },
    { value: '{{secondaryContactName}}', label: 'Secondary Contact Name' },
    { value: '{{secondaryEmail}}', label: 'Secondary Contact Email' },
    { value: '{{secondaryPhone}}', label: 'Secondary Contact Phone' },
    { value: '{{deliveryQty}}', label: 'Delivery Qty' },
    { value: '{{itemName}}', label: 'Item Name' },
    { value: '{{date}}', label: 'Date' },
    { value: '{{trackingID}}', label: 'Tracking Id' },
    { value: '{{deliveryPartner}}', label: 'Delivery Partner' },
    { value: '{{spo}}', label: 'SPO' }
  ];

  const sortedByLabel = variables.sort((a, b) => {
    if (a.label < b.label) return -1;
    if (a.label > b.label) return 1;
    return 0;
  });

  const insertVariable = (variable) => {
    // console.log(variable);
    if (editorRef.current) {
      editorRef.current.editor.insertContent(variable, {
        skip_focus: true,
      });
      setSelectedVariable('');
    }
  };

  const insertSubjectVariable = (variable) => {
    if (subjectRef.current) {
      subjectRef.current.value += variable;
      setMailSubject(subjectRef.current.value);
      setSelectedVariable('');
    }
  };

  const saveTemplate = () => {
    const formData = {
      title: templateTitle,
      mailType: selectedMailType,
      template: template,
      mailSubject: mailSubject,
    };
    // console.log(formData);
    addEmailTemplate(formData);
    setTemplate('');
    setOpenModal(false);
  };

  const updateTemplate = () => {
    const formData = {
      title: templateTitle,
      mailType: selectedMailType,
      template: template,
      mailSubject: mailSubject,
    };
    // console.log(formData);
    updateEmailTemplate(selectedTemplate.emailTempId, formData);
    setTemplate('');
    setOpenModal(false);
  };

  return (
    <>
      {isLoading && <Loader />}

      <div
        id="addUserData-modal"
        tabIndex="-1"
        aria-hidden="true"
        className={
          openModal
            ? 'flex items-center justify-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full h-[calc(100%)] max-h-full bg-black bg-opacity-50'
            : 'hidden'
        }
      >
        <div className="relative p-4 w-full max-w-5xl max-h-full">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                {selectedTemplate ? 'Update' : 'Create'} Email Template
              </h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={() => {
                  setOpenModal(false);
                }}
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <div className="p-4 md:p-5 space-y-4 overflow-y-auto max-h-[calc(100vh-15rem)]">
              <section className="bg-white dark:bg-gray-900">
                <div className="mx-auto max-w-5xl">
                  <div className="grid gap-4 grid-cols-2 md:grid-cols-4 sm:gap-6 mb-3">
                    <div className="col-span-2 md:col-span-4">
                      <label
                        htmlFor="selectRFQOptions"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Template Title
                      </label>
                      <input
                        id="selectRFQOptions"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        required
                        value={templateTitle}
                        onChange={(e) => {
                          setTemplateTitle(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-span-2 md:col-span-4">
                      <label
                        htmlFor="selectRFQOptions"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Select a Mail Type
                      </label>
                      <select
                        id="selectRFQOptions"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        required
                        value={selectedMailType}
                        onChange={(e) => {
                          setSelectedMailType(e.target.value);
                          setSelectedVariable('choose');
                        }}
                      >
                        {mailTypes.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-span-2">
                      <label
                        htmlFor="selectRFQOptions"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Insert Variable in Subject
                      </label>
                      <select
                        id="selectVariable"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        onChange={(e) => {
                          setSelectedVariable(e.target.value);
                          insertSubjectVariable(e.target.value);
                        }}
                        value={selectedVariable}
                      ><option value={"Choose Variable"}>
                          Choose Variable
                        </option>
                        {sortedByLabel.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-span-2">
                      <label
                        htmlFor="selectRFQOptions"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Insert Variable in Mail Body
                      </label>
                      <select
                        id="selectVariable"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        onChange={(e) => {
                          setSelectedVariable(e.target.value);
                          insertVariable(e.target.value);
                        }}
                        value={selectedVariable}
                      > <option value={"Choose Variable"}>
                          Choose Variable
                        </option>
                        {sortedByLabel.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-span-2 md:col-span-4">
                      <label
                        htmlFor="shipment_subject"
                        className="col-span-1 mb-2 font-medium text-gray-900 dark:text-white items-center inline-flex"
                      >
                        Subject
                      </label>
                      <input
                        type="text"
                        ref={subjectRef}
                        id="shipment_subject"
                        value={mailSubject}
                        className="col-span-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Write the Email Subject Over Here"
                        required
                        onChange={(e) => {
                          setMailSubject(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <Editor
                    ref={editorRef}
                    apiKey={process.env.REACT_APP_TINYMCE_EDITOR}
                    init={{
                      plugins:
                        ' anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount ',
                      toolbar:
                        'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat ',
                    }}
                    value={template}
                    onEditorChange={(content, editor) => setTemplate(content)}
                  />
                </div>
              </section>
            </div>
            <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
              <button
                type="button"
                onClick={() => {
                  if (selectedTemplate) {
                    updateTemplate();
                  } else {
                    saveTemplate();
                  }
                }}
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                {selectedTemplate ? 'Update' : 'Add'} Template
              </button>
              <button
                onClick={() => setOpenModal(false)}
                type="button"
                className="ms-3 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateEmailTemplateModal;
