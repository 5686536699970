import React from 'react';
import { deleteVendor } from '../../database/set-vendor-data';
import { UserCircleIcon } from '@heroicons/react/24/outline';

export const SolidStar = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    className={className}
  >
    <path
      fillRule="evenodd"
      d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
      clipRule="evenodd"
    />
  </svg>
);

export const EmptyStar = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="gray"
    className={className}
  >
    <path
      fillRule="evenodd"
      d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
      clipRule="evenodd"
    />
  </svg>
);

/**
 * The `starRenderer` function takes a rating value and returns a set of stars, with solid stars
 * representing the rating and empty stars representing the remaining rating.
 * @param value - The `value` parameter in the `starRenderer` function represents the rating value that
 * needs to be rendered as stars.
 * @returns The starRenderer function returns a div element containing a list of star icons.
 */
const starRenderer = (value) => {
  const rating = value.value;
  let stars = [];
  for (let i = 0; i < 5; i++) {
    if (i < rating) {
      stars.push(<SolidStar key={i} className="h-6 w-6 text-yellow-300" />);
    } else {
      stars.push(<EmptyStar key={i} className="h-6 w-6" />);
    }
  }
  return <div className="flex">{stars}</div>;
};

/**
 * The emailRenderer function returns an HTML anchor element with a mailto link.
 * @param value - The `value` parameter in the `emailRenderer` function represents the email address
 * that needs to be rendered as a clickable link.
 * @returns The emailRenderer function returns an HTML anchor element (a tag) with the email address as
 * the link text and the email address as the href attribute value.
 */
const emailRenderer = (value) => {
  return (
    <a
      href={`mailto:${value.value}`}
      className="text-blue-500 hover:text-blue-700 underline"
    >
      {value.value}
    </a>
  );
};

const webRenderer = (value) => {
  return (
    <a
      href={`${value.value}`}
      className="text-blue-500 hover:text-blue-700 underline"
    >
      {value.value}
    </a>
  );
};

/**
 * The phoneRenderer function returns a clickable phone number link.
 * @param value - The `value` parameter is the phone number that needs to be rendered as a clickable
 * link.
 * @returns The phoneRenderer function returns a JSX element that renders a telephone link with the
 * value as the displayed text.
 */
const phoneRenderer = (value) => {
  return <a href={`tel:${value.value}`}>{value.value}</a>;
};

const onDeleteRenderer = ({ data }) => {
  // // console.log('idData: ', data);

  return (
    <button
      type="button"
      onClick={() => deleteVendor(data.uniqueId)}
      className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
    >
      Delete
    </button>
  );
};

const imageRenderer = (value) => {
 return <div className="flex h-12 w-12">{
  (value.value) ? (
    <img className="w-full h-full object-fill rounded-full" src={value.value }/>
    ) : (
      <UserCircleIcon className="w-12 h-12" />
    )
  }</div>;
};

const orderColumns = (columns, order) => {
  if (!order) return columns;

  const orderedColumns = [];
  order.forEach((columnName) => {
    const column = columns.find((col) => col.name === columnName);
    if (column) {
      orderedColumns.push(column);
    }
  });

  // Add any remaining columns that are not specified in the order
  columns.forEach((column) => {
    if (!order.includes(column.name)) {
      orderedColumns.push(column);
    }
  });

  return orderedColumns;
};


const vendorColumns = (tablePreferences = {}) => {
  const columns = [
    {
      name:'vendorImage',
      header: "Vendor Image",
      minWidth: 150,
      defaultWidth: tablePreferences?.vendorImage || 150,
      render: imageRenderer, 
      style: { cursor: 'pointer' }
    },
    {
      name: 'rating',
      header: 'Rating',
      minWidth: 200,
      defaultWidth: tablePreferences?.rating || 200,
      render: starRenderer,
      style: { cursor: 'pointer' }
    },
    {
      name: 'name',
      header: 'Name',
      minWidth: 300,
      defaultWidth: tablePreferences?.name || 300,
      style: { cursor: 'pointer' }
    },
    {
      name: 'category',
      header: 'Category',
      minWidth: 200,
      defaultWidth: tablePreferences?.category || 200,
      style: { cursor: 'pointer' }
    },
    {
      name: 'contact',
      header: 'Contact',
      minWidth: 250,
      defaultWidth: tablePreferences?.contact || 250,
      style: { cursor: 'pointer' }
    },
    {
      name: 'city',
      header: 'City',
      minWidth: 200,
      defaultWidth: tablePreferences?.city || 200,
      style: { cursor: 'pointer' }
    },
    {
      name: 'state',
      header: 'State',
      minWidth: 200,
      defaultWidth: tablePreferences?.state || 200,
      style: { cursor: 'pointer' }
    },
    {
      name: 'phone',
      header: 'Phone',
      minWidth: 300,
      defaultWidth: tablePreferences?.phone || 300,
      render: phoneRenderer,
      style: { cursor: 'pointer' }
    },
    {
      name: 'email',
      header: 'Email',
      minWidth: 300,
      defaultWidth: tablePreferences?.email || 300,
      render: emailRenderer,
      style: { cursor: 'pointer' }
    },
    {
      name: 'website',
      header: 'Website',
      minWidth: 300,
      defaultWidth: tablePreferences?.website || 300,
      render: webRenderer,
      style: { cursor: 'pointer' }
    },
  ];

  const order = tablePreferences?.order;

  // Order the columns
  const orderedColumns = orderColumns(columns, order);

  return orderedColumns;
};

export default vendorColumns;
