import { app } from '../resources/gcp-config';
import { getFirestore, doc, setDoc, serverTimestamp } from 'firebase/firestore';
import { fetchUserData } from './fetch-user-data';

const db = getFirestore(app);

export default async function updateTablePref(tableName, data) {
  try {
    const user = await fetchUserData();

    if (!user || !user.docId) {
      throw new Error('User data is invalid or user is not authenticated.');
    }

    // console.log('tableName', tableName);
    // console.log('data', data);
    const docRef = doc(db, 'users', user.docId, 'table-preferences', tableName);
    await setDoc(
      docRef,
      { ...data, timestamp: serverTimestamp() },
      { merge: true }
    );
    // console.log('Table Pref Updated Successfully');
  } catch (error) {
    console.error('Error fetching table preferences:', error);
    return null;
  }
}
