import { app } from '../resources/gcp-config';
import {
  getFirestore,
  doc,
  getDoc,
  onSnapshot,
  collection,
  getDocs,
} from 'firebase/firestore';

const db = getFirestore(app);

export const fetchEmailDataPos = async (rfqId) => {
  const docRef = doc(db, 'sai-quotes-emails', rfqId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return { ...docSnap.data(), docId: docSnap.id };
  } else {
    console.error(new Error('No such document!'));
    return null;
  }
};

export const fetchAllPosData = (callback) => {
  const q = collection(db, 'pos');
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const rfqs = querySnapshot.docs.map((doc) => {
      return { ...doc.data(), docId: doc.id };
    });
    callback(rfqs); // Call the callback function with the result
  });

  // Return the unsubscribe function
  return unsubscribe;
};

// export const fetchAllPosData = (callback) => {
//   const q = collection(db, 'pos');
//   const rfqs = querySnapshot.docs.map((doc) => {
//     return { ...doc.data(), docId: doc.id };
//   });
//   return rfqs;
// };

export const fetchSinglePosData = async (docId) => {
  const docRef = doc(db, 'pos', docId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return { ...docSnap.data(), docId: docSnap.id };
  } else {
    console.error(new Error('No such document!'));
    return null;
  }
};

export const fetchSinglePosDataLive = (docId, callback) => {
  const docRef = doc(db, 'pos', docId);

  const unsubscribe = onSnapshot(docRef, (docSnap) => {
    if (docSnap.exists()) {
      callback({ ...docSnap.data(), docId: docSnap.id });
    } else {
      console.error(new Error('No such document!'));
      callback(null);
    }
  });

  return unsubscribe;
};
