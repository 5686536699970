import React from 'react';
import {
  HomeIcon,
  UserGroupIcon,
  BuildingStorefrontIcon,
  DocumentChartBarIcon,
  QueueListIcon,
  TruckIcon,
  Cog8ToothIcon,
  EnvelopeOpenIcon,
  ChartBarIcon,
} from '@heroicons/react/24/outline';

export const defaultNavItems = [
  {
    label: 'Dashboard',
    href: '/',
    icon: <HomeIcon className="w-6 h-6" />,
  },
  {
    label: 'RFQ Master',
    href: '/rfq',
    icon: <QueueListIcon className="w-6 h-6" />,
  },
  {
    label: 'PO Master',
    href: '/po',
    icon: <DocumentChartBarIcon className="w-6 h-6" />,
  },
  {
    label: 'Deliveries',
    href: '/deliveries',
    icon: <TruckIcon className="w-6 h-6" />,
  },
  {
    label: 'Vendors',
    href: '/vendors',
    icon: <BuildingStorefrontIcon className="w-6 h-6" />,
  },
  {
    label: 'Reports',
    href: '/reports',
    icon: <ChartBarIcon className="w-6 h-6" />,
  },
  {
    label: 'Users',
    href: '/users',
    icon: <UserGroupIcon className="w-6 h-6" />,
  },
  {
    label: 'Contracting Officers',
    href: '/contracting-officer',
    icon: <UserGroupIcon className="w-6 h-6" />,
  },
  {
    label: 'Email Records',
    href: '/emails',
    icon: <EnvelopeOpenIcon className="w-6 h-6" />,
  },
  {
    label: 'Email Templates',
    href: '/settings',
    icon: <Cog8ToothIcon className="w-6 h-6" />,
  },
  {
    label: 'Invoice Templates',
    href: '/invoice-template',
    icon: <Cog8ToothIcon className="w-6 h-6" />,
  },
];
