import React, { useState, useEffect } from 'react';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import getPosData from './pos-data';
import posFilterValue from './pos-sort';
import poscolumns from './pos-columns';
import updateTablePref from '../../database/set-table-pref';
import fetchTablePref from '../../database/fetch-table-pref';

const gridStyle = { height: '90vh' };

const PosTable = ({ setOpenEditModal, setSelectedId }) => {
  const [rfqData, setRfqData] = useState([]);
  const [tablePrefrences, setTablePrefrences] = useState({});

  useEffect(() => {
    const unsubscribe = getPosData((data) => {
      setRfqData(data);
    });

    fetchTablePref('PoMaster')
      .then((tableData) => {
        if (tableData) setTablePrefrences(tableData);
      })
      .catch((err) => console.log(err));

    // Cleanup function to unsubscribe when the component unmounts
    return () => unsubscribe();
  }, []);

  const handleColumnResize = async (e) => {
    const updatedColumnName = e.column.name;
    const updatedWidth = e.width;

    const preferences = {
      [updatedColumnName]: updatedWidth,
    };

    await updateTablePref('PoMaster', preferences);
  };

  const handleColumnOrderChange = async (e) => {
    await updateTablePref('PoMaster', { order: e });
  };
  return (
    <ReactDataGrid
      style={gridStyle}
      idProperty="uniqueId"
      defaultFilterValue={posFilterValue}
      columns={poscolumns(setOpenEditModal, setSelectedId, tablePrefrences)}
      onColumnResize={handleColumnResize}
      onColumnOrderChange={handleColumnOrderChange}
      dataSource={rfqData}
      pagination
      defaultLimit={20}
      rowHeight={60}
      enableSelection
      enableColumnAutosize={true}
      enableKeyboardNavigation
      showCellBorders={'horizontal'}
      columnMenu={{ lockable: false }}
    />
  );
};

export default PosTable;
