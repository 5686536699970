import {
  getFirestore,
  doc,
  setDoc,
  serverTimestamp,
  updateDoc,
  deleteDoc,
  getDoc,
} from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { app } from '../resources/gcp-config';
import { successAlert, errorAlert } from '../components/alerts';
import { v4 as uuidv4 } from 'uuid';

const db = getFirestore(app);

const storage = getStorage(app);

const createVendor = async (formData) => {
  const vendorId = uuidv4();
  formData.updatedAt = serverTimestamp();
  formData.vendorId = vendorId;
  try {

    const { imageFile } = formData;
    delete formData.imageFile
    if (imageFile) {
      const storageRef = ref(storage, `vendor_images/${imageFile.name}`);
      try {
        // Upload the file
        const snapshot = await uploadBytes(storageRef, imageFile);
        console.log('Uploaded a blob or file!', snapshot);

        // Get the download URL
        formData.vendorImage = await getDownloadURL(snapshot.ref);
        console.log('File available at', formData.vendorImage);
      } catch (error) {
        console.error('Upload failed:', error);
      }

    }
    await setDoc(doc(db, 'vendors', vendorId), formData);

    successAlert('Success!', 'A new vendor has been added.');
  } catch (error) {
    console.error('Error creating vendor:', error);
    errorAlert('Error!', 'An error occurred while creating the vendor.');
  }
};

const deleteVendor = async (vendorId) => {
  try {
    await deleteDoc(doc(db, 'vendors', vendorId));
    successAlert('Success!', 'The vendor has been deleted.');
  } catch (error) {
    console.error('Error deleting vendor:', error);
    errorAlert('Error!', 'An error occurred while deleting the vendor');
  }
};

const updateVendor = async (vendorId, formData) => {
  try {
    // Check if there's an image to update
    // Reference to the Firestore document
    const vendorDocRef = doc(db, 'vendors', vendorId);
    const {imageFile} = formData;
    delete formData.imageFile;
    if (imageFile) {
      // Get the current vendor data to find the old image URL
      const vendorDoc = await getDoc(vendorDocRef);
      const currentVendorData = vendorDoc.data();

      if (currentVendorData && currentVendorData.vendorImage) {
        // Delete the old image if it exists
        const oldImageRef = ref(storage, currentVendorData.vendorImage);
        await deleteObject(oldImageRef);
        console.log('Deleted old image:', currentVendorData.vendorImage);
      }

      // Create a reference to the new image storage location
      const storageRef = ref(storage, `vendor_images/${new Date().getTime()}-${imageFile.name}`);

      // Upload the new image
      const snapshot = await uploadBytes(storageRef, imageFile);

      // Get the download URL for the new image
      formData.vendorImage = await getDownloadURL(snapshot.ref);
    }
    // Update the vendor document with the new data and image URL if available
    await updateDoc(vendorDocRef, {
      ...formData,
      updatedAt: serverTimestamp(),
    });
    successAlert('Success!', 'The vendor has been updated.');
  } catch (error) {
    console.error('Error updating vendor:', error);
    errorAlert('Error!', 'An error occurred while updating the vendor.');
  }
};

export { updateVendor, createVendor, deleteVendor };
