import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Auth from './auth/auth';
import Dashboard from './app/dashboard-page';
import UsersPage from './app/users-page';
import RFQsPage from './app/rfqs-page';
import POsPage from './app/pos-page';
import DeliveriesPage from './app/deliveries-page';
import SettingsPage from './app/settings-page';
import VendorsPage from './app/vendors-page';
import EmailsPages from './app/emails-page';
import ReportsPage from './app/reports-page';
import InvoiceTemplatesPage from './app/invoice-template';
import withAuthCheck from './auth/check-auth-status';
import { app } from './resources/gcp-config';
import ContractingOfficerPage from './app/contractingOfficer-page';
import ProfilePage from './app/profile-page';

const ProtectedDashboard = withAuthCheck(Dashboard, app);
const ProtectedUsersPage = withAuthCheck(UsersPage, app);
const ProtectedRFQsPage = withAuthCheck(RFQsPage, app);
const ProtectedPOsPage = withAuthCheck(POsPage, app);
const ProtectedDeliveriesPage = withAuthCheck(DeliveriesPage, app);
const ProtectedSettingsPage = withAuthCheck(SettingsPage, app);
const ProtectedVendorsPage = withAuthCheck(VendorsPage, app);
const ProtectedEmailsPage = withAuthCheck(EmailsPages, app);
const ProtectedReportsPage = withAuthCheck(ReportsPage, app);
const ProtectedContractingOfficerPage = withAuthCheck(
  ContractingOfficerPage,
  app
);
const ProtectedProfilePage = withAuthCheck(ProfilePage, app);
const ProtectedInvoiceTemplatesPage = withAuthCheck(InvoiceTemplatesPage, app);

function App() {
  console.log("App running");
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Auth />} />
        <Route path="/" element={<ProtectedDashboard />} />
        <Route path="/dashboard" element={<ProtectedDashboard />} />
        <Route path="/users" element={<ProtectedUsersPage />} />
        <Route path="/vendors" element={<ProtectedVendorsPage />} />
        <Route path="/rfq" element={<ProtectedRFQsPage />} />
        <Route path="/po" element={<ProtectedPOsPage />} />
        <Route path="/emails" element={<ProtectedEmailsPage />} />
        <Route path="/deliveries" element={<ProtectedDeliveriesPage />} />
        <Route path="/reports" element={<ProtectedReportsPage />} />
        <Route path="/settings" element={<ProtectedSettingsPage />} />
        <Route
          path="/invoice-template"
          element={<ProtectedInvoiceTemplatesPage />}
        />
        <Route
          path="/contracting-officer"
          element={<ProtectedContractingOfficerPage />}
        />
        <Route path="/profile" element={<ProtectedProfilePage />} />
      </Routes>
    </Router>
  );
}

export default App;
