const posFilterValue = [
  { name: 'no', operator: 'gte', type: 'number', value: '' },
  { name: 'spo', operator: 'contains', type: 'string', value: '' },
  { name: 'rfqNo', operator: 'contains', type: 'string', value: '' },
  { name: 'agency', operator: 'contains', type: 'string', value: '' },
  {
    name: 'contractingOfficer',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  { name: 'email', operator: 'contains', type: 'string', value: '' },
  { name: 'awardedDate', operator: 'contains', type: 'string', value: '' },
  { name: 'lineItems', operator: 'gte', type: 'number', value: '' },
  { name: 'attachments', operator: 'gte', type: 'number', value: '' },
  { name: 'quotes', operator: 'gte', type: 'number', value: '' },
];
export default posFilterValue;
