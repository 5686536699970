const ReportsFilterValue = [
  { name: 'agency', operator: 'contains', type: 'string', value: '' },
  // { name: 'months', operator: 'gte', type: 'number', value: '' },
];

export const ItemsFilterValue = [
  { name: 'itemName', operator: 'contains', type: 'string', value: '' },
];

export default ReportsFilterValue;
