import React from 'react';
import classNames from 'classnames';
import { defaultNavItems } from './navItems';
import { Link } from 'react-router-dom';
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/24/outline';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import '../styles/dashboard.css';

// add NavItem prop to component prop
const Sidebar = ({
  collapsed,
  navItems = defaultNavItems,
  shown,
  setCollapsed,
}) => {
  const Icon = collapsed ? ChevronDoubleRightIcon : ChevronDoubleLeftIcon;
  const location = useLocation();
  return (
    <div
      className={classNames({
        'bg-gray-800 text-zinc-50 relative md:translate-x-0 z-20': true,
        'transition-all duration-300 ease-in-out': true,
        'w-[300px]': !collapsed,
        'w-16': collapsed,
        '-translate-x-full': !shown,
        // 'z-20': !collapsed,
        // 'z-0': collapsed,
      })}
    >
      <div
        className={classNames({
          'flex flex-col justify-between h-screen sticky inset-0 w-full': true,
        })}
      >
        {/* logo and collapse button */}
        <div
          className={classNames({
            'flex items-center border-b border-b-gray-800 transition-none': true,
            'p-4 justify-between': !collapsed,
            'py-4 justify-center': collapsed,
          })}
        >
          {!collapsed && (
            <span className="whitespace-nowrap">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/yoda-technologies-kb.appspot.com/o/serving-again-logo.png?alt=media&token=3dd3a271-c210-407f-a952-7d8e26f9ffc0"
                alt="Company Logo"
                className="px-3"
              />
            </span>
          )}
          <button
            className="grid place-content-center hover:bg-gray-800 w-10 h-10 rounded-full opacity-0 md:opacity-100"
            onClick={() => setCollapsed(!collapsed)}
          >
            <Icon className="w-5 h-5" />
          </button>
        </div>
        <nav className="flex-grow">
          <ul
            className={classNames({
              'my-2 flex flex-col gap-2 items-stretch': true,
            })}
          >
            {navItems.map((item, index) => {
              let isActive = location.pathname == item.href; // Check if item is the current page
              return (
                <li
                  key={index}
                  className={classNames({
                    'text-gray-100 hover:bg-gray-900 flex': true, //colors
                    'transition-colors duration-300': true, //animation
                    'rounded-md p-2 mx-3 gap-4 ': !collapsed,
                    'rounded-full p-2 mx-3 w-10 h-10': collapsed,
                    'bg-gray-700': isActive, // Add color for active item
                  })}
                >
                  <Link to={item.href} className="flex gap-2">
                    {item.icon} <span>{!collapsed && item.label}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
        <div
          className={classNames({
            'grid place-content-stretch p-4 ': true,
          })}
        ></div>
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  collapsed: PropTypes.bool,
  navItems: PropTypes.array,
  setCollapsed: PropTypes.func,
  shown: PropTypes.bool,
};

export default Sidebar;
