import { app } from '../resources/gcp-config';
import {
  getFirestore,
  doc,
  getDoc,
  onSnapshot,
  collection,
  getDocs,
} from 'firebase/firestore';

const db = getFirestore(app);

export const fetchAllDeliveriesData = (callback) => {
  const q = collection(db, 'deliveries');
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const deliveries = querySnapshot.docs.map((doc) => {
      const deliveryData = doc.data();
      return {
        ...deliveryData,
        docId: doc.id,
        updatedAt: deliveryData.updatedAt
          ? deliveryData.updatedAt.toDate().toLocaleString()
          : '',
      };
    });
    callback(deliveries); // Call the callback function with the result
  });

  // Return the unsubscribe function
  return unsubscribe;
};

export const fetchSingleDeliveryData = async (docId) => {
  const docRef = doc(db, 'deliveries', docId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return { ...docSnap.data(), docId: docSnap.id };
  } else {
    console.error(new Error('No such document!'));
    return null;
  }
};

export const fetchSingleDeliveryDataLive = (docId, callback) => {
  const docRef = doc(db, 'deliveries', docId);

  const unsubscribe = onSnapshot(docRef, (docSnap) => {
    if (docSnap.exists()) {
      callback({ ...docSnap.data(), docId: docSnap.id });
    } else {
      console.error(new Error('No such document!'));
      callback(null);
    }
  });

  return unsubscribe;
};

