import { useEffect, useState } from 'react';
import { imageFormats } from '../utils/constant';

const PreviewFileModal = ({
  openModal,
  setOpenModal,
  fileLink,
  attachments,
}) => {
  const [fileType, setFileType] = useState('');
  const [fileName, setFileName] = useState('');

  useEffect(() => {
    if (fileLink) {
      const matchedAttachment = attachments.find(
        (attachment) => attachment.attachmentUrl === fileLink
      );
      if (matchedAttachment) {
        setFileName(matchedAttachment.name);
        const fileExtension = matchedAttachment.name.split('.').pop();
        setFileType(fileExtension);
      }
    }
  }, [fileLink, attachments]);

  const renderPreview = () => {
    if (imageFormats.includes(fileType.toLowerCase())) {
      return (
        <img
          src={fileLink}
          alt="Preview"
          className="max-h-full max-w-full object-contain"
        />
      );
    } else {
      return (
        <iframe
          src={fileLink}
          width="100%"
          height="100%"
          title="Document Preview"
        />
      );
    }
  };

  return (
    <div
      id="preview-file-modal"
      className={`${
        openModal ? 'flex' : 'hidden'
      } fixed inset-0 z-50 items-center justify-center bg-black bg-opacity-50 overflow-y-auto overflow-x-hidden`}
      aria-hidden={!openModal}
    >
      <div className="relative w-full max-w-3xl max-h-full p-4">
        <div className="bg-white rounded-lg shadow dark:bg-gray-700">
          <div className="flex items-center justify-between p-4 border-b rounded-t dark:border-gray-600">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
              {fileName}
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
              onClick={() => setOpenModal(false)}
            >
              <svg
                className="w-5 h-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <div className="p-4 space-y-4 h-[85vh] overflow-y-auto flex justify-center items-center">
            {renderPreview()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewFileModal;
