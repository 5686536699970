import {
  getFirestore,
  doc,
  setDoc,
  serverTimestamp,
  updateDoc,
  deleteDoc,
  arrayUnion,
} from 'firebase/firestore';
import { app } from '../resources/gcp-config';
import { successAlert, errorAlert } from '../components/alerts';
import { v4 as uuidv4 } from 'uuid';

const db = getFirestore(app);

const createDelivery = async (formData) => {
  const deliveryId = uuidv4();
  formData.updatedAt = serverTimestamp();
  formData.deliveryId = deliveryId;
  try {
    await setDoc(doc(db, 'deliveries', deliveryId), formData);

    successAlert('Success!', 'A new Delivery Record has been created.');
  } catch (error) {
    console.error('Error creating pos:', error);
    errorAlert(
      'Error!',
      'An error occurred while creating the Delivery Record.'
    );
  }
};

const deleteDelivery = async (deliveryId) => {
  try {
    await deleteDoc(doc(db, 'deliveries', deliveryId));
    successAlert('Success!', 'Delivery Record has been deleted.');
  } catch (error) {
    console.error('Error deleting Delivery:', error);
    errorAlert(
      'Error!',
      'An error occurred while deleting the Delivery Record.'
    );
  }
};

const updateDelivery = async (formData, deliveryId) => {
  formData.updatedAt = serverTimestamp();
  try {
    await updateDoc(doc(db, 'deliveries', deliveryId), formData);

    successAlert('Success!', 'Delivery Record has been updated.');
  } catch (error) {
    console.error('Error creating Delivery:', error);
    errorAlert(
      'Error!',
      'An error occurred while updating the Delivery Record.'
    );
  }
};

export const updateDeliveryAttachments = async (
  formData,
  newAttachments,
  deliveryId
) => {
  try {
    formData.updatedAt = serverTimestamp();

    if (!formData.sendAttachments) {
      formData.sendAttachments = [];
    }

    formData.sendAttachments = [...formData.sendAttachments, ...newAttachments];

    // Log the processed formData for debugging
    // console.log(formData);

    // Update the Firestore document
    const deliveryDocRef = doc(db, 'deliveries', deliveryId);
    await updateDoc(deliveryDocRef, formData);

    // Success alert
    // console.log('Success!', 'Delivery Record has been updated.');
    successAlert('Success!', 'Delivery Record has been updated.');
  } catch (error) {
    console.error('Error updating Delivery:', error);
    console.error('An error occurred while updating the Delivery Record.');
    errorAlert(
      'Error!',
      'An error occurred while updating the Delivery Record.'
    );
  }
};

export { createDelivery, updateDelivery, deleteDelivery };
