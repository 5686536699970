import React from 'react';

const RFQStats = ({ data, className }) => {
  return (
    <div
      className={`max-w-md p-6 bg-white border border-gray-200 rounded-lg shadow mx-5 dark:bg-gray-800 dark:border-gray-700 cursor-default ${className}`}
    >
      <div>
        <h5 className="mb-2 text-xl font-semibold text-gray-900 dark:text-white">
          {data.StatContent || 'RFQs Found'}
        </h5>
      </div>
      <p className="mb-3 text-gray-500 dark:text-gray-400 text-6xl font-semibold">
        {data.StatCount || '0'}
      </p>
      <p className="mb-3 text-gray-500 dark:text-gray-400 font-semibold text-sm">
        {data.StatChange || '+180'} from Last Month
      </p>
    </div>
  );
};

export default RFQStats;
