import {
  getFirestore,
  doc,
  setDoc,
  serverTimestamp,
  updateDoc,
  deleteDoc,
} from 'firebase/firestore';
import { app } from '../resources/gcp-config';
import { successAlert, errorAlert } from '../components/alerts';
import { v4 as uuidv4 } from 'uuid';

const db = getFirestore(app);

const createRFQ = async (formData) => {
  const rfqId = uuidv4();
  formData.updatedAt = serverTimestamp();
  formData.rfqId = rfqId;
  try {
    await setDoc(doc(db, 'rfqs', rfqId), formData);

    successAlert('Success!', 'A new RFQ has been created.');
  } catch (error) {
    console.error('Error creating RFQ:', error);
    errorAlert('Error!', 'An error occurred while creating the RFQ.');
  }
};

const deleteRFQ = async (rfqId) => {
  try {
    await deleteDoc(doc(db, 'rfqs', rfqId));
    successAlert('Success!', 'The RFQ has been deleted.');
  } catch (error) {
    console.error('Error deleting RFQ:', error);
    errorAlert('Error!', 'An error occurred while deleting the RFQ.');
  }
};

const updateRFQ = async (rfqId, formData) => {
  try {
    await updateDoc(doc(db, 'rfqs', rfqId), {
      ...formData,
      updatedAt: serverTimestamp(),
    });
    successAlert('Success!', 'The RFQ has been updated.');
  } catch (error) {
    console.error('Error updating RFQ:', error);
    errorAlert('Error!', 'An error occurred while updating the RFQ.');
  }
};

// const a = {
//     "0": {
//         "itemName": "test1",
//         "category": "test",
//         "deliveryAddress": "test",
//         "qty": 10,
//         "id": "d17d0ac4-b13a-4471-b6af-e5edda68e693",
//         "clin": 1,
//         "itemId": "fb76336e-98db-4f84-8b75-27475432aa63",
//         "description": "test",
//         "dueBy": 10,
//         "uniqueId": "fb76336e-98db-4f84-8b75-27475432aa63"
//     },
//     "1": {
//         "category": "test2 ",
//         "qty": 1,
//         "description": "test2",
//         "dueBy": 1,
//         "id": "565b5dce-5d19-4b0e-84ba-1e63ef3b55ff",
//         "itemId": "eb2f615b-46dd-467c-ab2f-87b7a1871d38",
//         "itemName": "test2",
//         "deliveryAddress": "teest2",
//         "clin": 2,
//         "uniqueId": "eb2f615b-46dd-467c-ab2f-87b7a1871d38"
//     },
//     "2": {
//         "itemId": "7e837650-e891-4476-ae5b-7dab031fb201",
//         "clin": 221,
//         "itemName": "test 3",
//         "category": "test",
//         "qty": 100,
//         "description": "test"
//     },
//     "3": {
//         "itemId": "b74ea5fc-7b88-4a1a-9173-b612e7098956",
//         "clin": 222,
//         "itemName": "test 4",
//         "category": "test2 ",
//         "qty": 95,
//         "description": "test2"
//     },
//     "4": {
//         "itemId": "25124ff6-c86e-42a2-8cb6-ec501c56e240",
//         "clin": 223,
//         "itemName": "test 5",
//         "qty": 90
//     },
//     "5": {
//         "itemId": "4f9a1918-75a4-4c2c-8856-41f3503b1589",
//         "clin": 224,
//         "itemName": "test 6",
//         "qty": 85
//     },
//     "6": {
//         "itemId": "d073ba12-ac8a-4088-a615-069e72b722a2",
//         "clin": 225,
//         "itemName": "test 7",
//         "qty": 80
//     },
//     "7": {
//         "itemId": "b1fe680b-6616-40ff-a4b6-75c9a7ac425a",
//         "clin": 226,
//         "itemName": "test 8",
//         "qty": 75
//     },
//     "8": {
//         "itemId": "390bc351-d21e-4070-afd6-f9e8642c716d",
//         "clin": 227,
//         "itemName": "test 9",
//         "qty": 70
//     },
//     "9": {
//         "itemId": "d4d0b75c-ce26-48bb-8c71-15a6c7b0f071",
//         "clin": 228,
//         "itemName": "test 10",
//         "qty": 65
//     },
//     "10": {
//         "itemId": "5265ad4d-85d0-4ed4-9aa7-bc4e976d4067",
//         "clin": 229,
//         "itemName": "test 11",
//         "qty": 60
//     },
//     "11": {
//         "itemId": "ff376448-0bd7-43f0-8a13-58da6faab6c8",
//         "clin": 230,
//         "itemName": "test 12",
//         "qty": 55
//     },
//     "12": {
//         "itemId": "30ed756e-3436-49af-8d96-390e9862d6b0",
//         "clin": 231,
//         "itemName": "test 13",
//         "qty": 50
//     }
// }

const updateRFQArray = async (arrayElement, jsonObject, rfqId) => {
  // console.log(arrayElement, jsonObject, rfqId);
  try {
    // Determine the complete set of keys across all objects
    const allKeys = new Set();
    jsonObject.forEach((obj) => {
      Object.keys(obj).forEach((key) => allKeys.add(key));
    });

    // Preprocess each object in jsonObject
    const processedJsonObject = jsonObject.map((obj) => {
      const processedObj = {};
      allKeys.forEach((key) => {
        processedObj[key] =
          obj[key] === undefined || obj[key] === null ? '' : obj[key];
      });
      return processedObj;
    });

    // console.log(processedJsonObject);

    const rfqRef = doc(db, 'rfqs', rfqId);
    await updateDoc(rfqRef, {
      [arrayElement]: processedJsonObject,
    });

    // successAlert('Success!', 'The RFQ has been updated.');
    // console.log('RFQ array updated successfully.');
  } catch (error) {
    console.error('Error updating RFQ array:', error);
  }
};

export { createRFQ, updateRFQ, deleteRFQ, updateRFQArray };
