import React, { useState, useEffect, useRef } from 'react';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import CoColumns from './co-columns';
import CoFilterValue from './co-sort';
import { fetchAllCOData } from '../../database/fetch-co-data';
import Loader from '../loader/loader';
import { updateCo } from '../../database/set-co-data';
import { errorAlert, warnAlert } from '../alerts';

const gridStyle = { height: '90vh' };
const CoTable = () => {
  const [coData, setCoData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCo, setSelectedCo] = useState(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    setIsLoading(true);
    const unsubscribe = fetchAllCOData((COs) => {
      // // console.log(COs);
      setCoData(COs);
      setIsLoading(false);
    });
    return () => unsubscribe();
  }, []);

  const onEditComplete = async (newData) => {
    // console.log(newData);
    setCoData(async (prevData) => {
      const coIndex = prevData.findIndex((co) => co.coId === newData.rowId);
      // console.log(coIndex);
      const newCOs = [...prevData];
      newCOs[coIndex] = {
        ...newCOs[coIndex],
        [newData.columnId]: newData.value,
      };
      // console.log(newCOs[coIndex]);
      await updateCo(newData.rowId, newCOs[coIndex]);
      return newCOs;
    });
  };

  const handleImageClick = (data) => {
    setSelectedCo(data);
    fileInputRef.current?.click();
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    console.log("file>>>>>>>", file, selectedCo);
    if (file && selectedCo) {
      try {
        const acceptedFileTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
        const maxFileSize = 5 * 1024 * 1024;

        if (file) {
          if (!acceptedFileTypes.includes(file.type)) {
            warnAlert('Please upload a valid image file (JPEG, PNG, GIF, WEBP).');
            fileInputRef.current.value = "";
            return;
          }
          if (file.size > maxFileSize) {
            fileInputRef.current.value = "";
            warnAlert('File size exceeds the maximum(5 MB) limit.');
            return;
          }


          setCoData(async (prevData) => {
            const coIndex = prevData.findIndex((co) => co.coId === selectedCo.coId);
            // console.log(coIndex);
            const newCOs = [...prevData];
            const data = newCOs[coIndex];
            data.imageFile = file;
            await updateCo(selectedCo.coId, data);
            return newCOs;
          });
          setSelectedCo(null); // Clear selection
        }

      } catch (error) {
        // Handle upload error
        errorAlert('Image upload failed');
      }
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <ReactDataGrid
        style={gridStyle}
        idProperty="coId"
        defaultFilterValue={CoFilterValue}
        columns={CoColumns(handleImageClick)}
        dataSource={coData}
        pagination
        editable
        onEditComplete={onEditComplete}
        enableColumnAutosize
        defaultLimit={20}
        rowHeight={60}
        enableKeyboardNavigation
        showCellBorders={'horizontal'}
        columnMenu={{ lockable: false }}
      />
      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
    </>
  );
};

export default CoTable;
