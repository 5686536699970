import {
  getFirestore,
  doc,
  serverTimestamp,
  setDoc,
  updateDoc,
  deleteDoc,
} from 'firebase/firestore';
import { app } from '../resources/gcp-config';
import { errorAlert, successAlert } from '../components/alerts';
import { v4 as uuidv4 } from 'uuid';

const db = getFirestore(app);

export const addEmailTemplate = async (formData) => {
  const emailTempId = uuidv4();
  formData.emailTempId = emailTempId;
  formData.updatedAt = serverTimestamp();
  // console.log(formData);
  try {
    await setDoc(doc(db, 'email-templates', emailTempId), formData);

    successAlert('Success!', 'A new email template has been added.');
  } catch (error) {
    console.error('Error saving email template:', error);
    errorAlert('Error!', 'An error occurred while saving the email template.');
  }
};

export const deleteEmailTemplate = async (tempId) => {
  try {
    deleteDoc(doc(db, 'email-templates', tempId));
    successAlert('Success!', 'The template has been deleted.');
  } catch (error) {
    console.error('Error deleting template:', error);
    errorAlert('Error!', 'An error occurred while deleting the template');
  }
};

export const updateEmailTemplate = async (tempId, formData) => {
  try {
    await updateDoc(doc(db, 'email-templates', tempId), {
      ...formData,
      updatedAt: serverTimestamp(),
    });
    successAlert('Success!', 'The template has been updated.');
  } catch (error) {
    console.error('Error updating template:', error);
    errorAlert('Error!', 'An error occurred while updating the template');
  }
};
