import { fetchAllEmailData } from '../../database/fetch-email-data';

const getEmails = (callback) => {
  return fetchAllEmailData((emails) => {
    // Sort emails by timestamp in descending order
    const sortedEmails = emails.sort(
      (a, b) => b.timestamp.seconds - a.timestamp.seconds
    );

    // Map over sorted emails to create structured email data
    const emailData = sortedEmails
      .map((email, index) => {
        if (!email) return null;
        return {
          uniqueId: email.docId,
          no: parseInt(index + 1, 10),
          from: `${email.sender.name} (${email.sender.email})` || '',
          dateTime: new Date(email.timestamp.seconds * 1000).toString() || '',
          subject: email.subject || '',
          attachments: email.attachments.length || 0,
          classify: email.classifyCategory || email.classify || '',
        };
      })
      .filter(Boolean);
    callback(emailData);
  });
};

export default getEmails;
