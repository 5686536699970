import React, { useEffect, useState } from 'react';
import { fetchUserData } from '../../database/fetch-user-data';
import { Bars3Icon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { Avatar, Dropdown, Navbar } from 'flowbite-react';
import { handleLogout } from '../../auth/auth';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged, getAuth } from 'firebase/auth';
import { app } from '../../resources/gcp-config';
const auth = getAuth(app);

const NavbarElement = (props) => {
  let navigate = useNavigate();

  const [userData, setUserData] = useState(null);

  /* The `useEffect` hook in this code is used to fetch user data from the database and update the
`userData` state variable. */
  useEffect(() => {
    fetchUserData()
      .then((data) => {
        setUserData(data);
        console.log(data);
        localStorage.setItem('name', data.name.split(' ')[0]);
      })
      .catch((error) => {
        console.error('Error fetching user data: ', error);
      });
  }, []);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log(user); // Log user details
        localStorage.setItem('email', user.email);
      }
    });
  }, []);

  return (
    <nav
      className={classNames({
        'bg-white text-zinc-500': true, // colors
        'flex items-center': true, // layout
        'w-screen md:w-full sticky z-10 px-4 shadow-sm h-[73px] top-0 ': true, //positioning & styling
      })}
    >
      <div className="flex-grow"></div>
      <Navbar fluid rounded>
        <div className="flex md:order-2">
          <Dropdown
            arrowIcon={false}
            inline
            label={
              <Avatar
                placeholderInitials={userData?.name
                  ?.split(' ')
                  .map((name) => name.charAt(0))
                  .join('')}
                rounded
                img={userData?.photoURL || undefined}
              />
            }
          >
            <div className="px-4 py-3 text-sm text-gray-900 dark:text-white">
              <div>{userData?.name || '...'}</div>
              <div className="font-medium truncate">
                {userData?.primaryEmail || '...'}
              </div>
            </div>
            <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
              <li>
                <a
                  href="/dashboard"
                  className="block px-4 py-2 hover:bg-gray-100"
                >
                  Dashboard
                </a>
              </li>
              <li>
                <a
                  href="/profile"
                  className="block px-4 py-2 hover:bg-gray-100"
                >
                  Edit Profile
                </a>
              </li>
            </ul>
            <div className="py-2">
              <button
                className="px-4 py-2 text-sm text-red-600 hover:bg-gray-100 w-full text-left inline-flex items-center font-semibold"
                onClick={() => handleLogout(navigate)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 mr-3"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
                  />
                </svg>
                Sign out
              </button>
            </div>
          </Dropdown>
        </div>
      </Navbar>
      <button className="md:hidden ml-4" onClick={props.onMenuButtonClick}>
        <Bars3Icon className="h-6 w-6" />
      </button>
    </nav>
  );
};

export default NavbarElement;
