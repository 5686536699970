import { app } from '../resources/gcp-config';
import {
  getFirestore,
  doc,
  getDoc,
  onSnapshot,
  collection,
} from 'firebase/firestore';

const db = getFirestore(app);

export const fetchInboundEmails = (rfqId, callback) => {
  const docRef = doc(db, 'pos-inbound-emails', rfqId);

  // Set up the snapshot listener
  const unsubscribe = onSnapshot(docRef, (docSnap) => {
    if (docSnap.exists()) {
      const data = { ...docSnap.data(), docId: docSnap.id };
      callback(data);
    } else {
      console.error(new Error('No such document!'));
    }
  });

  return unsubscribe; // Return the unsubscribe function
};

// export const fetchInboundEmails = (rfqId, callback) => {
//   const docRef = doc(db, 'pos-inbound-emails', rfqId);

//   // Set up the snapshot listener
//   const unsubscribe = onSnapshot(docRef, (docSnap) => {
//     if (docSnap.exists()) {
//       const data = { ...docSnap.data(), docId: docSnap.id };
//       callback(data);
//     } else {
//       console.error(new Error('No such document!'));
//     }
//   });

// };
