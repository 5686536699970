const vendorFilterValue = [
  { name: 'name', operator: 'contains', type: 'string', value: '' },
  { name: 'rating', operator: 'gte', type: 'number', value: '' },
  { name: 'category', operator: 'contains', type: 'string', value: '' },
  { name: 'contact', operator: 'contains', type: 'string', value: '' },
  { name: 'city', operator: 'contains', type: 'string', value: '' },
  { name: 'state', operator: 'contains', type: 'string', value: '' },
  { name: 'phone', operator: 'contains', type: 'string', value: '' },
  { name: 'email', operator: 'contains', type: 'string', value: '' },
];

export default vendorFilterValue;
