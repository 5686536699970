import React, { useState, useEffect } from 'react';
import { app } from '../../resources/gcp-config';
import {
  getFirestore,
  collection,
  onSnapshot,
  doc,
  getDoc,
} from 'firebase/firestore';
import { fetchSingleUserData } from '../../database/fetch-user-data';

const db = getFirestore(app);

const Notifications = () => {
  const [dataa, setDataa] = useState([]);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, 'rfqs'),
      async (querySnapshot) => {
        const rfqs = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const notificationData = doc.data();

            if (notificationData.assignedTo) {
              const userData = await fetchSingleUserData(
                notificationData.assignedTo
              );

              return {
                ...notificationData,
                docId: doc.id,
                userName: userData ? userData.name : 'Unknown User',
              };
            } else {
              return {
                ...notificationData,
                docId: doc.id,
                userName: 'Unknown User',
              };
            }
          })
        );

        // date conversion to mm-dd-yyyy
        const convertedRFQs = rfqs.map((rfq) => {
          // Convert 'dueDate' string to a Date object
          const originalDate = new Date(rfq.dueDate);

          // Extract day, month, and year components
          const day = originalDate.getDate();
          const month = originalDate.getMonth() + 1; // Months are zero-indexed, so we add 1
          const year = originalDate.getFullYear();

          // Format the date as 'MM DD YYYY'
          const formattedDate = `${month.toString().padStart(2, '0')}-${day
            .toString()
            .padStart(2, '0')}-${year}`;

          // Update the 'dueDate' field in the RFQ object
          return { ...rfq, dueDate: formattedDate };
        });

        convertedRFQs.sort((a, b) => b.dueDate.localeCompare(a.dueDate));
        // // console.log(convertedRFQs);

        setDataa(convertedRFQs);
      }
    );

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    // // console.log(JSON.stringify(dataa, null, 2));
  }, [dataa]);

  return (
    <>
      <div className="w-full px-4 py-10 mx-auto">
        <div className="flex flex-col">
          <div className="-m-1.5 overflow-x-auto">
            <div className="p-1.5 w-full inline-block align-middle">
              <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden dark:bg-slate-900 dark:border-gray-700">
                <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 dark:border-gray-700">
                  <div>
                    <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
                      Recent Notifications
                    </h2>
                    <p className="text-sm text-gray-600 dark:text-gray-400">
                      All Notifications You have received over time.
                    </p>
                  </div>
                </div>
                <table className="w-full divide-y divide-gray-200 dark:divide-gray-700">
                  <thead className="bg-gray-50 dark:bg-slate-900">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-start">
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                            For
                          </span>
                        </div>
                      </th>
                      <th scope="col" className="px-6 py-3 text-start">
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                            Message
                          </span>
                        </div>
                      </th>
                      <th scope="col" className="px-6 py-3 text-start">
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                            Due at
                          </span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                    {dataa.map(
                      (notification) =>
                        notification.userName &&
                        notification.userName.toUpperCase() !==
                          'UNKNOWN USER' && (
                          <tr key={notification.docId}>
                            <td className="px-6 py-3">
                              <div className="flex items-center gap-x-2">
                                <span className="tracking-wide text-gray-800 dark:text-gray-200">
                                  {notification.userName || 'Unknown User'}
                                </span>
                              </div>
                            </td>
                            <td className="px-6 py-3">
                              <div className="flex items-center gap-x-2">
                                <span className="tracking-wide text-gray-800 dark:text-gray-200">
                                  RFQ is Due for{' '}
                                  <strong>{notification.agency}</strong> with
                                  RFQ No. <strong>{notification.rfqNo}</strong>
                                </span>
                              </div>
                            </td>
                            <td className="px-6 py-3">
                              <div className="flex items-center gap-x-2">
                                <span className="tracking-wide text-gray-800 dark:text-gray-200">
                                  {notification.dueDate}
                                </span>
                              </div>
                            </td>
                          </tr>
                        )
                    )}
                  </tbody>
                </table>
                <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 dark:border-gray-700">
                  <div>
                    <p className="text-sm text-gray-600 dark:text-gray-400">
                      <span className="font-semibold text-gray-800 dark:text-gray-200">
                        {
                          dataa.filter(
                            (notification) =>
                              notification.userName.toUpperCase() !==
                              'UNKNOWN USER'
                          ).length
                        }
                      </span>{' '}
                      results
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Notifications;
