export const categories = [
  {
    category_id: 'abe0305a-5a39-11ee-b8f1-069a17f197ff',
    categoryname: ' -- ',
    categorydescription: ' -- ',
    sector: '00',
  },
  {
    category_id: 'abe02e8e-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Aerospace Craft and Structural Components',
    categorydescription: 'Aerospace Craft and Structural Components',
    sector: '15',
  },
  {
    category_id: 'abe02e52-5a39-11ee-b8f1-069a17f197ff',
    categoryname:
      'Aerospace Craft Launching, Landing, Ground Handling and Servicing Equipment',
    categorydescription:
      'Aerospace Craft Launching, Landing, Ground Handling and Servicing Equipment',
    sector: '17',
  },
  {
    category_id: 'abe02aec-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Agricultural Machinery and Equipment',
    categorydescription: 'Agricultural Machinery and Equipment',
    sector: '37',
  },
  {
    category_id: 'abe01911-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Agricultural Supplies',
    categorydescription: 'Agricultural Supplies',
    sector: '87',
  },
  {
    category_id: 'abe0219d-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Alarm, Signal, Security Detection',
    categorydescription: 'Alarm, Signal, Security Detection',
    sector: '63',
  },
  {
    category_id: 'abe02efc-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Ammunition and Explosives',
    categorydescription: 'Ammunition and Explosives',
    sector: '13',
  },
  {
    category_id: 'abe02bc6-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Bearings',
    categorydescription: 'Bearings',
    sector: '31',
  },
  {
    category_id: 'abe01d43-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Books, Maps, Other Publications',
    categorydescription: 'Books, Maps, Other Publications',
    sector: '76',
  },
  {
    category_id: 'abe01bc9-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Brushes, Paints, Sealers, Adhesives',
    categorydescription: 'Brushes, Paints, Sealers, Adhesives',
    sector: '80',
  },
  {
    category_id: 'abe02028-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Chemicals and Chemical Products',
    categorydescription: 'Chemicals and Chemical Products',
    sector: '68',
  },
  {
    category_id: 'abe01c23-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Cleaning Equipment and Supplies',
    categorydescription: 'Cleaning Equipment and Supplies',
    sector: '79',
  },
  {
    category_id: 'abe019b8-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Clothing, Individual Equipment, Insigna, and Jewelry',
    categorydescription: 'Clothing, Individual Equipment, Insigna, and Jewelry',
    sector: '84',
  },
  {
    category_id: 'abe02385-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Comm/Detect/Coherent Radiation',
    categorydescription: 'Comm/Detect/Coherent Radiation',
    sector: '58',
  },
  {
    category_id: 'abe02ab5-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Construct/Mine/Excavate/Highwy Equipment',
    categorydescription: 'Construct/Mine/Excavate/Highwy Equipment',
    sector: '38',
  },
  {
    category_id: 'abe023e3-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Construction and Building Material',
    categorydescription: 'Construction and Building Material',
    sector: '56',
  },
  {
    category_id: 'abe01b19-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Containers/Packaging/Packing Suppl',
    categorydescription: 'Containers/Packaging/Packing Suppl',
    sector: '81',
  },
  {
    category_id: 'abe02246-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Electric Wire, Power Distrib Equipment',
    categorydescription: 'Electric Wire, Power Distrib Equipment',
    sector: '61',
  },
  {
    category_id: 'abe0232e-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Electrical/Electronic Equipment Compnts',
    categorydescription: 'Electrical/Electronic Equipment Compnts',
    sector: '59',
  },
  {
    category_id: 'abe02c2f-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Engine Accessories',
    categorydescription: 'Engine Accessories',
    sector: '29',
  },
  {
    category_id: 'abe02c65-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Engines and Turbines and Component',
    categorydescription: 'Engines and Turbines and Component',
    sector: '28',
  },
  {
    category_id: 'abe022d2-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Fiber Optic',
    categorydescription: 'Fiber Optic',
    sector: '60',
  },
  {
    category_id: 'abe02fad-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Fire Control Equipment',
    categorydescription: 'Fire Control Equipment',
    sector: '12',
  },
  {
    category_id: 'abe029ca-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Fire/Rescue/Safety; Enviro Protect',
    categorydescription: 'Fire/Rescue/Safety; Enviro Protect',
    sector: '42',
  },
  {
    category_id: 'abe01e5e-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Food Preparation/Serving Equipment',
    categorydescription: 'Food Preparation/Serving Equipment',
    sector: '73',
  },
  {
    category_id: 'abe01747-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Fuels, Lubricants, Oils, Waxes',
    categorydescription: 'Fuels, Lubricants, Oils, Waxes',
    sector: '91',
  },
  {
    category_id: 'abe02958-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Furnace/Steam/Drying; Nucl Reactor',
    categorydescription: 'Furnace/Steam/Drying; Nucl Reactor',
    sector: '44',
  },
  {
    category_id: 'abe01f15-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Furniture',
    categorydescription: 'Furniture',
    sector: '71',
  },
  {
    category_id: 'abe02ec3-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Guided Missles',
    categorydescription: 'Guided Missles',
    sector: '14',
  },
  {
    category_id: 'abe02806-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Hand Tools',
    categorydescription: 'Hand Tools',
    sector: '51',
  },
  {
    category_id: 'abe02788-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Hardware and Abrasives',
    categorydescription: 'Hardware and Abrasives',
    sector: '53',
  },
  {
    category_id: 'abe01eb7-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Household/Commerc Furnish/Appliance',
    categorydescription: 'Household/Commerc Furnish/Appliance',
    sector: '72',
  },
  {
    category_id: 'abe01f65-5a39-11ee-b8f1-069a17f197ff',
    categoryname:
      'Information Technology Equipment (Incld Firmware) Software,Supplies& Support Equipment',
    categorydescription:
      'Information Technology Equipment (Incld Firmware) Software,Supplies& Support Equipment',
    sector: '70',
  },
  {
    category_id: 'abe020f1-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Instruments and Laboratory Equipment',
    categorydescription: 'Instruments and Laboratory Equipment',
    sector: '66',
  },
  {
    category_id: 'abe021f5-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Lighting Fixtures, Lamps',
    categorydescription: 'Lighting Fixtures, Lamps',
    sector: '62',
  },
  {
    category_id: 'abe018ab-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Live Animals',
    categorydescription: 'Live Animals',
    sector: '88',
  },
  {
    category_id: 'abe0264b-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Lumber, Millwork, Plywood, Veneer',
    categorydescription: 'Lumber, Millwork, Plywood, Veneer',
    sector: '55',
  },
  {
    category_id: 'abe0283e-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Maintenance/Repair Shop Equipment',
    categorydescription: 'Maintenance/Repair Shop Equipment',
    sector: '49',
  },
  {
    category_id: 'abe02a7d-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Materials Handling Equipment',
    categorydescription: 'Materials Handling Equipment',
    sector: '39',
  },
  {
    category_id: 'abe027c8-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Measuring Tools',
    categorydescription: 'Measuring Tools',
    sector: '52',
  },
  {
    category_id: 'abe02bfb-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Mechanical Power Transmission Equipment',
    categorydescription: 'Mechanical Power Transmission Equipment',
    sector: '30',
  },
  {
    category_id: 'abe0214a-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Medical/Dental/Veterinary Equipment/Supp',
    categorydescription: 'Medical/Dental/Veterinary Equipment/Supp',
    sector: '65',
  },
  {
    category_id: 'abe0160d-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Metal Bars, Sheets, Shapes',
    categorydescription: 'Metal Bars, Sheets, Shapes',
    sector: '95',
  },
  {
    category_id: 'abe02e1c-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Metalworking Machinery',
    categorydescription: 'Metalworking Machinery',
    sector: '34',
  },
  {
    category_id: 'abde8e40-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Miscellaneous',
    categorydescription: 'Miscellaneous',
    sector: '99',
  },
  {
    category_id: 'abe02d44-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Motor Vehicles, Cycles, Trailers',
    categorydescription: 'Motor Vehicles, Cycles, Trailers',
    sector: '23',
  },
  {
    category_id: 'abe01cdf-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Musical Inst/Phonograph/Home Radio',
    categorydescription: 'Musical Inst/Phonograph/Home Radio',
    sector: '77',
  },
  {
    category_id: 'abe0168a-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Nonmetallic Crude Materials',
    categorydescription: 'Nonmetallic Crude Materials',
    sector: '94',
  },
  {
    category_id: 'abe016e4-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Nonmetallic Fabricated Materials',
    categorydescription: 'Nonmetallic Fabricated Materials',
    sector: '93',
  },
  {
    category_id: 'abe02fe7-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Nuclear Ordnance',
    categorydescription: 'Nuclear Ordnance',
    sector: '11',
  },
  {
    category_id: 'abe01dfd-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Office Mach/Text Process/Visib Rec',
    categorydescription: 'Office Mach/Text Process/Visib Rec',
    sector: '74',
  },
  {
    category_id: 'abe01d96-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Office Supplies and Devices',
    categorydescription: 'Office Supplies and Devices',
    sector: '75',
  },
  {
    category_id: 'abe01342-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Ores, Minerals and Primary Products',
    categorydescription: 'Ores, Minerals and Primary Products',
    sector: '96',
  },
  {
    category_id: 'abe02087-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Photographic Equipment',
    categorydescription: 'Photographic Equipment',
    sector: '67',
  },
  {
    category_id: 'abe028ae-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Pipe, Tubing, Hose, and Fittings',
    categorydescription: 'Pipe, Tubing, Hose, and Fittings',
    sector: '47',
  },
  {
    category_id: 'abe02921-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Plumbing, Heating, Waste Disposal',
    categorydescription: 'Plumbing, Heating, Waste Disposal',
    sector: '45',
  },
  {
    category_id: 'abe02733-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Prefab Structures/Scaffolding',
    categorydescription: 'Prefab Structures/Scaffolding',
    sector: '54',
  },
  {
    category_id: 'abe0298f-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Pumps and Compressors',
    categorydescription: 'Pumps and Compressors',
    sector: '43',
  },
  {
    category_id: 'abe02d7b-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Railway Equipment',
    categorydescription: 'Railway Equipment',
    sector: '22',
  },
  {
    category_id: 'abe01c80-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Recreational/Athletic Equipment',
    categorydescription: 'Recreational/Athletic Equipment',
    sector: '78',
  },
  {
    category_id: 'abe02a07-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Refrig, Air Condit/Circulat Equipment',
    categorydescription: 'Refrig, Air Condit/Circulat Equipment',
    sector: '41',
  },
  {
    category_id: 'abe02a3c-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Rope, Cable, Chain, Fittings',
    categorydescription: 'Rope, Cable, Chain, Fittings',
    sector: '40',
  },
  {
    category_id: 'abe02b57-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Service and Trade Equipment',
    categorydescription: 'Service and Trade Equipment',
    sector: '35',
  },
  {
    category_id: 'abe02dae-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Ship and Marine Equipment',
    categorydescription: 'Ship and Marine Equipment',
    sector: '20',
  },
  {
    category_id: 'abe02de5-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Ships, Small Craft, Pontoon, Docks',
    categorydescription: 'Ships, Small Craft, Pontoon, Docks',
    sector: '19',
  },
  {
    category_id: 'abe02b23-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Special Industry Machinery',
    categorydescription: 'Special Industry Machinery',
    sector: '36',
  },
  {
    category_id: 'abe017d1-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Subsistence',
    categorydescription: 'Subsistence',
    sector: '89',
  },
  {
    category_id: 'abe01ac2-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Textile/Leather/Fur; Tent; Flag',
    categorydescription: 'Textile/Leather/Fur; Tent; Flag',
    sector: '83',
  },
  {
    category_id: 'abe02c9e-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Tires and Tubes',
    categorydescription: 'Tires and Tubes',
    sector: '26',
  },
  {
    category_id: 'abe01964-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Toiletries',
    categorydescription: 'Toiletries',
    sector: '85',
  },
  {
    category_id: 'abe02d0b-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Tractors',
    categorydescription: 'Tractors',
    sector: '24',
  },
  {
    category_id: 'abe01fc1-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Training Aids and Devices',
    categorydescription: 'Training Aids and Devices',
    sector: '69',
  },
  {
    category_id: 'abe02877-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Valves',
    categorydescription: 'Valves',
    sector: '48',
  },
  {
    category_id: 'abe02cd3-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Vehicular Equipment Components',
    categorydescription: 'Vehicular Equipment Components',
    sector: '25',
  },
  {
    category_id: 'abe028ea-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Water Purification/Sewage Treatment',
    categorydescription: 'Water Purification/Sewage Treatment',
    sector: '46',
  },
  {
    category_id: 'abe03021-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Weapons',
    categorydescription: 'Weapons',
    sector: '10',
  },
  {
    category_id: 'abe02b8b-5a39-11ee-b8f1-069a17f197ff',
    categoryname: 'Woodworking Machinery and Equipment',
    categorydescription: 'Woodworking Machinery and Equipment',
    sector: '32',
  },
];
