import React from 'react';
import { deleteCO } from '../../database/set-co-data';
import { UserCircleIcon } from '@heroicons/react/24/outline';

/**
 * The emailRenderer function returns an HTML anchor element with a mailto link.
 * @param value - The `value` parameter in the `emailRenderer` function represents the email address
 * that needs to be rendered as a clickable link.
 * @returns The emailRenderer function returns an HTML anchor element (a tag) with the email address as
 * the link text and the email address as the href attribute value.
 */
const emailRenderer = (value) => {
  return (
    <a
      href={`mailto:${value.value}`}
      className="text-blue-500 hover:text-blue-700 underline"
    >
      {value.value}
    </a>
  );
};

/**
 * The businessPhone function returns a clickable phone number link.
 * @param value - The `value` parameter is the phone number that needs to be rendered as a clickable
 * link.
 * @returns The businessPhone function returns a JSX element that renders a telephone link with the
 * value as the displayed text.
 */
const businessPhone = (value) => {
  return <a href={`tel:${value.value}`}>{value.value}</a>;
};

const name = (value) => {
  return `${value.firstName} ${value.middleName} ${value.lastName}`;
};

const onDeleteRenderer = ({ data }) => {
  // // console.log('idData: ', data);

  return (
    <button
      type="button"
      onClick={() => deleteCO(data.docId)}
      className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
    >
      Delete
    </button>
  );
};


// const imageRenderer = (value, { data }, handleImageClick) => {
//   return (
//     <div
//       className="flex h-12 w-12 cursor-pointer"
//       onClick={() => handleImageClick(data)}
//     >
//       {value.value ? (
//         <img className="w-full h-full object-fill rounded-full" src={value.value} alt="CO Image" />
//       ) : (
//         <UserCircleIcon className="w-12 h-12" />
//       )}
//     </div>
//   );
// };

const imageRenderer = (value, { data }, handleImageClick) => {
  return (
    <div
      className="flex h-12 w-12 cursor-pointer"
      onClick={() => handleImageClick(data)}
    >
      {value.value ? (
        <img 
          className="w-full h-full object-fill rounded-full" 
          src={value.value} 
          alt="CO Image" 
        />
      ) : (
        <UserCircleIcon className="w-12 h-12" />
      )}
    </div>
  );
};

const CoColumns =(handleImageClick)=> [
  // {
  //   name: 'CO_Image', header: 'CO Image', minWidth: 150, render: (props) => (
  //     <img 
  //       src={props.data.CO_Image || '/default-image.png'} 
  //       alt="CO Image" 
  //       style={{ cursor: 'pointer', width: 50, height: 50 }} 
  //       onClick={() => handleImageClick(props.data)}
  //     />
  //   ),

  // },
  {
    name: 'CO_Image',
    header: 'CO Image',
    minWidth: 150,
    render: (props) => (
      <div
        style={{ cursor: 'pointer', width: 50, height: 50 }}
        onClick={() => handleImageClick(props.data)}
      >
        {props.data.CO_Image ? (
          <img 
            src={props.data.CO_Image} 
            alt="CO Image" 
            style={{ width: '100%', height: '100%', objectFit: 'cover' }} 
          />
        ) : (
          <UserCircleIcon className="w-full h-full" />
        )}
      </div>
    ),
  },
  { name: 'name', header: 'Name', minWidth: 200 },
  { name: 'company', header: 'Company', minWidth: 300 },
  { name: 'jobTitle', header: 'Job Title', minWidth: 300 },
  {
    name: 'businessPhone',
    header: 'Business Phone',
    minWidth: 300,
    render: businessPhone,
  },
  {
    name: 'emailAddress',
    header: 'Email',
    minWidth: 300,
    render: emailRenderer,
  },
  {
    name: 'delete',
    header: 'Delete',
    minWidth: 50,
    render: ({ value, data }) => onDeleteRenderer({ data }),
  },
];

export default CoColumns;
