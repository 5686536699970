import NavbarElement from '../components/sidebar/navbar';
import Sidebar from '../components/sidebar/sidebar';
import classNames from 'classnames';
import React, { useState } from 'react';
import PosTable from '../components/pos/pos-table';
import FloatingButton from '../components/floating-button';
import AddNewPosComponent from '../components/pos/add-new-po';
import ViewPosComponent from '../components/pos/pos-display-modal';
import '../components/styles/dashboard.css';

function POsPage(props) {
  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [collapsed, setSidebarCollapsed] = useState(false);
  const [showSidebar, setShowSidebar] = useState(true);
  return (
    <div
      className={classNames({
        'grid bg-zinc-100 min-h-screen': true,
        'grid-cols-sidebar': !collapsed,
        'grid-cols-sidebar-collapsed': collapsed,
        'transition-[grid-template-columns] duration-300 ease-in-out': true,
      })}
    >
      <div
        style={{
          display: 'flex',
        }}
      >
        <div>
          <Sidebar
            collapsed={collapsed}
            setCollapsed={setSidebarCollapsed}
            shown={showSidebar}
          />
        </div>
        <div className="container" style={{ overflow: 'auto' }}>
          <div className="">
            <NavbarElement
              onMenuButtonClick={() => setShowSidebar((prev) => !prev)}
            />
            {props.children}
            <div className="flex-grow overflow-hidden">
              <PosTable
                setOpenEditModal={setOpenEditModal}
                openEditModal={openEditModal}
                setSelectedId={setSelectedId}
              />
            </div>
          </div>
          <FloatingButton
            onClick={() => {
              console.log(selectedId);
              setOpenModal(true);
            }}
            text={"Add New PO"}
          />
          <AddNewPosComponent
            openModal={openModal}
            setOpenModal={setOpenModal}
          />
          <ViewPosComponent
            openEditModal={openEditModal}
            setOpenEditModal={setOpenEditModal}
            docId={selectedId}
          />
        </div>
      </div>
    </div>
  );
}

export default POsPage;
