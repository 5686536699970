import React, { useState, useEffect } from 'react';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import vendorColumns from './vendor-columns';
import vendorFilterValue from './vendor-sort';
import getVendorData from './vendor-data';
import fetchTablePref from '../../database/fetch-table-pref';
import updateTablePref from '../../database/set-table-pref';
const gridStyle = { height: '90vh' };

const VendorTable = ({ setOpenEditModal, setSelectedId }) => {
  const [vendorData, setVendorData] = useState([]);
  const [tablePrefrences, setTablePrefrences] = useState({});

  useEffect(() => {
    const unsubscribe = getVendorData((data) => {
      setVendorData(data);
    });
    fetchTablePref('VendorsTable')
      .then((tableData) => {
        if (tableData) setTablePrefrences(tableData);
      })
      .catch((err) => console.log(err));

    // Cleanup function to unsubscribe when the component unmounts
    return () => unsubscribe();
  }, []);

  const handleSelectionChange = ({ selected }) => {
    setOpenEditModal(true);
    setSelectedId(selected);
  };

  const handleColumnResize = async (e) => {
    const updatedColumnName = e.column.name;
    const updatedWidth = e.width;

    const preferences = {
      [updatedColumnName]: updatedWidth,
    };

    await updateTablePref('VendorsTable', preferences);
  };

  const handleColumnOrderChange = async (e) => {
    await updateTablePref('VendorsTable', { order: e });
  };

  return (
    <ReactDataGrid
      style={gridStyle}
      idProperty="uniqueId"
      defaultFilterValue={vendorFilterValue}
      columns={vendorColumns(tablePrefrences)}
      onColumnResize={handleColumnResize}
      onColumnOrderChange={handleColumnOrderChange}
      dataSource={vendorData}
      pagination
      defaultLimit={20}
      rowHeight={60}
      enableSelection
      onSelectionChange={handleSelectionChange}
      enableKeyboardNavigation
      showCellBorders={'horizontal'}
      columnMenu={{ lockable: false }}
    />
  );
};

export default VendorTable;
