import React from 'react';
import { EyeIcon } from '@heroicons/react/20/solid';
import { deleteEmail } from '../../database/set-email-data';
/**
 * The emailRenderer function returns an HTML anchor element with a mailto link.
 * @param value - The `value` parameter in the `emailRenderer` function represents the email address
 * that needs to be rendered as a clickable link.
 * @returns The emailRenderer function returns an HTML anchor element (a tag) with the email address as
 * the link text and the email address as the href attribute value.
 */

const viewRenderer = ({ data, setOpenEditModal, setSelectedId }) => {
  const handleClick = (event) => {
    const docId = event.currentTarget.getAttribute('data-doc-id');

    // console.log('docId', docId);
    setSelectedId(docId); // Set the selected id
    setOpenEditModal(true); // Open the modal
  };
  return (
    <div
      className="flex justify-center"
      data-doc-id={data.data.uniqueId}
      onClick={handleClick}
    >
      <EyeIcon className="h-5 w-5 hover:text-blue-500 cursor-pointer" />
    </div>
  );
};

const onDeleteRenderer = ({ data }) => {
  return (
    <button
      type="button"
      onClick={() => deleteEmail(data.uniqueId)}
      className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
    >
      Delete
    </button>
  );
};

const poscolumns = (setOpenEditModal, setSelectedId) => [
  {
    name: 'view',
    header: '',
    minWidth: 100,
    render: (data) => viewRenderer({ data, setOpenEditModal, setSelectedId }),
  },
  { name: 'no', header: 'No.', minWidth: 100 },
  { name: 'from', header: 'From', minWidth: 200 },
  { name: 'dateTime', header: 'Date Time', minWidth: 250 },
  { name: 'subject', header: 'Subject', minWidth: 250 },
  { name: 'attachments', header: 'Attachments', minWidth: 20 },
  { name: 'classify', header: 'Classify', minWidth: 250 },
  {
    name: 'delete',
    header: 'Delete',
    minWidth: 50,
    render: ({ value, data }) => onDeleteRenderer({ data }),
  },
];

export default poscolumns;
