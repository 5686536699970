import {
  getFirestore,
  doc,
  serverTimestamp,
  updateDoc,
  setDoc,
  deleteDoc,
  getDoc,
} from 'firebase/firestore';
import { app } from '../resources/gcp-config';
import { errorAlert, successAlert } from '../components/alerts';
import { v4 as uuidv4 } from 'uuid';
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';

const db = getFirestore(app);
const storage = getStorage(app);

const createCO = async (formData) => {
  const coId = uuidv4();
  formData.coId = coId;
  formData.updatedAt = serverTimestamp();
  // console.log(formData);
  try {

    const { imageFile } = formData;
    delete formData.imageFile
    if (imageFile) {
      const storageRef = ref(storage, `C_O_images/${imageFile.name}`);
      try {
        // Upload the file
        const snapshot = await uploadBytes(storageRef, imageFile);
        console.log('Uploaded a blob or file!', snapshot);

        // Get the download URL
        formData.CO_Image = await getDownloadURL(snapshot.ref);
        console.log('File available at', formData.CO_Image);
      } catch (error) {
        console.error('Upload failed:', error);
      }
    }

    await setDoc(doc(db, 'contracting-officer', coId), formData);
    successAlert('Success!', 'A new contracting officer has been added.');
  } catch (error) {
    console.error('Error creating vendor:', error);
    errorAlert(
      'Error!',
      'An error occurred while creating the contracting officer.'
    );
  }
};

const deleteCO = async (docId) => {
  try {
    await deleteDoc(doc(db, 'contracting-officer', docId));
    successAlert('Success!', 'The contracting officer has been deleted.');
  } catch (error) {
    console.error('Error deleting vendor:', error);
    errorAlert(
      'Error!',
      'An error occurred while deleting the contracting officer.'
    );
  }
};

const updateCo = async (coId, formData) => {
  try {
    const CO_DocRef = doc(db, 'contracting-officer', coId);
    const {imageFile} = formData;
    delete formData.imageFile;
    if (imageFile) {
      // Get the current vendor data to find the old image URL
      const CO_Doc = await getDoc(CO_DocRef);
      const currentCOData = CO_Doc.data();

      if (currentCOData && currentCOData.CO_Image) {
        // Delete the old image if it exists
        try {
          const oldImageRef = ref(storage, getImagePathFromURL(currentCOData.CO_Image));
          await deleteObject(oldImageRef);
        } catch (e){
        }
      }

      // Create a reference to the new image storage location
      const newImagePath = `C_O_images/${new Date().getTime()}-${imageFile.name}`;
      const storageRef = ref(storage, newImagePath);

      // Upload the new image
      const snapshot = await uploadBytes(storageRef, imageFile);

      // Get the download URL for the new image
      formData.CO_Image = await getDownloadURL(snapshot.ref);
    }
    // Update the vendor document with the new data and image URL if available
    await updateDoc(CO_DocRef, {
      ...formData,
      updatedAt: serverTimestamp(),
    });
    successAlert('Success!', 'The vendor has been updated.');
  } catch (error) {
    console.error('Error updating vendor:', error);
    errorAlert('Error!', 'An error occurred while updating the vendor.');
  }
};

const getImagePathFromURL = (url) => {
  const urlParts = url.split('/');
  return urlParts[urlParts.length - 1];
};

export { createCO, updateCo, deleteCO };
