import React, { useState, useEffect } from 'react';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import ReportsColumns, {
  ItemsColumns,
  ItemsTotalColumns,
  TotalsColumns,
} from './reports-columns';
import ReportsFilterValue, { ItemsFilterValue } from './reports-sort';
import Loader from '../loader/loader';
import { fetchAllDeliveriesData } from '../../database/fetch-deliveries-data';
import { fetchAllPosData } from '../../database/fetch-pos-data';
import { fetchAllRfqData } from '../../database/fetch-rfq-data';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import * as XLSX from 'xlsx';
import { ArrowDownTrayIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { MultiSelect } from "react-multi-select-component";
ChartJS.register(ArcElement, Tooltip, Legend);

const { v4: uuidv4 } = require('uuid');

const gridStyle = { height: '70vh' };
const gridStyle2 = { height: '' };

const ReportsTable = ({ chartData, setChartData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [reportsData, setReportsData] = useState([]);
  const [processedData, setProcessedData] = useState([]);
  const [columnTotals, setColumnTotals] = useState({
    totalPO: 0,
    totalRfqResponses: 0,
    totalPoCost: 0,
    totalRfqCost: 0,
    projMargin: 0,
    totalFinalPoCost: 0,
    actualMargin: 0,
    taxes: 0,
    profit: 0,
    months: [],
    vpoInvoices: 0,
    totalSpoInvoices: 0,
    buyingPrice: 0,
    posMonths: [], // Initialize an array for posMonths
    deliveryMonths: [], // Initialize an array for deliveryMonths
    rfqMonths: [],
  });
  const [selectedMonth, setSelectedMonth] = useState(1);
  const [endMonth, setEndMonth] = useState(12);
  const [itemsData, setItemsData] = useState([]);
  const [itemsColumnTotals, setItemsColumnTotals] = useState({
    totalPoCost: 0,
    totalRfqCost: 0,
    profit: 0,
    qty: 0,
  });

  const options = [
    { label: "Agency", value: "agency" },
    { label: "Total PO", value: "totalPO" },
    { label: "Total RFQ Responses", value: "totalRfqResponses" },
    { label: "Total PO Cost", value: "totalPoCost" },
    { label: "Total RFQ Cost", value: "totalRfqCost" },
    { label: "Project Margin", value: "projMargin" },
    { label: "Total Final PO Cost", value: "totalFinalPoCost" },
    { label: "Actual Margin", value: "actualMargin" },
    { label: "Taxes", value: "taxes" },
    { label: "Profit", value: "profit" },
    { label: "Months", value: "months" },
    { label: "VPO Invoices", value: "vpoInvoices" },
    { label: "Total SPO Invoices", value: "totalSpoInvoices" },
    { label: "Buying Price", value: "buyingPrice" },
  ];
  const options1 = [
    { label: "Item Name", value: "itemName" },
    { label: "Total PO Cost", value: "totalPoCost" },
    { label: "Total LI Purchase Cost", value: "totalLIPurchaseCost" },
    { label: "Margin", value: "margin" },
    { label: "Profit", value: "profit" },
    { label: "Qty", value: "qty" },
  ];
  const [selected, setSelected] = useState(options);
  const [selectedItem, setSelectedItem] = useState(options1);


  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const deliveries = await new Promise((resolve) => fetchAllDeliveriesData(resolve));
        const pos = await new Promise((resolve) => fetchAllPosData(resolve));
        const rfqs = await new Promise((resolve) => fetchAllRfqData(resolve));
        setReportsData([deliveries, pos, rfqs]);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (reportsData.length !== 0) {
      const flatArray = reportsData.flat();
      // // console.log(flatArray);
      const agencyTotals = {};
      let agencyName = [];
      flatArray.forEach((item) => {
        agencyName = item['agency'] ? item['agency'] : '';

        if (agencyName) {
          if (!agencyTotals[agencyName]) {
            agencyTotals[agencyName] = {
              uniqueId: uuidv4(),
              totalPO: Array(12).fill(0),
              totalRfqResponses: Array(12).fill(0),
              totalPoCost: Array(12).fill(0),
              totalRfqCost: Array(12).fill(0),
              projMargin: Array(12).fill(0),
              totalFinalPoCost: Array(12).fill(0),
              actualMargin: Array(12).fill(0),
              taxes: Array(12).fill(0),
              profit: Array(12).fill(0),
              months: [],
              vpoInvoices: Array(12).fill(0),
              totalSpoInvoices: Array(12).fill(0),
              buyingPrice: Array(12).fill(0),
              posMonths: [],
              deliveryMonths: [],
              rfqMonths: [],
            };
          }

          // calculating the totalPO field
          let awardedMonth = 0;
          if (item['posId'] && item['uniqueId']) {
            if (item['awardedDate']) {
              awardedMonth = new Date(item['awardedDate']).getMonth();
              agencyTotals[agencyName].totalPO[awardedMonth]++;

              // Check if the month doesn't already exist in posMonths array
              if (!agencyTotals[agencyName].posMonths.includes(awardedMonth)) {
                agencyTotals[agencyName].posMonths.push(awardedMonth);
              }
            }
            agencyTotals[agencyName].posMonths.sort();
          }

          // calculating the vpo invoices
          let deliveryMonth;
          if (item['vpoId']) {
            if (item['deliveryDate']) {
              deliveryMonth = new Date(item['deliveryDate']).getMonth();
              agencyTotals[agencyName].vpoInvoices[deliveryMonth]++;
              if (
                !agencyTotals[agencyName].deliveryMonths.includes(deliveryMonth)
              ) {
                agencyTotals[agencyName].deliveryMonths.push(deliveryMonth);
              }
            }
            agencyTotals[agencyName].deliveryMonths.sort();
          }

          // calculating the spo invoices
          if (item['spoId']) {
            agencyTotals[agencyName].totalSpoInvoices[deliveryMonth]++;
          }

          // calculating the buying price and totalPOcost and totalFinalPOcost
          if (item['rfqId'] && item['posId']) {
            if (
              item['originalQuotes'] &&
              Array.isArray(item['originalQuotes'])
            ) {
              // // console.log(item['agency']);
              const totalPoCost = item['originalQuotes'].reduce(
                (sum, quote) => {
                  if (quote['total'] !== undefined && quote['total'] !== null) {
                    let quoteTotal = quote['total'];
                    // // console.log(quoteTotal);
                    if (typeof quoteTotal === 'string') {
                      quoteTotal = parseFloat(quoteTotal) || 0;
                    }

                    sum += quoteTotal;
                    if (
                      quote['vQty'] !== undefined &&
                      quote['vQty'] !== null &&
                      quote['price'] !== undefined &&
                      quote['price'] !== null
                    ) {
                      let vQty =
                        typeof quote['vQty'] === 'string'
                          ? parseFloat(quote['vQty']) || 0
                          : quote['vQty'];
                      let price =
                        typeof quote['price'] === 'string'
                          ? parseFloat(quote['price']) || 0
                          : quote['price'];

                      // Multiply vQty with price and add it to buyingPrice
                      let buyingPrice = vQty * price;
                      buyingPrice = isNaN(buyingPrice) ? 0 : buyingPrice;
                      agencyTotals[agencyName].buyingPrice[awardedMonth] +=
                        buyingPrice;
                    }
                  }
                  return sum;
                },
                0
              );
              agencyTotals[agencyName].totalPoCost[awardedMonth] += totalPoCost;
            }

            if (item['saiTotal'] !== undefined && item['saiTotal'] !== null) {
              let finalPoCost = item['saiTotal'];
              // // console.log(finalPoCost);
              if (typeof finalPoCost === 'string') {
                finalPoCost = parseFloat(finalPoCost) || 0;
              }
              agencyTotals[agencyName].totalFinalPoCost[awardedMonth] +=
                finalPoCost;
            }
          }

          // calculating the taxes
          if (item['tax'] !== undefined && item['tax'] !== null) {
            let tax = item['tax'];
            // // console.log(tax);
            if (typeof tax === 'string') {
              tax = parseFloat(tax) || 0;
            }

            // Add the tax to the agency
            agencyTotals[agencyName].taxes[awardedMonth] += tax;
          }

          // calculating the rfq count and rfq cost
          if (item['rfqId'] && !item['posId']) {
            let rfqMonth = 0;
            if (item['releaseDate']) {
              rfqMonth = new Date(item['releaseDate']).getMonth();

              // Check if the month doesn't already exist in rfqMonths array
              if (!agencyTotals[agencyName].rfqMonths.includes(rfqMonth)) {
                agencyTotals[agencyName].rfqMonths.push(rfqMonth);
              }
            }
            agencyTotals[agencyName].totalRfqResponses[rfqMonth]++;
            if (item['total'] !== undefined && item['total'] !== null) {
              let topLevelTotal = item['total'];
              // // console.log(topLevelTotal);
              if (typeof topLevelTotal === 'string') {
                topLevelTotal = parseFloat(topLevelTotal) || 0;
              }
              agencyTotals[agencyName].totalRfqCost[rfqMonth] += topLevelTotal;
            }
          }
          // calculation of profits
          agencyTotals[agencyName].profit[awardedMonth] =
            agencyTotals[agencyName].totalFinalPoCost[awardedMonth] -
            agencyTotals[agencyName].buyingPrice[awardedMonth];

          // calculating the months
          agencyTotals[agencyName].months = [
            ...new Set([
              ...agencyTotals[agencyName].posMonths,
              ...agencyTotals[agencyName].deliveryMonths,
              ...agencyTotals[agencyName].rfqMonths,
            ]),
          ];

          // Sort the months array if needed
          agencyTotals[agencyName].months.sort();
        }
      });

      // // console.log(agencyTotals);
      const finalData = Object.keys(agencyTotals).map((agencyName) => ({
        agency: agencyName,
        ...agencyTotals[agencyName],
      }));
      // // console.log(finalData);
      const aggregatedDaata = aggregateDataByMonth(finalData);
      // // console.log(aggregatedDaata);
      setChartData(aggregatedDaata);

      // filtering the data
      const filteredData = finalData
        .filter((item) => {
          const selectedMonthIndex = selectedMonth - 1;
          const endMonthIndex = endMonth - 1;

          // Check if the item has data for any month in the range
          return item.months.some(
            (month) => month >= selectedMonthIndex && month <= endMonthIndex
          );
        })
        .map((item) => {
          const selectedMonthIndex = selectedMonth - 1;
          const endMonthIndex = endMonth - 1;
          // // console.log(item.totalPoCost[selectedMonthIndex]);
          // // console.log(item.totalRfqResponses[selectedMonthIndex]);
          const accumulatedValues = Array.from(
            { length: endMonthIndex - selectedMonthIndex + 1 },
            (_, i) => {
              const monthIndex = selectedMonthIndex + i;

              return {
                totalPO: item.totalPO[monthIndex] || 0,
                totalRfqResponses: item.totalRfqResponses[monthIndex] || 0,
                totalPoCost: item.totalPoCost[monthIndex] || 0,
                totalRfqCost: item.totalRfqCost[monthIndex] || 0,
                projMargin: item.projMargin[monthIndex] || 0,
                totalFinalPoCost: item.totalFinalPoCost[monthIndex] || 0,
                actualMargin: item.actualMargin[monthIndex] || 0,
                taxes: item.taxes[monthIndex] || 0,
                profit: item.profit[monthIndex] || 0,
                vpoInvoices: item.vpoInvoices[monthIndex] || 0,
                totalSpoInvoices: item.totalSpoInvoices[monthIndex] || 0,
                buyingPrice: item.buyingPrice[monthIndex] || 0,
              };
            }
          );

          const agencyName = item.agency || '';
          const result = accumulatedValues.reduce(
            (acc, values) => ({
              agency: agencyName,
              totalPO: acc.totalPO + values.totalPO,
              totalRfqResponses:
                acc.totalRfqResponses + values.totalRfqResponses,
              totalPoCost: acc.totalPoCost + values.totalPoCost,
              totalRfqCost: acc.totalRfqCost + values.totalRfqCost,
              projMargin: acc.projMargin + values.projMargin,
              totalFinalPoCost: acc.totalFinalPoCost + values.totalFinalPoCost,
              actualMargin: acc.actualMargin + values.actualMargin,
              taxes: acc.taxes + values.taxes,
              profit: acc.profit + values.profit,
              months: endMonthIndex - selectedMonthIndex + 1,
              vpoInvoices: acc.vpoInvoices + values.vpoInvoices,
              totalSpoInvoices: acc.totalSpoInvoices + values.totalSpoInvoices,
              buyingPrice: acc.buyingPrice + values.buyingPrice,
            }),
            {
              agency: '',
              totalPO: 0,
              totalRfqResponses: 0,
              totalPoCost: 0,
              totalRfqCost: 0,
              projMargin: 0,
              totalFinalPoCost: 0,
              actualMargin: 0,
              taxes: 0,
              profit: 0,
              months: 0,
              vpoInvoices: 0,
              totalSpoInvoices: 0,
              buyingPrice: 0,
            }
          );
          return result;
        });
      setProcessedData(filteredData);
      const calculatedTotals = calculateTotals(filteredData);
      setColumnTotals(calculatedTotals);
    }
  }, [reportsData, selectedMonth, endMonth, setChartData]);

  useEffect(() => {
    if (reportsData.length !== 0) {
      const flatArray = reportsData.flat();
      let itemsNamesSet = new Set();
      let itemsTotals = {};

      flatArray.forEach((item) => {
        // itemNames
        if (item['rfqId'] && !item['posId']) {
          item.items.forEach((subItem) => {
            const itemName = subItem['itemName'] || '';
            itemsNamesSet.add(itemName);
          });
        }

        if (item['posId'] && item['uniqueId']) {
          item.originalQuotes.forEach((subItem) => {
            const itemName = subItem['itemName'] || '';
            itemsNamesSet.add(itemName);
          });
        }

        itemsNamesSet.forEach((itemName) => {
          // Initialize itemsTotals for each unique itemName
          if (!itemsTotals[itemName]) {
            itemsTotals[itemName] = {
              totalPoCost: Array(12).fill(0),
              totalFinalPoCost: Array(12).fill(0),
              totalRfqCost: Array(12).fill(0),
              margin: Array(12).fill(0),
              profit: Array(12).fill(0),
              qty: Array(12).fill(0),
              months: [],
              posMonths: [],
              rfqMonths: [],
            };
          }

          let awardedMonth;
          if (item['posId'] && item['uniqueId']) {
            if (item['awardedDate']) {
              awardedMonth = new Date(item['awardedDate']).getMonth();
              if (!itemsTotals[itemName].posMonths.includes(awardedMonth)) {
                itemsTotals[itemName].posMonths.push(awardedMonth);
              }
            }
            itemsTotals[itemName].posMonths.sort();

            if (
              item['originalQuotes'] &&
              Array.isArray(item['originalQuotes'])
            ) {
              item['originalQuotes'].forEach((quote) => {
                const tempName = quote['itemName'] || '';
                if (tempName === itemName) {
                  const totalPoCost = quote['total'];
                  if (totalPoCost !== undefined && totalPoCost !== null) {
                    itemsTotals[itemName].totalPoCost[awardedMonth] +=
                      parseFloat(totalPoCost) || 0;
                  }

                  const totalFinalPoCost = quote['saiTotal'];
                  if (
                    totalFinalPoCost !== undefined &&
                    totalFinalPoCost !== null
                  ) {
                    itemsTotals[itemName].totalFinalPoCost[awardedMonth] +=
                      parseFloat(totalFinalPoCost) || 0;
                  }

                  const qty = quote['qty'];
                  if (qty !== undefined && qty !== null && !isNaN(qty)) {
                    itemsTotals[itemName].qty[awardedMonth] += parseFloat(qty);
                  }
                }
              });
            }
          }

          if (item['rfqId'] && !item['posId']) {
            let rfqMonth = 0;
            if (item['releaseDate']) {
              rfqMonth = new Date(item['releaseDate']).getMonth();
              if (!itemsTotals[itemName].rfqMonths.includes(rfqMonth)) {
                itemsTotals[itemName].rfqMonths.push(rfqMonth);
              }
            }
            itemsTotals[itemName].rfqMonths.sort();

            if (item['items'] && Array.isArray(item['items'])) {
              item['items'].forEach((quote) => {
                const tempName = quote['itemName'] || '';
                if (tempName === itemName) {
                  const totalRfqCost = quote['total'];
                  if (totalRfqCost !== undefined && totalRfqCost !== null) {
                    itemsTotals[itemName].totalRfqCost[awardedMonth] +=
                      parseFloat(totalRfqCost) || 0;
                  }
                }
              });
            }
          }

          itemsTotals[itemName].months = [
            ...new Set([
              ...itemsTotals[itemName].posMonths,
              ...itemsTotals[itemName].rfqMonths,
            ]),
          ];

          itemsTotals[itemName].months.forEach((month) => {
            itemsTotals[itemName].profit[month] =
              itemsTotals[itemName].totalRfqCost[month] -
              itemsTotals[itemName].totalPoCost[month];

            itemsTotals[itemName].margin[month] =
              itemsTotals[itemName].totalFinalPoCost[month] -
              itemsTotals[itemName].totalPoCost[month];
          });
          itemsTotals[itemName].months.sort();
        });
      });

      const itemsDataArray = Object.entries(itemsTotals).map(
        ([itemName, itemData]) => ({
          itemName,
          totalPoCost: itemData.totalPoCost,
          totalFinalPoCost: itemData.totalFinalPoCost,
          totalRfqCost: itemData.totalRfqCost,
          margin: itemData.margin,
          profit: itemData.profit,
          qty: itemData.qty,
          months: itemData.months,
          posMonths: itemData.posMonths,
          rfqMonths: itemData.rfqMonths,
        })
      );

      // console.log('ItemsDataArray', itemsDataArray);
      const selectedMonthIndex = selectedMonth - 1;
      const endMonthIndex = endMonth - 1;

      const filteredData = itemsDataArray
        .filter((item) =>
          item.months.some(
            (month) => month >= selectedMonthIndex && month <= endMonthIndex
          )
        )
        .map((item) => {
          const accumulatedValues = Array.from(
            { length: endMonthIndex - selectedMonthIndex + 1 },
            (_, i) => {
              const monthIndex = selectedMonthIndex + i;

              return {
                itemName: item.itemName,
                totalPoCost: item.totalPoCost[monthIndex] || 0,
                totalFinalPoCost: item.totalFinalPoCost[monthIndex] || 0,
                totalRfqCost: item.totalRfqCost[monthIndex] || 0,
                margin: item.margin[monthIndex] || 0,
                profit: item.profit[monthIndex] || 0,
                qty: item.qty[monthIndex] || 0,
              };
            }
          );

          const result = accumulatedValues.reduce(
            (acc, values) => ({
              itemName: values.itemName,
              totalPoCost: acc.totalPoCost + values.totalPoCost,
              totalFinalPoCost: acc.totalFinalPoCost + values.totalFinalPoCost,
              margin: acc.margin + values.margin,
              totalRfqCost: acc.totalRfqCost + values.totalRfqCost,
              profit: acc.profit + values.profit,
              qty: acc.qty + values.qty,
            }),
            {
              itemName: item.itemName,
              totalPoCost: 0,
              totalFinalPoCost: 0,
              totalRfqCost: 0,
              margin: 0,
              profit: 0,
              qty: 0,
            }
          );
          return result;
        });

      // console.log('FilteredData', filteredData);
      setItemsData(filteredData);
      const tempTotals = calculateItemsTotals(filteredData);
      setItemsColumnTotals(tempTotals);
    }
  }, [reportsData, selectedMonth, endMonth]);

  const calculateTotals = (flatArray) => {
    const totals = {
      totalPO: 0,
      totalRfqResponses: 0,
      totalPoCost: 0,
      totalRfqCost: 0,
      projMargin: 0,
      totalFinalPoCost: 0,
      actualMargin: 0,
      taxes: 0,
      profit: 0,
      months: 0,
      vpoInvoices: 0,
      totalSpoInvoices: 0,
      buyingPrice: 0,
    };

    flatArray.forEach((item) => {
      // Update the totals based on the item properties
      totals.totalPO += item.totalPO || 0;
      totals.totalRfqResponses += item.totalRfqResponses || 0;
      totals.totalPoCost += item.totalPoCost || 0;
      totals.totalRfqCost += item.totalRfqCost || 0;
      totals.projMargin += item.projMargin || 0;
      totals.totalFinalPoCost += item.totalFinalPoCost || 0;
      totals.actualMargin += item.actualMargin || 0;
      totals.taxes += item.taxes || 0;
      totals.profit += item.profit || 0;
      totals.months += item.months || 0;
      totals.vpoInvoices += item.vpoInvoices || 0;
      totals.totalSpoInvoices += item.totalSpoInvoices || 0;
      totals.buyingPrice += item.buyingPrice || 0;
    });

    return [totals];
  };

  function aggregateDataByMonth(finalData) {
    // Initialize an object to store aggregated data
    const aggregatedData = {};

    // Iterate over each agency object in finalData
    finalData.forEach((agency) => {
      // Iterate over each key in the agency object
      Object.keys(agency).forEach((key) => {
        // Skip keys that are not arrays (e.g., 'agency', 'uniqueId', 'months', etc.)
        if (!Array.isArray(agency[key])) {
          return;
        }

        // Ensure the key exists in the aggregatedData object
        if (!aggregatedData[key]) {
          aggregatedData[key] = Array(12).fill(0); // Initialize with 12 zeros for 12 months
        }

        // Iterate over each month index and update the aggregatedData array
        agency[key].forEach((value, monthIndex) => {
          aggregatedData[key][monthIndex] += value;
        });
      });
    });

    return aggregatedData;
  }

  const calculateItemsTotals = (flatArray) => {
    const totals = {
      totalPoCost: 0,
      totalFinalPoCost: 0,
      totalRfqCost: 0,
      qty: 0,
      margin: 0,
      profit: 0,
    };
    flatArray.forEach((item) => {
      totals.totalPoCost += item.totalPoCost || 0;
      totals.totalFinalPoCost += item.totalFinalPoCost || 0;
      totals.totalRfqCost += item.totalRfqCost || 0;
      totals.qty += item.qty || 0;
      totals.margin += item.margin || 0;
      totals.profit += item.profit || 0;
    });
    return [totals];
  };

  const exportToExcel = () => {
    const grid = document.querySelector('.my-grid');
    if (grid) {
      const filteredData = processedData.map((item) => {
        const filteredItem = {};
        selected.forEach((data) => {
          filteredItem[data.label] = item[data.value] || 0;
        });
        return filteredItem;
      });
      console.log(filteredData)
      const ws = XLSX.utils.json_to_sheet(filteredData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, `${new Date().toDateString()}-reports.xlsx`);
    }
  };

  const reportItemDataExportToExcel = () => {
    const filteredItemsData = itemsData.map((item) => {
      const filteredItem = {};
      selectedItem.forEach((option) => {
        filteredItem[option.label] = item[option.value] || 0;
      });
      return filteredItem;
    });
  
    const ws = XLSX.utils.json_to_sheet(filteredItemsData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `${new Date().toDateString()}-reports-item-data.xlsx`);
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="flex space-x-4 my-2 items-center mx-auto w-fit">
        <span className="mr-4">Select Month: </span>
        <label>Start Month: </label>
        <select
          value={selectedMonth}
          onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
        >
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month) => (
            <option key={month} value={month}>
              {month}
            </option>
          ))}
        </select>
        <label>End Month: </label>
        <select
          value={endMonth}
          onChange={(e) => setEndMonth(parseInt(e.target.value))}
        >
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month) => (
            <option key={month} value={month}>
              {month}
            </option>
          ))}
        </select>

        <MultiSelect
          options={options}
          value={selected}
          onChange={setSelected}
          labelledBy="Select"
          className='max-w-72 min-w-64'
          hasSelectAll={true}
        />

        <button className='flex items-center  h-8 p-2 bg-green-600 rounded-md text-white hover:bg-white hover:text-black disabled:bg-green-300 disabled:hover:text-white' 
          onClick={exportToExcel}
          disabled={selected.length === 0}
        >
          <ArrowDownTrayIcon width={20} /> Export
        </button>
      </div>

      <ReactDataGrid
        style={gridStyle}
        idProperty="uniqueId"
        defaultFilterValue={ReportsFilterValue}
        columns={ReportsColumns}
        dataSource={processedData}
        pagination
        defaultLimit={20}
        rowHeight={60}
        enableKeyboardNavigation
        showCellBorders={'horizontal'}
        columnMenu={{ lockable: false }}
        className="my-grid"// Track selected rows
      />
      {columnTotals && (
        <ReactDataGrid
          style={gridStyle2}
          //  idProperty="uniqueId"
          //  defaultFilterValue={ReportsFilterValue}
          columns={TotalsColumns}
          dataSource={columnTotals}
          defaultLimit={20}
          rowHeight={60}
          enableKeyboardNavigation
          showCellBorders={'horizontal'}
          columnMenu={{ lockable: false }}
        />
      )}

      <div className="flex space-x-4 my-2 items-center mx-auto w-fit">
        <h2 className="my-4 mx-5 text-2xl font-bold"> Items Data </h2>
        <MultiSelect
          options={options1}
          value={selectedItem}
          onChange={setSelectedItem}
          labelledBy="Select Item"
          className='max-w-72 min-w-64'
          hasSelectAll={true}
        />
        <button className='flex items-center  h-8 p-2 bg-green-600 rounded-md text-white hover:bg-white hover:text-black disabled:bg-green-300 disabled:hover:text-white' onClick={reportItemDataExportToExcel} disabled={selectedItem.length === 0}>
          <ArrowDownTrayIcon width={20} /> Export
        </button>
      </div>
      {itemsData && (
        <ReactDataGrid
          style={gridStyle}
          idProperty="itemName"
          defaultFilterValue={ItemsFilterValue}
          columns={ItemsColumns}
          dataSource={itemsData}
          pagination
          defaultLimit={10}
          rowHeight={60}
          enableKeyboardNavigation
          showCellBorders={'horizontal'}
          columnMenu={{ lockable: false }}
          className="my-grid1"// Track selected rows
        />
      )}
      {itemsColumnTotals && (
        <ReactDataGrid
          style={gridStyle2}
          columns={ItemsTotalColumns}
          dataSource={itemsColumnTotals}
          rowHeight={60}
          enableKeyboardNavigation
          showCellBorders={'horizontal'}
          columnMenu={{ lockable: false }}
        />
      )}
      {chartData && chartData.length > 0 && (
        <div className="my-5 mx-5 text-2xl font-bold">
          <Pie data={chartData} />
        </div>
      )}
    </>
  );
};

export default ReportsTable;
