import { app } from '../resources/gcp-config';
import {
  getFirestore,
  doc,
  getDoc,
  onSnapshot,
  collection,
} from 'firebase/firestore';
import { fetchUserData } from './fetch-user-data';

const db = getFirestore(app);

export default async function fetchTablePref(tableName) {
  try {
    const user = await fetchUserData();

    if (!user || !user.docId) {
      throw new Error('User data is invalid or user is not authenticated.');
    }

    const docRef = doc(db, 'users', user.docId, 'table-preferences', tableName);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return { ...docSnap.data(), docId: docSnap.id };
    } else {
      throw new Error('No such document!');
    }
  } catch (error) {
    console.error('Error fetching table preferences:', error);
    return null;
  }
}
