import React, { useState, useEffect } from 'react';
import Loader from '../loader/loader.js';
import { fetchAllPosData } from '../../database/fetch-pos-data.js';
import { createDelivery } from '../../database/set-delivery-data.js';
import { fetchAllVendorsData } from '../../database/fetch-vendor-data';
import { errorAlert } from '../alerts.js';

function AddNewDeliveryComponent({ openModal, setOpenModal }) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPO, setSelectedPO] = useState(null);
  const [items, setItems] = useState([]);
  const [enableLineItems, setEnableLineItems] = useState(false);
  const [lineItems, setLineItems] = useState([]);
  const [selectedLineItem, setSelectedLineItem] = useState('');
  const [spoNo, setSpoNo] = useState('');
  const [vpoNo, setVpoNo] = useState('');
  const [vendorName, setVendorName] = useState('');
  const [saiQty, setSaiQty] = useState(false);
  const [deliveryDate, setDeliveryDate] = useState('');
  const [deliveryQty, setDeliveryQty] = useState('');
  const [trackingPartner, setTrackingPartner] = useState('');
  const [trackingID, setTrackingID] = useState('');
  const [trackingStatus, setTrackingStatus] = useState('');
  const [openConfirmPOModal, setOpenConfirmPOModal] = useState(false);
  const [openTempPOModal, setOpenTempPOModal] = useState(false);
  const [vendorsList, setVendorsList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const unsubscribe = fetchAllPosData((pos) => {
        setItems(pos);
        setIsLoading(false);
      });

      return () => {
        unsubscribe();
      };
    };

    fetchData().then(() => console.log('Received Data'));
  }, []);

  useEffect(() => {
    const fetchVendorData = async () => {
      const unsubscribe = fetchAllVendorsData((pos) => {
        setVendorsList(pos);
      });

      return () => {
        unsubscribe();
      };
    };

    fetchVendorData().then(() => console.log('Received Data'));
  }, []);

  const handleChangePO = (event) => {
    const selectedPosId = event.target.value;
    const selectedPos = items.find((item) => item.posId === selectedPosId);

    setSelectedPO(selectedPos);

    setEnableLineItems(true);
    setLineItems(selectedPos.originalQuotes);
    // console.log(selectedPos);
  };

  const handleAddNewDelivery = async () => {
    if (!selectedLineItem || !deliveryDate || !trackingPartner || !trackingID) {
      // You can add an alert or error handling here if needed
      errorAlert('Please fill in all the fields');
      return;
    }
    // console.log(selectedLineItem);
    const formData = {
      ...selectedPO,
      vpoId: selectedLineItem.vpo,
      saiQty: selectedLineItem.saiQty,
      deliveryDate,
      trackingPartner,
      deliveryQty,
      trackingID,
      vendorName: selectedLineItem.vendorName,
      deliveryStatus: trackingStatus,
      spoId: selectedPO.spo,
    };

    try {
      setIsLoading(true);
      await createDelivery(formData);
      setIsLoading(false);
      setOpenModal(false);
      setSelectedPO('');
      setEnableLineItems(false);
      setLineItems([]);
      setSelectedLineItem('');
      setSaiQty(false);
      setDeliveryDate('');
      setTrackingPartner('');
      setTrackingID('');
      setTrackingStatus('');
      setDeliveryQty('');
    } catch (error) {
      console.error('Error creating delivery:', error);
    }
  };

  const handleAddTemporaryDelivery = async () => {
    // console.log('selectedLineItem');

    const formData = {
      vpoId: vpoNo,
      saiQty: saiQty,
      deliveryDate,
      trackingPartner,
      deliveryQty,
      trackingID,
      vendorName: vendorName,
      deliveryStatus: trackingStatus,
      spoId: spoNo,
    };

    // console.log(formData);

    try {
      setIsLoading(true);
      await createDelivery(formData);
      setIsLoading(false);
      setOpenTempPOModal(false);
      setSelectedPO('');
      setEnableLineItems(false);
      setLineItems([]);
      setSelectedLineItem('');
      setSaiQty(false);
      setDeliveryDate('');
      setTrackingPartner('');
      setTrackingID('');
      setTrackingStatus('');
      setDeliveryQty('');
      setSpoNo('');
      setVpoNo('');
      setVendorName('');
    } catch (error) {
      console.error('Error creating delivery:', error);
    }
  };

  const handleChangeLineItems = (event) => {
    const selectedLineItem = lineItems.find(
      (item) => item.uniqueId === event.target.value
    );

    setSelectedLineItem(selectedLineItem);
    if (selectedLineItem) {
      setSaiQty(true);
    } else {
      setSaiQty(false);
    }
  };

  return (
    <>
      {isLoading && <Loader />}

      <div
        id="addUserData-modal"
        tabIndex="-1"
        aria-hidden="true"
        className={
          openModal
            ? 'flex items-center justify-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full h-[calc(100%)] max-h-full bg-black bg-opacity-50'
            : 'hidden'
        }
      >
        <div className="relative p-4 w-full max-w-5xl max-h-full">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                Add New Delivery
              </h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={() => setOpenModal(false)}
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <div className="p-4 md:p-5 space-y-4 overflow-y-auto max-h-[calc(100vh-15rem)]">
              <section className="bg-white dark:bg-gray-900">
                <div className="mx-auto max-w-5xl">
                  <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
                    <div className="sm:col-span-2">
                      <div className="flex justify-between items-center">
                        <div>
                          <label
                            htmlFor="selectPOOptions"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >
                            Select an Existing PO
                          </label>
                        </div>

                        <div className="cursor-pointer text-green-600 dark:text-green-400">
                          <label
                            htmlFor="addManualPO"
                            className="block mb-2 text-sm font-medium cursor-pointer hover:font-semibold"
                            onClick={() => {
                              setOpenModal(false);
                              setOpenConfirmPOModal(true);
                            }}
                          >
                            + Add a Manual PO
                          </label>
                        </div>
                      </div>
                      <select
                        id="selectPOOptions"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        required
                        value={selectedPO ? selectedPO.posId : ''}
                        onChange={handleChangePO}
                      >
                        <option value="">Select PO</option>
                        {items.map((item) => (
                          <option key={item.docId} value={item.posId}>
                            {item.agency} ({item.spo || item.spoId})
                          </option>
                        ))}
                      </select>
                    </div>

                    {enableLineItems && lineItems && (
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="selectLineItemOptions"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Select a Line item
                        </label>
                        <select
                          id="selectLineItemOptions"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          required
                          value={
                            selectedLineItem ? selectedLineItem.uniqueId : ''
                          }
                          onChange={handleChangeLineItems}
                        >
                          <option value="">Select Item</option>
                          {lineItems.map((item) => (
                            <option key={item.docId} value={item.uniqueId}>
                              {`${item.clin} - ${item.itemName} - ${item.vendorName}`}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}

                    {saiQty && (
                      <>
                        <div className="mt-4 block sm:col-span-2">
                          <label
                            htmlFor="saiQtyDisplay"
                            className="block mb-2 font-medium text-gray-900 dark:text-white"
                          >
                            Total Sai Quantity
                          </label>
                          <p
                            id="saiQtyDisplay"
                            className="text-lg block font-semibold text-gray-900 dark:text-white"
                          >
                            {selectedLineItem.saiQty}
                          </p>
                        </div>
                        <label
                          htmlFor="deliveryDate"
                          className="block mb-2  text-sm col-span-1 font-medium text-gray-900 dark:text-white"
                        >
                          Delivery Qty (Number of Units of Line Item being
                          Delivered)
                          <span className="text-red-600">*</span>
                          <input
                            type="number"
                            name="deliveryQty"
                            id="deliveryQty"
                            className="bg-gray-50 border mt-2 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                            required
                            onChange={(e) => {
                              setDeliveryQty(e.target.value);
                            }}
                          />
                        </label>
                        <label></label>
                        <label
                          htmlFor="deliveryDate"
                          className="block mb-2  text-sm col-span-1 font-medium text-gray-900 dark:text-white"
                        >
                          Delivery Date
                          <span className="text-red-600">*</span>
                          <input
                            type="date"
                            name="deliveryDate"
                            id="deliveryDate"
                            className="bg-gray-50 border mt-2 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                            required
                            onChange={(e) => {
                              setDeliveryDate(e.target.value);
                            }}
                          />
                        </label>

                        <label
                          htmlFor="deliveryPartner"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Delivery Partner{' '}
                          <span className="text-red-600">*</span>
                          <input
                            list="delivery-partners"
                            name="deliveryPartner"
                            id="deliveryPartner"
                            className="bg-gray-50 border mt-2 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                            placeholder="FedEx"
                            required
                            value={trackingPartner}
                            onChange={(e) => {
                              setTrackingPartner(e.target.value);
                            }}
                          />
                          <datalist id="delivery-partners">
                            <option value="FedEx"></option>
                            <option value="USPS"></option>
                            <option value="UPS"></option>
                            <option value="DHL"></option>
                            <option value="Amazon"></option>
                          </datalist>
                        </label>

                        <label
                          htmlFor="status"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Tracking Status{' '}
                          <span className="text-red-600">*</span>
                          <select
                            name="status"
                            id="status"
                            className="bg-gray-50 border mt-2 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                            required
                            value={trackingStatus}
                            onChange={(e) => {
                              setTrackingStatus(e.target.value);
                            }}
                          >
                            <option value="">Select tracking status</option>
                            <option value="Shipped">Shipped</option>
                            <option value="Not Shipped">Not Shipped</option>
                            <option value="Delivered">Delivered</option>
                          </select>
                        </label>

                        <label
                          htmlFor="trackingId"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Tracking ID <span className="text-red-600">*</span>
                          <input
                            type="text"
                            name="trackingId"
                            id="trackingId"
                            className="bg-gray-50 border mt-2 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                            placeholder="123456789"
                            required
                            value={trackingID}
                            onChange={(e) => {
                              setTrackingID(e.target.value);
                            }}
                          />
                        </label>
                      </>
                    )}
                  </div>
                </div>
              </section>
            </div>
            <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
              <button
                type="button"
                onClick={handleAddNewDelivery}
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Add Delivery
              </button>
              <button
                onClick={() => setOpenModal(false)}
                type="button"
                className="ms-3 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        id="addconfirmPOData-modal"
        tabIndex="-1"
        aria-hidden="true"
        className={
          openConfirmPOModal
            ? 'flex items-center justify-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full h-[calc(100%)] max-h-full bg-black bg-opacity-50'
            : 'hidden'
        }
      >
        <div className="relative p-4 w-full max-w-5xl max-h-full">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                Select the Type of PO
              </h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={() => setOpenConfirmPOModal(false)}
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <div className="p-4 md:p-5 space-y-4 overflow-y-auto max-h-[calc(100vh-15rem)]">
              <section className="bg-white dark:bg-gray-900">
                <div className="mx-auto max-w-5xl">
                  <h3 className="mb-5 text-lg font-medium text-gray-900 dark:text-white">
                    Select the Type of PO you want to Create
                  </h3>
                  <ul className="grid w-full gap-6 md:grid-cols-2">
                    <li>
                      <label
                        htmlFor="hosting-small"
                        onClick={() => {
                          setOpenConfirmPOModal(false);
                          setOpenTempPOModal(true);
                        }}
                        className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
                      >
                        <div className="block">
                          <div className="w-full text-lg font-semibold">
                            Temporary PO
                          </div>
                          <div className="w-full text-sm mt-3">
                            Temporary PO will let you add a PO Number and Line
                            Item for Delivery Tracking
                          </div>
                        </div>
                      </label>
                    </li>
                    <li>
                      <label
                        htmlFor="hosting-big"
                        onClick={() => (window.location.href = '/po')}
                        className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
                      >
                        <div className="block">
                          <div className="w-full text-lg font-semibold">
                            Trackable PO
                          </div>
                          <div className="w-full text-sm mt-3">
                            This will add a Trackable PO where you can keep a
                            track of the PO Details, Quotes and Line Items.
                          </div>
                        </div>
                      </label>
                    </li>
                  </ul>
                </div>
              </section>
            </div>
            <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
              <button
                onClick={() => setOpenConfirmPOModal(false)}
                type="button"
                className="ms-3 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        id="addnewpo-modal"
        tabIndex="-1"
        aria-hidden="true"
        className={
          openTempPOModal
            ? 'flex items-center justify-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full h-[calc(100%)] max-h-full bg-black bg-opacity-50'
            : 'hidden'
        }
      >
        <div className="relative p-4 w-full max-w-5xl max-h-full">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                Add a Temporary PO
              </h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={() => setOpenTempPOModal(false)}
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <div className="p-4 md:p-5 space-y-4 overflow-y-auto max-h-[calc(100vh-15rem)]">
              <section className="bg-white dark:bg-gray-900">
                <div className="mx-auto max-w-5xl">
                  <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
                    <div className="sm:col-span-2">
                      <label
                        htmlFor="spoNo"
                        className="block mb-2 text-sm col-span-1 font-medium text-gray-900 dark:text-white"
                      >
                        SPO#
                        <span className="text-red-600">*</span>
                        <input
                          type="text"
                          name="spoNo"
                          id="spoNo"
                          className="bg-gray-50 border mt-2 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          required
                          onChange={(e) => {
                            setSpoNo(e.target.value);
                          }}
                        />
                      </label>
                    </div>
                    <div className="sm:col-span-2">
                      <label
                        htmlFor="vpoNo"
                        className="block mb-2 text-sm col-span-1 font-medium text-gray-900 dark:text-white"
                      >
                        VPO#
                        <span className="text-red-600">*</span>
                        <input
                          type="text"
                          name="vpoNo"
                          id="vpoNo"
                          className="bg-gray-50 border mt-2 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          required
                          onChange={(e) => {
                            setVpoNo(e.target.value);
                          }}
                        />
                      </label>
                    </div>
                    <div className="sm:col-span-2">
                      <label
                        htmlFor="vendorName"
                        className="block mb-2 text-sm col-span-1 font-medium text-gray-900 dark:text-white"
                      >
                        Vendor Name
                        <span className="text-red-600">*</span>
                        <input
                          type="text"
                          list="vendor-name"
                          name="vendorName"
                          id="vendorName"
                          className="bg-gray-50 border mt-2 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          required
                          value={vendorName}
                          onChange={(e) => {
                            setVendorName(e.target.value);
                          }}
                        />
                        <datalist id="vendor-name">
                          {vendorsList.map((vendor) => (
                            <option value={vendor.vendorName} />
                          ))}
                        </datalist>
                      </label>
                    </div>
                    <label
                      htmlFor="saiQty"
                      className="block mb-2  text-sm col-span-1 font-medium text-gray-900 dark:text-white"
                    >
                      SAI Qty
                      <span className="text-red-600">*</span>
                      <input
                        type="number"
                        name="saiQty"
                        id="deliveryQty"
                        className="bg-gray-50 border mt-2 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        required
                        onChange={(e) => {
                          setSaiQty(e.target.value);
                        }}
                      />
                    </label>
                    <label
                      htmlFor="deliveryQty"
                      className="block mb-2  text-sm col-span-1 font-medium text-gray-900 dark:text-white"
                    >
                      Delivery Qty (Number of Units of Line Item being
                      Delivered)
                      <span className="text-red-600">*</span>
                      <input
                        type="number"
                        name="deliveryQty"
                        id="deliveryQty"
                        className="bg-gray-50 border mt-2 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        required
                        onChange={(e) => {
                          setDeliveryQty(e.target.value);
                        }}
                      />
                    </label>
                    <label
                      htmlFor="deliveryDate"
                      className="block mb-2  text-sm col-span-1 font-medium text-gray-900 dark:text-white"
                    >
                      Delivery Date
                      <span className="text-red-600">*</span>
                      <input
                        type="date"
                        name="deliveryDate"
                        id="deliveryDate"
                        className="bg-gray-50 border mt-2 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        required
                        onChange={(e) => {
                          setDeliveryDate(e.target.value);
                        }}
                      />
                    </label>

                    <label
                      htmlFor="deliveryPartner"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Delivery Partner <span className="text-red-600">*</span>
                      <input
                        list="delivery-partners"
                        name="deliveryPartner"
                        id="deliveryPartner"
                        className="bg-gray-50 border mt-2 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="FedEx"
                        required
                        value={trackingPartner}
                        onChange={(e) => {
                          setTrackingPartner(e.target.value);
                        }}
                      />
                      <datalist id="delivery-partners">
                        <option value="FedEx"></option>
                        <option value="USPS"></option>
                        <option value="UPS"></option>
                        <option value="DHL"></option>
                        <option value="Amazon"></option>
                      </datalist>
                    </label>

                    <label
                      htmlFor="status"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Tracking Status <span className="text-red-600">*</span>
                      <select
                        name="status"
                        id="status"
                        className="bg-gray-50 border mt-2 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        required
                        value={trackingStatus}
                        onChange={(e) => {
                          setTrackingStatus(e.target.value);
                        }}
                      >
                        <option value="">Select tracking status</option>
                        <option value="Shipped">Shipped</option>
                        <option value="Not Shipped">Not Shipped</option>
                        <option value="Delivered">Delivered</option>
                      </select>
                    </label>

                    <label
                      htmlFor="trackingId"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Tracking ID <span className="text-red-600">*</span>
                      <input
                        type="text"
                        name="trackingId"
                        id="trackingId"
                        className="bg-gray-50 border mt-2 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="123456789"
                        required
                        value={trackingID}
                        onChange={(e) => {
                          setTrackingID(e.target.value);
                        }}
                      />
                    </label>
                  </div>
                </div>
              </section>
            </div>
            <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
              <button
                type="button"
                onClick={handleAddTemporaryDelivery}
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Add Delivery Details
              </button>
              <button
                onClick={() => setOpenTempPOModal(false)}
                type="button"
                className="ms-3 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddNewDeliveryComponent;
