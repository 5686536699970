import React, { useEffect, useState } from 'react';
import { fetchAllUsersData } from '../../database/fetch-user-data';
import { updateRFQ } from '../../database/set-rfq-data';

const AssignedToRenderer = ({ value, data }) => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(value || 'unassigned');

  useEffect(() => {
    const unsubscribe = fetchAllUsersData((usersData) => {
      setUsers(usersData);
    });

    // Clean up function
    return () => unsubscribe();
  }, []);

  const handleAssignedToChange = async (newValue, data) => {
    setSelectedUser(newValue);
    // console.log(newValue)
    // console.log(data)
    const formData = {
      assignedTo: newValue,
      assignedToAt: new Date().getTime(),
    };

    try {
      await updateRFQ(data.uniqueId, formData);
      // console.log(newValue)
      // console.log(data)
    } catch (error) {
      console.error('Error updating RFQ:', error);
    }
  };

  return (
    <div className="flex justify-center">
      <select
        value={selectedUser}
        className="px-2 py-1 rounded w-full"
        onChange={(e) => handleAssignedToChange(e.target.value, data)}
      >
        <option value="unassigned">Unassigned</option>
        {users.map((user) => (
          <option key={user.docId} value={user.docId}>
            {user.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default AssignedToRenderer;
