// frontend/src/auth/check-auth-status.js
import React from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Navigate } from 'react-router-dom';

function withAuthCheck(Component, app) {
  return class extends React.Component {
    state = {
      loading: true,
      isAuthenticated: false,
    };

    componentDidMount() {
      const auth = getAuth(app);
      onAuthStateChanged(auth, (user) => {
        if (user) {
          this.setState({ loading: false, isAuthenticated: true });
        } else {
          this.setState({ loading: false });
        }
      });
    }

    render() {
      if (this.state.loading) {
        return (
          <div className="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        );
      }
      if (!this.state.isAuthenticated) {
        return <Navigate to="/login" />;
      }

      return <Component {...this.props} />;
    }
  };
}

export default withAuthCheck;
