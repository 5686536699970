import React, { useState, useEffect, memo } from 'react';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import getEmails from './emails-data';
import posFilterValue from './emails-sort';
import poscolumns from './emails-columns';

const gridStyle = { height: '90vh' };

const EmailsTable = ({ setOpenEditModal, setSelectedId }) => {
  const [rfqData, setRfqData] = useState([]);

  useEffect(() => {
    const unsubscribe = getEmails((data) => {
      setRfqData(data);
    });

    // Cleanup function to unsubscribe when the component unmounts
    return () => unsubscribe();
  }, []);

  return (
    <ReactDataGrid
      style={gridStyle}
      idProperty="uniqueId"
      defaultFilterValue={posFilterValue}
      columns={poscolumns(setOpenEditModal, setSelectedId)}
      dataSource={rfqData}
      pagination
      defaultLimit={20}
      rowHeight={60}
      enableSelection
      enableKeyboardNavigation
      showCellBorders={'horizontal'}
      columnMenu={{ lockable: false }}
    />
  );
};

export default memo(EmailsTable);
