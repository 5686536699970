import { app } from '../resources/gcp-config';
import {
  getFirestore,
  doc,
  getDoc,
  onSnapshot,
  collection,
} from 'firebase/firestore';

const db = getFirestore(app);

export const fetchAllCOData = (callback) => {
  const q = collection(db, 'contracting-officer');
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const COs = querySnapshot.docs.map((doc) => {
      return { ...doc.data(), docId: doc.id };
    });
    callback(COs);
  });

  return unsubscribe;
};

export const fetchSingleCOdata = async (docId) => {
  const docRef = doc(db, 'contracting', docId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return { ...docSnap.data(), docId: docSnap.id };
  } else {
    console.error(new Error('No such document!'));
    return null;
  }
};
