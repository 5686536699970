import React from 'react';
import { EyeIcon } from '@heroicons/react/20/solid';
import { deletePOS } from '../../database/set-po-data';
/**
 * The emailRenderer function returns an HTML anchor element with a mailto link.
 * @param value - The `value` parameter in the `emailRenderer` function represents the email address
 * that needs to be rendered as a clickable link.
 * @returns The emailRenderer function returns an HTML anchor element (a tag) with the email address as
 * the link text and the email address as the href attribute value.
 */

const emailRenderer = (value) => {
  return (
    <a
      href={`mailto:${value.value}`}
      className="text-blue-500 hover:text-blue-700 underline"
    >
      {value.value}
    </a>
  );
};

const viewRenderer = ({ data, setOpenEditModal, setSelectedId }) => {
  // // console.log(data);
  const handleClick = (event) => {
    const docId = event.currentTarget.getAttribute('data-doc-id');
    // // console.log('docId', docId);
    setSelectedId(docId); // Set the selected id
    setOpenEditModal(true); // Open the modal
  };

  return (
    <div
      className="flex justify-center"
      data-doc-id={data.data.uniqueId}
      onClick={handleClick}
    >
      <EyeIcon className="h-5 w-5 hover:text-blue-500 cursor-pointer" />
    </div>
  );
};

const onDeleteRenderer = ({ data }) => {
  // // console.log('idData: ', data);

  return (
    <button
      type="button"
      onClick={() => deletePOS(data.uniqueId)}
      className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
    >
      Delete
    </button>
  );
};

const poscolumns = (setOpenEditModal, setSelectedId, tablePreferences = {}) => {
  const defaultOrder = [
    'view',
    'no',
    'spo',
    'rfqNo',
    'agency',
    'contractingOfficer',
    'email',
    'awardedDate',
    'lineItems',
    'attachments',
    'quotes',
    'delete',
  ];

  const columnOrder = tablePreferences?.order || defaultOrder;

  // Define an object to map column names to their corresponding configurations
  const columnConfigurations = {
    view: {
      name: 'view',
      header: '',
      minWidth: 50,
      render: (data) => viewRenderer({ data, setOpenEditModal, setSelectedId }),
      defaultWidth: tablePreferences?.view || 50,
    },
    no: {
      name: 'no',
      header: 'No.',
      minWidth: 100,
      defaultWidth: tablePreferences?.no || 100,
    },
    spo: {
      name: 'spo',
      header: 'SPO No.',
      minWidth: 200,
      defaultWidth: tablePreferences?.spo || 200,
    },
    rfqNo: {
      name: 'rfqNo',
      header: 'Related RFQ No.',
      minWidth: 200,
      defaultWidth: tablePreferences?.rfqNo || 200,
    },
    agency: {
      name: 'agency',
      header: 'Agency',
      minWidth: 250,
      defaultWidth: tablePreferences?.agency || 250,
    },
    contractingOfficer: {
      name: 'contractingOfficer',
      header: 'Contracting Officer',
      minWidth: 250,
      defaultWidth: tablePreferences?.contractingOfficer || 250,
    },
    email: {
      name: 'email',
      header: 'Email',
      minWidth: 250,
      render: emailRenderer,
      defaultWidth: tablePreferences?.email || 250,
    },
    awardedDate: {
      name: 'awardedDate',
      header: 'Awarded Date',
      minWidth: 250,
      defaultWidth: tablePreferences?.awardedDate || 250,
    },
    lineItems: {
      name: 'lineItems',
      header: 'Line Items',
      minWidth: 100,
      defaultWidth: tablePreferences?.lineItems || 100,
    },
    attachments: {
      name: 'attachments',
      header: 'Attachments',
      minWidth: 100,
      defaultWidth: tablePreferences?.attachments || 100,
    },
    quotes: {
      name: 'quotes',
      header: 'Quotes',
      minWidth: 100,
      defaultWidth: tablePreferences?.quotes || 100,
    },
    delete: {
      name: 'delete',
      header: 'Delete',
      minWidth: 150,
      render: ({ value, data }) => onDeleteRenderer({ data }),
      defaultWidth: tablePreferences?.delete || 150,
    },
  };

  // Map over the column order array to generate the columns
  const columns = columnOrder.map((columnName) => {
    return columnConfigurations[columnName]; // Retrieve column configuration based on column name
  });

  return columns;
};

export default poscolumns;
